import React from "react";
import { useQueries } from "react-query";
import {
  cdelay,
  from_time_mini,
  getv,
  iso_format,
  nils,
} from "../utils/utils.js";
import {
  q_admin_jackpot_pot,
  q_admin_jackpot_winhash,
  qissuccesss,
} from "../queries/queries.js";
import { useMemo } from "react";
import { tablecn } from "../utils/cn_map.js";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { extract_inp } from "../components/input.js";
import { Loader01c } from "../components/anims.js";

const Jackpot = () => {
  const [qo] = useQueries([q_admin_jackpot_pot({}, {})]);
  const { js = [], vsmap = {} } = useMemo(() => {
    if (qissuccesss(qo)) return getv(qo, "data.result");
    return {};
  }, [qo.dataUpdatedAt]);

  const update_hash = async ({ jackpotid }) => {
    let doc = {
      jackpotid,
      hash: extract_inp(`_inp_hash:${jackpotid}`),
    };
    doc.hash = doc.hash.trim();
    await q_admin_jackpot_winhash({ doc }).queryFn();
    await cdelay(1000);
    await qo.refetch();
  };

  return (
    <div className="h-page">
      <div className="h-[2rem]"></div>
      <p className="text-center text-[2rem] font-digi">Jackpot</p>
      <div className="h-[2rem]"></div>
      <div className="mx-auto w-[80rem]">
        <Card className={"w-full"}>
          {qo.isLoading && <Loader01c size="s" />}
          <table className={tablecn.table}>
            <thead>
              <tr className={tablecn.tr}>
                <th className={tablecn.th}>Jackpot ID</th>
                <th className={tablecn.th}>type</th>
                <th className={tablecn.th}>starts</th>
                <th className={tablecn.th}>ends</th>
                <th className={tablecn.th}>status</th>
              </tr>
            </thead>
            <tbody>
              {js.map((j) => {
                let vau = getv(j, "winner.vault");
                let vau_name = vsmap[vau];
                return (
                  <React.Fragment>
                    <tr className={tablecn.tr}>
                      <td className={tablecn.td}>
                        <span>{j.jackpotid}</span>
                      </td>
                      <td className={tablecn.td}>
                        <span>{j.jtype}</span>
                      </td>
                      <td className={tablecn.td}>
                        <div className="fc-ss">
                          <span>{iso_format(j.start_time)}</span>
                          <span>{from_time_mini(j.start_time)}</span>
                          <span>---</span>
                        </div>
                      </td>
                      <td className={tablecn.td}>
                        {j.end_time && (
                          <div className="fc-ss">
                            <span>{iso_format(j.end_time)}</span>
                            <span>{from_time_mini(j.end_time)}</span>
                          </div>
                        )}
                      </td>
                      <td className={tablecn.td}>{j.status}</td>
                      <td className={tablecn.td}>{'-'}</td>
                      <td className={tablecn.td}>{'-'}</td>
                    </tr>
                    <tr className={tablecn.tr}>
                      <td colSpan={2} className={tablecn.td}>
                        <div className="fc-ss">
                          <span>winner: {vau}</span>
                          <span className="text-acc0 text-[12px]">
                            {vau_name}
                          </span>
                        </div>
                      </td>
                      <td colSpan={2} className={tablecn.td}>
                        <div className="fc-ss">
                          <span>hash:</span>
                          <span>{getv(j, "winner.hash")}</span>
                        </div>
                      </td>
                      <td colSpan={2} className={tablecn.td}>
                        <div className="fr-cc w-full">
                          <div className="flex-1">
                            <InpText
                              {...{
                                id: `_inp_hash:${j.jackpotid}`,
                                setter: () => {},
                              }}
                            />
                          </div>
                          <Tag
                            onClick={update_hash}
                            className="bg-acc0 text-black"
                          >
                            Set Hash
                          </Tag>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

export default Jackpot;
