import _ from "lodash";
import { useMemo, useState } from "react";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { Card, InpText } from "../components/utilityComps";
import { q_admin_hstats } from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { dec, getv, iso, jstr, nano, nils } from "../utils/utils";

export const HStatsPage = () => {
  const [rid, set_rid] = useState(null);
  const inp_rid = `inp-rid`;

  const [q] = useQueries([q_admin_hstats({ rid }, { enabled: !nils(rid) })]);
  const hstats = useMemo(() => getv(q, "data.result.hstats"), [jstr(q)]);

  return (
    <div class="h-page">
      <div class="h-[3rem]"></div>
      <div class="fc-cc gap-2">
        <div class="fr-cc">
          <InpText
            id={inp_rid}
            label="Race ID"
            contprops={{ className: "" }}
            inpprops={{ className: "" }}
            placeholder="Title"
            setter={(v) => {
              if (nils(v)) v = null;
              set_rid(v);
            }}
          />
        </div>
        {!_.isEmpty(hstats) && (
          <Card class="bg-reg border border-slate-700 resp-text-0 ">
            <table className={twMerge(tablecn.table, "thintdrowp4-table")}>
              <thead>
                <tr>
                  <td>HID</td>
                  <td>Mean</td>
                  <td>SD</td>
                </tr>
              </thead>
              <tbody>
                {_.map(hstats, (e) => {
                  return (
                    <tr className="font-mono">
                      <td>{e.hid}</td>
                      <td>{dec(e.mean, 3)}</td>
                      <td>{dec(e.std, 3)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        )}
      </div>
    </div>
  );
};
