import _ from "lodash";
import moment from "moment";
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { Helmet } from "react-helmet-async";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { Loader01c } from "../components/anims";
import { Card } from "../components/utilityComps";
import { qiserr, qissuccesss, q_sheet_pages_funds } from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { getv, iso, nano, nils } from "../utils/utils";

const FundsContext = createContext();
export const useFundsContext = () => useContext(FundsContext);

const SectionTableView = ({ o }) => {
  const headcn = "font-digi text-left text-acc0 resp-px-1";
  const datacn = "font-mono font-bold text-right text-white resp-px-1";

  return (
    <Card className={"w-full resp-p-4"}>
      <p className="text-center my-2 resp-text-1 font-digi">{o.head}</p>
      <div class="fr-cc resp-gap-2 my-2">
        <span>{getv(o, "dates.st")}</span>
        <span>-</span>
        <span>{getv(o, "dates.ed")}</span>
      </div>
      <table className={twMerge(tablecn.table, "w-full")}>
        <thead>
          {o.rows.slice(0, 1).map((r) => {
            return (
              <tr className={twMerge(tablecn.tr)}>
                {r.map((c) => {
                  return <td className={twMerge(headcn)}>{c}</td>;
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {o.rows.slice(1).map((r) => {
            return (
              <tr className={twMerge(tablecn.tr)}>
                {r.map((c, i) => {
                  return (
                    <td className={twMerge(i == 0 ? headcn : datacn)}>{c}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export const FundsPage = () => {
  const pagetitle = "Funds || DNA Racing";
  const [qo] = useQueries([q_sheet_pages_funds()]);
  const d = getv(qo, "data.result");
  const fcon = {};

  return (
    <FundsContext.Provider value={fcon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="w-[80rem] max-w-[98vw] overflow-auto mx-auto">
          <div className="h-[4rem]"></div>
          <p className="text-center my-2 resp-text-2 font-digi">Funds</p>

          {qo.isLoading ? (
            <Loader01c />
          ) : qiserr(qo) ? (
            <p className="text-center my-2 resp-text-1 text-red-300 font-digi">
              {qiserr(qo)}
            </p>
          ) : qissuccesss(qo) ? (
            <>
              <SectionTableView o={d.table1} />
              <SectionTableView o={d.table2} />
            </>
          ) : null}

          <div className="h-[4rem]"></div>
        </div>
      </div>
    </FundsContext.Provider>
  );
};
