import {
  faAdd,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faRefresh,
  faSquare,
  faTimes,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment, { relativeTimeRounding } from "moment";
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { Helmet } from "react-helmet-async";
import { useQueries } from "react-query";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tokdecn, useAppContext } from "../App";
import { Loader01c } from "../components/anims";
import {
  extract_inp,
  set_val_inp,
  F_OptionInput,
  F_InputTextAr,
} from "../components/input";
import { Card, Tag, TokenIcon } from "../components/utilityComps";
import {
  polytxnlink,
  qiserr,
  qissuccesss,
  q_bulk_race_payouts_addrows,
  q_bulk_race_payouts_cc,
  q_bulk_race_payouts_delrows,
  q_bulk_race_payouts_list,
  q_sheet_pages_funds,
} from "../queries/queries";
import { class_text, tablecn } from "../utils/cn_map";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
} from "../utils/utils";

const BulkRacePayoutsContext = createContext();
export const useBulkRacePayoutsContext = () =>
  useContext(BulkRacePayoutsContext);

const validate_row = (r) => {
  if (_.isEmpty(r)) return false;
  if (nils(r.rid) || !_.isString(r.rid)) return false;
  if (nils(r.hid) || !_.isNumber(r.hid)) return false;
  if (nils(r.vault) || !_.isString(r.vault)) return false;
  if (nils(r.amt) || !_.isNumber(r.amt)) return false;
  if (nils(r.token) || !_.isString(r.token)) return false;
  if (!["WETH", "DEZ"].includes(r.token)) return false;
  return true;
};

const bulkinpid = "inp_bulk_payout_rows";
const InpSection = () => {
  const bcon = useBulkRacePayoutsContext();
  const { add_rows } = bcon;
  let [err, set_err] = useState(null);
  const submit_bulk = () => {
    try {
      set_err(null);
      let vals = extract_inp(bulkinpid);
      vals = vals.trim();
      let ar = _.chain(vals.split("\n"))
        .map((e) => e.trim())
        .map((e) => {
          if (!e) return null;
          let row = e.split("	");
          row = {
            rid: row[0].trim(),
            hid: parseInt(row[1]),
            vault: row[2].toLowerCase().trim(),
            amt: parseFloat(row[3]),
            token: row[4].trim(),
          };
          return row;
        })
        .value();
      let invlids = [];
      let n = ar.length;
      ar = _.chain(ar)
        .filter((r, i) => {
          let valid = validate_row(r);
          if (!valid) invlids.push(i + 1);
          return valid;
        })
        .value();
      let n2 = ar.length;
      if (n != n2) {
        throw new Error(
          `has some invalid ${n - n2} rows:${invlids.join(", ")}`,
        );
      }
      console.log(ar);
      add_rows(ar);
    } catch (e) {
      set_err(e.message);
      console.log(e);
    }
  };
  return (
    <div class="">
      <p className="fr-sc resp-gap-2 font-digi my-2">
        <span>Format:</span>
        {"RaceID CoreID VaultWallet Amount TOKEN".split(" ").map((e, i) => {
          return (
            <span className="text-yellow-300 underline" key={i}>
              {e}
            </span>
          );
        })}
      </p>
      <textarea
        id={bulkinpid}
        className="w-full p-2 font-mono outline-none border border-acc0 rounded-md bg-black h-[10rem]"
      ></textarea>
      <div class="fr-sc">
        {!nils(err) && <p class="text-red-300 resp-text--1">{err}</p>}
        <div class="flex-1"></div>
        <Tag
          className="bg-acc0/40"
          onClick={() => {
            submit_bulk();
          }}
        >
          Add Payouts
        </Tag>
      </div>
    </div>
  );
};

const ListSection = () => {
  const bcon = useBulkRacePayoutsContext();

  const {
    page,
    set_page,
    limit,
    filt,
    set_filt,
    qorowslist,
    refresh_list,
    delete_rows,
  } = bcon;

  const [nf, set_nf] = useState(filt);
  const inpargs = {
    nf,
    set_nf,
    nfkey: "fquallist",
  };

  const [rows, rmap, vmap, hmap] = useMemo(() => {
    const {
      rows = [],
      rmap = {},
      vmap = {},
      hmap = {},
    } = getv(qorowslist, "data.result") || {};
    return [rows, rmap, vmap, hmap];
  }, [qorowslist.dataUpdatedAt]);

  const [filtrows, set_filtrows] = useState(rows);
  useEffect(() => {
    console.log("baserows updated");
    set_filtrows(rows);
  }, [jstr(rows), qorowslist.dataUpdatedAt]);

  const update_filters = () => {
    set_filt(nf);
    refresh_list();
  };

  const [selids, set_selids] = useState([]);

  const delete_single_row = async (row) => {
    let row2 = _.filter(filtrows, (e) => e.id != row.id);
    set_filtrows(row2);
    delete_rows([row.id]);
  };

  const delete_selected_rows = async () => {
    let row2 = _.filter(filtrows, (e) => !selids.includes(e.id));
    set_filtrows(row2);
    set_selids([]);
    delete_rows(selids);
  };

  return (
    <>
      <hr className="w-full my-2" />
      <div class="my-2">
        <div class="fr-sc">
          <div class="flex-1"></div>
          <div class="grid grid-cols-6 gap-4">
            <div class="">Status</div>
            <div class="col-span-2">
              <F_OptionInput
                {...{
                  ...inpargs,
                  path: "status",
                  type: "options",
                  options: ["pending", "success", "error"],
                }}
              />
            </div>

            <div class="">Token</div>
            <div class="col-span-2">
              <F_OptionInput
                {...{
                  ...inpargs,
                  path: "token",
                  type: "options",
                  options: ["WETH", "DEZ"],
                }}
              />
            </div>

            <div class="">RaceIds</div>
            <div class="col-span-2">
              <F_InputTextAr
                {...{
                  ...inpargs,
                  path: "rid",
                }}
              />
            </div>

            <div class="">Vault</div>
            <div class="col-span-2">
              <F_InputTextAr
                {...{
                  ...inpargs,
                  path: "vault",
                }}
              />
            </div>

            <div class="">Hid</div>
            <div class="col-span-2">
              <F_InputTextAr
                {...{
                  ...inpargs,
                  path: "hid",
                  el_postprocess_fn: (e) => parseInt(e),
                }}
              />
            </div>

            <div class="col-span-3 fr-sc">
              <div class="flex-1"></div>
              <Tag
                onClick={update_filters}
                className="resp-text---1 bg-acc0/30 text-white"
              >
                <span>Update Filters</span>
              </Tag>
            </div>
            <div class="col-span-6 p-1 bg-reg">
              <p class="resp-text--2">{jstr(filt)}</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full my-2" />
      <div class="fr-sc gap-2">
        <Tag onClick={refresh_list}>
          <FontAwesomeIcon
            className={twMerge(
              "resp-text-2 text-acc0",
              qorowslist.isLoading ? "spin-anim" : "",
            )}
            icon={faRefresh}
          />
        </Tag>
        <p class="text-left resp-text-1">Transactions</p>
        <p class="text-left resp-text-1">[{rows.length} rows]</p>
        <div class="flex-1"></div>

        <div class="flex-1"></div>
        {!_.isEmpty(selids) ? (
          <>
            <Tag className="text-acc0" onClick={() => set_selids([])}>
              <FontAwesomeIcon icon={faTimes} />
            </Tag>
            <span> {selids.length} Selected</span>
            <Tag className="text-red-500" onClick={delete_selected_rows}>
              <FontAwesomeIcon icon={faTrash} />
            </Tag>
          </>
        ) : null}
        <Tag onClick={() => set_page(Math.max(page - 1, 1))}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Tag>
        <span>Page {page}</span>
        <Tag onClick={() => set_page(page + 1)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Tag>
      </div>

      {!_.isEmpty(filtrows) && (
        <Card className="w-full p-2 overflow-auto">
          <table className={twMerge(tablecn.table, "w-max")}>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              {filtrows.map((row) => {
                let { rid, hid, vault } = row;
                let r = rmap[rid];
                let h = hmap[hid];
                let vauname = vmap[vault];
                let selected = selids.includes(row.id);
                return (
                  <tr
                    key={row.id}
                    class={twMerge(tablecn.tr, "thintdrowp4 resp-text--2")}
                  >
                    <td>
                      <Tag
                        onClick={() => {
                          if (selected) {
                            set_selids(_.filter(selids, (e) => e != row.id));
                          } else {
                            set_selids([...selids, row.id]);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          className={twMerge(
                            "resp-text-2",
                            selected ? "text-acc0" : "text-slate-400",
                          )}
                          icon={selected ? faCheckSquare : faSquare}
                        />
                      </Tag>
                    </td>
                    <td className="">
                      <div class="fc-ss">
                        <span className="resp-text-0">{rid}</span>
                        {!_.isEmpty(r) && (
                          <div class="fr-sc resp-gap-2">
                            <span className="text-acc0">
                              {r.cb}-{class_text(r.class, "t")}
                            </span>
                            <span>{r.race_name}</span>
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div class="fc-ss">
                        <span className="resp-text-0">{hid}</span>
                        {!_.isEmpty(h) && (
                          <div class="fr-sc resp-gap-2">
                            <FontAwesomeIcon
                              style={{ color: `#${h.hex_code}` }}
                              icon={faCircle}
                            />
                            <span className="text-white">{h.name}</span>
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div class="fc-ss">
                        <span className="resp-text--3 font-mon0">{vault}</span>
                        {!nils(vauname) && (
                          <div class="fr-sc resp-gap-2">
                            <span className="text-acc0">{vauname}</span>
                          </div>
                        )}
                        {!_.isEmpty(row?.errmsgs) &&
                          row.errmsgs.map((err) => {
                            <span className="text-red-300 resp-text--3 mb-1">
                              {err}
                            </span>;
                          })}
                      </div>
                    </td>

                    <td>
                      <div class="fr-sc resp-text-0 resp-gap-1 resp-text--1">
                        <div class="flex-1"></div>
                        <span
                          className={twMerge(
                            row.token == "WETH"
                              ? "text-pink-400"
                              : row.token == "DEZ"
                                ? "text-acc0"
                                : "",
                          )}
                        >
                          {dec(row.amt, tokdecn(row.token))}
                        </span>
                        <TokenIcon token={row.token} />
                      </div>
                    </td>

                    <td>
                      <Tag
                        onClick={() => {
                          delete_single_row(row);
                        }}
                        className={"bg-red-400 text-white"}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Tag>
                    </td>

                    <td>
                      <div class="fc-ss resp-gap-1 resp-text--1">
                        <span
                          className={twMerge(
                            "uppercase",
                            row.status == "pending"
                              ? "text-blue-400"
                              : row.status == "success"
                                ? "text-green-400"
                                : row.status == "error"
                                  ? "text-red-400"
                                  : row.status == "cancelled"
                                    ? "text-red-400"
                                    : "",
                          )}
                        >
                          {row.status}-{row?.retry ?? 0}
                        </span>
                        {row.status !== "success" && (
                          <span>pay in {from_time_mini(row.pay_after)}</span>
                        )}
                      </div>
                    </td>

                    <td>
                      <div class="fc-ss resp-gap-1">
                        <div class="fr-sc resp-gap-1">
                          <FontAwesomeIcon icon={faAdd} />
                          <span className="resp-text--2">
                            {iso_format(row.added_at)}
                          </span>
                        </div>
                        <div class="fr-sc resp-gap-1">
                          <FontAwesomeIcon icon={faRefresh} />
                          <span className="resp-text--2">
                            {iso_format(row.updated_at)}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div class="fr-sc resp-gap-1">
                        {!_.isEmpty(row.hashs) &&
                          row.hashs.map((h) => {
                            return (
                              <Link target={"_blank"} to={polytxnlink(h)}>
                                <TokenIcon token="MATIC" size="xs" />
                              </Link>
                            );
                          })}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      )}
    </>
  );
};

export const BulkRacePayouts = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const pagetitle = "BulkRacePayouts || DNA Racing";

  const [resp, set_resp] = useState({});

  const [page, set_page] = useState(1);
  const [filt, set_filt] = useState({});
  const limit = 50;
  const list_inpprops = { page, limit, extmat: filt };
  const [qorowslist] = useQueries([q_bulk_race_payouts_list(list_inpprops)]);

  const fn_wrap = async (fn) => {};

  const add_rows = async (rows) => {
    try {
      set_resp({ status: "loading", msg: "Adding Rows" });

      let re = await q_bulk_race_payouts_addrows({ rows }).queryFn();
      // await cdelay(3 * 1e3);
      // let re = { status: "success", msg: "Added rows Rows" };

      if (re?.status == "error") throw new Error(re.err);
      if (re.status == "success") {
        set_resp({
          loading: false,
          status: "success",
          msg: getv(re, "result.msg"),
        });
        set_val_inp(bulkinpid, "");
      } else {
        throw new Error("unable to connect");
      }
      setTimeout(() => {
        set_resp({});
      }, 4 * 1e3);
    } catch (err) {
      console.log(err);
      set_resp({ loading: false, status: "error", msg: err.message });
    }
  };
  const delete_rows = async (ids) => {
    try {
      set_resp({ status: "loading", msg: `Deleting ${ids.length} Rows` });

      let re = await q_bulk_race_payouts_delrows({ ids }).queryFn();
      // await cdelay(3 * 1e3);
      // let re = { status: "success", msg: "Added rows Rows" };

      if (re?.status == "error") throw new Error(re.err);
      if (re.status == "success") {
        set_resp({
          loading: false,
          status: "success",
          msg: getv(re, "result.msg"),
        });
        set_val_inp(bulkinpid, "");
      } else {
        throw new Error("unable to connect");
      }
      setTimeout(() => {
        set_resp({});
      }, 4 * 1e3);
    } catch (err) {
      console.log(err);
      set_resp({ loading: false, status: "error", msg: err.message });
    }
  };
  const refresh_list = async () => {
    await q_bulk_race_payouts_cc({
      k: "r_list",
      ...list_inpprops,
    });
    await cdelay(1 * 1e3);
    qorowslist.refetch();
  };

  const bcon = {
    page,
    set_page,
    limit,
    filt,
    set_filt,
    qorowslist,

    add_rows,
    delete_rows,
    refresh_list,
  };

  return (
    <BulkRacePayoutsContext.Provider value={bcon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="w-[80rem] max-w-[98vw] overflow-auto mx-auto">
          <div className="h-[4rem]"></div>
          <p className="text-center my-2 resp-text-2 font-digi">
            BulkRacePayouts
          </p>

          <InpSection />
          {_.isEmpty(resp) ? null : (
            <div
              class={twMerge(
                "fr-sc gap-2 border rounded-full p-2 px-4",
                resp?.status == "loading"
                  ? "text-blue-400 border-blue-400"
                  : resp?.status == "error"
                    ? "border-red-300 text-red-300"
                    : resp?.status == "success"
                      ? "border-green-300 text-green-300"
                      : "text-white",
              )}
            >
              {resp.status == "loading" && <Loader01c size="s" />}
              <p class="resp-text-1">{resp.msg}</p>
            </div>
          )}

          <ListSection />

          <div className="h-[4rem]"></div>
        </div>
      </div>
    </BulkRacePayoutsContext.Provider>
  );
};
