import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { useAppContext, useNowContext } from "../App.js";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import { useQueries } from "react-query";
import {
  q_admin_finmov_base,
  q_admin_finmov_bbms,
  q_admin_finmov_setb,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import {
  dec,
  from_time_mini,
  getv,
  iso_format,
  nano,
  nils,
} from "../utils/utils.js";
import { extract_inp } from "../components/input.js";
import { Loader01c } from "../components/anims.js";
import _ from "lodash";
import { twMerge } from "tailwind-merge";
import {
  cb_txt,
  class_cn,
  class_text,
  pos_txt,
  tablecn,
} from "../utils/cn_map.js";
import { BY_Star } from "../utils/raceutils.js";
import moment from "moment";
const FinMovContext = createContext({});
const useFinMovContext = () => useContext(FinMovContext);

const Race = () => {
  const { now } = useNowContext();
  const fcon = useFinMovContext();
  const { r = {}, bbms = {}, set_bpos } = fcon;

  const { race, rsim, hmap, fmov, posmap } = r;

  const betstops = useMemo(() => {
    if (nils(rsim)) return null;
    if (nils(race.start_time)) return null;
    let bs = moment(race.start_time).add(-60, "seconds").toISOString();
    return bs;
  }, [race.start_time]);

  return (
    <div className="w-full">
      {!_.isEmpty(r.race) && (
        <Card className={"bg-reg w-full resp-text--2"}>
          <div className="fr-sc resp-gap-2 my-2">
            <Tag className="border border-acc0 text-acc0 resp-text--1">
              {race.rid}
            </Tag>
            <Tag className={twMerge("resp-text--1", class_cn(race.class))}>
              {class_text(race.class)}
            </Tag>

            <Tag className={twMerge("resp-text--1", "text-acc0")}>
              {cb_txt(race.cb)}
            </Tag>
            <p className="font-digi resp-text--1">{race.race_name}</p>
          </div>
          <div className="fr-sc resp-gap-2 my-2 resp-text--1">
            {race.processed == true ? (
              <p className="text-red-300">
                Race Results are processed and updated on DB
              </p>
            ) : (
              <p className="text-acc0">
                Race is only scheduled. Results are not processed yet.
              </p>
            )}
          </div>
          <div className="fr-sc resp-gap-2 my-2 resp-text--1">
            {!nils(rsim) ? (
              <p className="text-acc0">is bettable on FairEx</p>
            ) : (
              <p className="text-acc0">is NOT bettable on FairEx</p>
            )}
          </div>
          <div className="fr-sc resp-gap-2 my-2 resp-text--2">
            <div className="flex-1"></div>
            {nano(now) < nano(race.start_time) ? (
              <p className="text-green-300">
                Starts in {from_time_mini(race.start_time)}
              </p>
            ) : (
              <p>Started at {iso_format(race.start_time)}</p>
            )}
          </div>
          <div className="fr-sc resp-gap-2 my-2 resp-text--2">
            <div className="flex-1"></div>
            {nils(betstops) ? null : nano(now) < nano(betstops) ? (
              <p className="text-green-300">
                betstops in {from_time_mini(betstops)}
              </p>
            ) : (
              <p>betstoped at {iso_format(betstops)}</p>
            )}
          </div>
          <div className="fr-sc resp-gap-2 my-2 resp-text--2">
            {!nils(fmov) ? (
              <>
                <span className="text-yellow-300">
                  finmoved for {fmov.hid} to win
                </span>
                <span className={fmov.done ? "text-green-300" : "text-red-300"}>
                  {fmov.done ? "done" : "not done"}
                </span>
              </>
            ) : (
              <span>not finmoved</span>
            )}
          </div>
          <hr />
          <p className="rexp-text-1 font-digi">Bikes List</p>
          <table className={twMerge(tablecn.table)}>
            <thead>
              <tr className={twMerge("thintdrowp4")}>
                <td>gate</td>
                <td>hid</td>
                <td>name</td>
                <td>star</td>
                <td>odds</td>
                <td>bets</td>
                <td>winnable</td>
                <td>
                  <div className="fc-ss">
                    <span>Profit</span>
                    <span>if this wins</span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {r.race.hids.map((hid, i) => {
                let gate = i + 1;
                let h = hmap[hid];
                let bs = (r.race.bluestars || []).includes(hid) ? 2 : 0;
                let ys = (r.race.yellowstars || []).includes(hid) ? 3 : 0;
                let s =
                  _.chain(rsim?.simsdata)
                    .find((s) => s.hid == hid)
                    .value() || {};

                let hbm = getv(bbms, `bmap.${hid}`) ?? {};

                let othertotbet = _.chain(bbms.bmap)
                  .values()
                  .filter((e) => e.hid != hid)
                  .sumBy((e) => e?.tot_betamt ?? 0)
                  .value();
                let ourprofit = othertotbet - (hbm.tot_winable ?? 0);

                let pm = getv(posmap, hid) ?? {};
                return (
                  <tr key={hid} className={twMerge("thintdrowp4")}>
                    <td>Gate-{gate}</td>
                    <td>#{hid}</td>
                    <td>
                      <div className="fc-ss resp-text--2">
                        <p className="font-digi">{h.name}</p>
                        <p className="font-digi text-acc0">{h.vault_name}</p>
                        <p className="text-right">
                          {!nils(pm.pos) ? pos_txt(pm.pos) : "--"}
                        </p>

                        <p className="text-right">
                          {!nils(pm.time) ? `${dec(pm.time, 2)}sec` : "--"}
                        </p>
                      </div>
                    </td>
                    <td>
                      <BY_Star star={bs + ys} />
                    </td>
                    <td>
                      <p className="text-acc0 w-full text-right">
                        {!nils(s.odds) ? dec(s.odds, 2) : "--"}
                      </p>
                    </td>
                    <td>
                      {!nils(hbm.tot_betamt) ? (
                        <p className="text-right">{dec(hbm.tot_betamt, 0)}</p>
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {!nils(hbm.tot_winable) ? (
                        <p className="text-right">{dec(hbm.tot_winable, 0)}</p>
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      <p className="text-right">{dec(ourprofit, 0)}</p>
                    </td>
                    <td>
                      <Tag
                        onClick={() => set_bpos(hid)}
                        className="border border-acc0 text-acc0 resp-text--2 font-digi"
                      >
                        SET
                      </Tag>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      )}
    </div>
  );
};

const BBMS = () => {
  const fcon = useFinMovContext();
  const { r = {}, bbms = {} } = fcon;
  const { hmap = {} } = r;
  const { bmap = {}, vmap = {} } = bbms;
  return (
    <div className="w-full">
      {!nils(bmap) && (
        <Card className={"bg-reg w-full resp-text--2"}>
          <p className="resp-text-1">Bets per Bike</p>
          <table className={twMerge("w-full", tablecn.table)}>
            <tbody>
              {_.entries(bmap).map(([hid, hbm]) => {
                let h = hmap[hid] || {};
                return (
                  <>
                    <tr className={"thintdrowp4 bg-black"}>
                      <td>
                        <p className="text-acc0">#{hid}</p>
                      </td>
                      <td>
                        <p className="text-acc0">{h.name}</p>
                      </td>
                    </tr>
                    {hbm.bets.map((b) => {
                      let vname = vmap[b.bettor] ?? "----";
                      return (
                        <tr className={"thintdrowp4"}>
                          <td></td>
                          <td>{vname}</td>
                          <td>
                            <p className="text-right w-full">
                              {dec(b.betamt, 0)}DEZ
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })}
            </tbody>
          </table>
        </Card>
      )}
    </div>
  );
};

export const FinMov = () => {
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [rid, set_rid] = useState(psearch.rid ?? "");

  const [qo_r, qo_bbms] = useQueries([
    q_admin_finmov_base(
      { rid },
      { staleTime: 10 * 1e3, refechInterval: 10 * 1e3, enabled: !nils(rid) }
    ),
    q_admin_finmov_bbms(
      { rid },
      { staleTime: 10 * 1e3, refechInterval: 10 * 1e3, enabled: !nils(rid) }
    ),
  ]);
  const r = useMemo(() => getv(qo_r, "data.result"), [qo_r.dataUpdatedAt]);
  const bbms = useMemo(
    () => getv(qo_bbms, "data.result"),
    [qo_bbms.dataUpdatedAt]
  );

  useEffect(() => {
    let rem = { rid };
    upd_psearch(rem);
  }, [rid]);

  const [respmsg, set_respmsg] = useState(null);
  const [resperr, set_resperr] = useState(null);
  const set_bpos = async (hid) => {
    console.log("set_bpos", hid);
    let resp = await q_admin_finmov_setb({ rid, hid }).queryFn();
    console.log("set_bpos resp", resp);
    if (resp.status == "error") {
      set_resperr(getv(resp, "err"));
    } else {
      set_respmsg(getv(resp, "result.msg"));
    }
  };

  const fcon = {
    rid,
    set_rid,

    qo_r,
    qo_bbms,

    r,
    bbms,

    set_bpos,
  };
  return (
    <FinMovContext.Provider value={fcon}>
      <div className="h-page">
        <div className="w-[70rem] max-w-[98vw] mx-auto">
          <div className="h-[5rem]"></div>
          <p className="my-2 text-center text-acc0 font-digi resp-text-1">
            FinMov
          </p>
          <div className="fr-cc">
            <InpText
              {...{
                id: "inp_finmov_rid",
                def_val: rid,
                setter: () => {
                  let v = extract_inp("inp_finmov_rid");

                  set_rid(v);
                },
              }}
            />
          </div>
          {!nils(respmsg) && (
            <p className="p-1 border border-green-300 textr-green-300 rounded-md m-2 resp-text--1">
              {respmsg}
            </p>
          )}
          {!nils(resperr) && (
            <p className="p-1 border border-red-300 textr-red-300 rounded-md m-2 resp-text--1">
              {resperr}
            </p>
          )}
          {!nils(rid) && (
            <>
              {qo_r.isLoading ? (
                <Loader01c size="s" />
              ) : qiserr(qo_r) ? (
                <p className="text-red-400 text-center font-digi resp-text--1">
                  {qiserr(qo_r)}
                </p>
              ) : qissuccesss(qo_r) ? (
                <Race />
              ) : null}

              {qo_bbms.isLoading ? (
                <Loader01c size="s" />
              ) : qiserr(qo_bbms) ? (
                <p className="text-red-400 text-center font-digi resp-text--1">
                  {qiserr(qo_bbms)}
                </p>
              ) : qissuccesss(qo_bbms) ? (
                <BBMS />
              ) : null}
            </>
          )}
          <div className="h-[5rem]"></div>
        </div>
      </div>
    </FinMovContext.Provider>
  );
};
