import React, { useEffect, useState } from "react";
import { InpText, Tag } from "../components/utilityComps";
import { extract_inp } from "../components/input.js";
import { cdelay, jstr } from "../utils/utils";
import { Loader01c } from "../components/anims";
import { q_inbox_create_admin_acc } from "../queries/queries";
import { Helmet } from "react-helmet-async";

export const Inbox = () => {
  // const [doc, set_doc] = useState();
  const [resp, set_resp] = useState(null);
  const upload = async () => {
    try {
      set_resp({ loading: true });
      let title = extract_inp("inp_title");
      let msg = document.getElementById("inp_msg").innerText;
      let doc = { title, msg };
      console.log(doc);
      await cdelay(2000);

      let resp = await q_inbox_create_admin_acc(doc).queryFn();
      if (resp.err) throw new Error(resp.err);
      console.log(resp);

      set_resp({ loading: false, msg: "done" });

      setTimeout(() => set_resp(null), 2000);
    } catch (err) {
      set_resp({ loading: false, err: err.message });
      console.log(err);
    }
  };

  return (
    <>
      <div className="h-page">
        <Helmet>
          <title>Admin Announcment</title>
        </Helmet>
        <div className="mx-auto max-w-[98vw] w-[40rem]">
          <div class="h-[5rem]"></div>
          <p class="my-2 resp-text-1 font-digi text-acc0">Admin Announcment</p>

          <div class="fc-cc my-2 resp-gap-2">
            <InpText
              id="inp_title"
              label="Title"
              contprops={{ className: "w-full" }}
              inpprops={{ className: "w-full" }}
              placeholder="Title"
              setter={() => {}}
            />

            <div
              contenteditable="true"
              id="inp_msg"
              className="bg-black border border-acc0 text-white p-2 rounded-md w-full h-[20rem] overflow-auto outline-none"
              placeholder="Message"
            ></div>
          </div>
          <div class="fr-sc w-full">
            {resp?.loading && <Loader01c size="s" />}
            <Tag onClick={upload} className="bg-acc0/40">
              Submit
            </Tag>
          </div>
          {resp?.err && (
            <p class="my-2 resp-text--1 font-digi text-red-400 p-2 border border-red-400">
              {resp.err}
            </p>
          )}
          {resp?.msg && (
            <p class="my-2 resp-text--1 font-digi text-green-400 p-2 border border-green-400">
              {resp.msg}
            </p>
          )}

          <div class="h-[5rem]"></div>
        </div>
      </div>
    </>
  );
};
