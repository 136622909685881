import { ethers } from "ethers";
import abi from "./abi.json";
import { format_ether, getv, iso, nils } from "../../utils/utils.js";
import { contractAddress_list } from "../constants.js";
import { getgasinfo_m, getNonce } from "../../utils/contract_utils";

const getgasinfo = async () => {
  let gas = await getgasinfo_m(5);
  let nonce = await getNonce();
  gas.nonce = nonce + 2;
  console.log("getgasinfo", gas);
  return gas;
};
class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      // event MintLootboxEvent(address vault, uint qty, uint boxtype);
      // event OpenLootboxEvent(address vault, uint qty, uint boxtype);

      if (name == "MintLootboxEvent") {
        let a = par.args;
        o.args = {
          vault: a[0].toLowerCase(),
          qty: Number(a[1]),
          boxtype: Number(a[2]),
        };
      } else if (name == "OpenLootboxEvent") {
        let a = par.args;
        o.args = {
          vault: a[0].toLowerCase(),
          qty: Number(a[1]),
          boxtype: Number(a[2]),
        };
      }

      logrows.push(o);
    }
    return logrows;
  }

  async o_burnLootbox(account, qty, boxtype) {
    let tx = await this.contract.o_burnLootbox(account, qty, boxtype);
    let resp = await tx.wait();
    return resp;
  }

  async openLootbox(qty, boxtype) {
    let tx = await this.contract.openLootbox(qty, boxtype);
    let resp = await tx.wait();
    return resp;
  }

  async accountOwns(account, boxtype) {
    let resp = await this.contract.accountOwns(account, boxtype);
    resp = Number(resp);
    return resp;
  }

  async o_mintLootbox(to, qty, boxtype, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    console.log("o_mintLootbox", info);
    let tx = await this.contract.o_mintLootbox(to, qty, boxtype, info);
    // console.log(tx);
    let resp = await tx.wait();
    return resp;
  }
}

const chain_mode = "main";
const k = "prizebox";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new Contract({ contractAddress, provider, signer });
  return runner;
};

const PrizeBox = {
  k,
  get_contract,
  get_contract_address,
};
export default PrizeBox;
