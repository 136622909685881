import React, { useMemo } from "react";
import {
  q_admin_calc_gameflow,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { dec, getv, nils } from "../utils/utils.js";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import { tablecn } from "../utils/cn_map.js";
import { Card } from "../components/utilityComps.js";

const trowcn = "thintdrowp4 resp-text--2";
const RaceTable = ({ t = {} }) => {
  return (
    <Card className="bg-reg w-full">
      <p className="text-center text-acc0 resp-text-1 font-digi">
        Race Earnings
      </p>
      <table className={twMerge(tablecn.table, "w-max mx-auto")}>
        <tbody>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">#Races</td>
            <td className="text-white">{t.raw.races_n}</td>
          </tr>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">Tot.Fees</td>
            <td className="text-white">
              {dec(t.raw.totfees, 2)}
              {" WETH"}
            </td>
          </tr>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">Tot.Payouts</td>
            <td className="text-white">
              {dec(t.raw.prizes, 2)}
              {" WETH"}
            </td>
          </tr>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">Tot.Earnings</td>
            <td className="text-acc0">
              {dec(t.raw.totearns, 2)}
              {" WETH"}
            </td>
          </tr>
          {[
            ["MinorPot", "9", "minorpot"],
            ["MaxPot", "10", "maxpot"],
            ["MajorPot", "11", "majorpot"],
            ["GameFunds", "40", "gamefunds"],
            ["LC%", "30", "lc"],
          ].map(([a, b, c]) => {
            return (
              <tr className={twMerge(trowcn)}>
                <td className="text-acc0">
                  <div className="fr-sc">
                    <span className="flex-1">{a}</span>
                    <span>[{b}%]</span>
                  </div>
                </td>
                <td className="text-white">
                  {dec(t.pots_add[c], 2)}
                  {" WETH"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};
const BreedTable = ({ t = {} }) => {
  return (
    <Card className="bg-reg w-full">
      <p className="text-center text-acc0 resp-text-1 font-digi">
        Splices / Breed
      </p>
      <table className={twMerge(tablecn.table, "w-max mx-auto")}>
        <tbody>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">#Splices</td>
            <td className="text-white">{t.raw.minted_n}</td>
          </tr>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">Tot.DNAFees</td>
            <td className="text-acc0">
              {dec(t.raw.totfees, 2)}
              {" WETH"}
            </td>
          </tr>
          {[
            ["GameFunds", "70", "gamefunds"],
            ["LC%", "30", "lc"],
          ].map(([a, b, c]) => {
            return (
              <tr className={twMerge(trowcn)}>
                <td className="text-acc0">
                  <div className="fr-sc">
                    <span className="flex-1">{a}</span>
                    <span>[{b}%]</span>
                  </div>
                </td>
                <td className="text-white">
                  {dec(t.pots_add[c], 2)}
                  {" WETH"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};
const SkinMintTable = ({ t = {} }) => {
  return (
    <Card className="bg-reg w-full">
      <p className="text-center text-acc0 resp-text-1 font-digi">
        Skin Boxes Mint
      </p>
      <table className={twMerge(tablecn.table, "w-max mx-auto")}>
        <tbody>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">#Boxes</td>
            <td className="text-white">{t.raw.boxes_n}</td>
          </tr>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">Tot.BoxFees</td>
            <td className="text-acc0">
              {dec(t.raw.totfees, 2)}
              {" WETH"}
            </td>
          </tr>
          {[
            ["Artist", "50", "artist"],
            ["Dev and Tech", "50", "dev_n_tech"],
          ].map(([a, b, c]) => {
            return (
              <tr className={twMerge(trowcn)}>
                <td className="text-acc0">
                  <div className="fr-sc">
                    <span className="flex-1">{a}</span>
                    <span>[{b}%]</span>
                  </div>
                </td>
                <td className="text-white">
                  {dec(t.pots_add[c], 2)}
                  {" WETH"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};
const GameFundTable = ({ t = {} }) => {
  return (
    <Card className="bg-reg w-full">
      <p className="text-center text-acc0 resp-text-1 font-digi">Game Funds</p>
      <table className={twMerge(tablecn.table, "w-max mx-auto")}>
        <tbody>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">#GameFunds</td>
            <td className="text-acc0">
              {dec(t.tot, 2)}
              {" WETH"}
            </td>
          </tr>
          {[
            ["Season Pot", "33", "season"],
            ["Dev and Tech:2", "50", "dev_n_tech_2"],
            ["Grand Final", "17", "grand_final"],
          ].map(([a, b, c]) => {
            return (
              <tr className={twMerge(trowcn)}>
                <td className="text-acc0">
                  <div className="fr-sc">
                    <span className="flex-1">{a}</span>
                    <span>[{b}%]</span>
                  </div>
                </td>
                <td className="text-white">
                  {dec(t[c], 2)}
                  {" WETH"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};
const PotsSummary = ({ t = {} }) => {
  return (
    <Card className="bg-reg w-full">
      <p className="text-center text-acc0 resp-text-1 font-digi">Pots Summary</p>
      <table className={twMerge(tablecn.table, "w-max mx-auto")}>
        <tbody>
          <tr className={twMerge(trowcn)}>
            <td className="text-acc0">Tot.GameFunds</td>
            <td className="text-white">
              {dec(t.gamefunds.tot, 2)}
              {" WETH"}
            </td>
          </tr>
          {[
            ["Tot.LC%", "", "lc"],
            ["MinorPot", "", "minorpot"],
            ["MaxPot", "", "maxpot"],
            ["MajorPot", "", "majorpot"],
            ["Artist", "", "artist"],
            ["Tot.Dev n Tech", "", "tot_dev_n_tech"],
          ].map(([a, b, c]) => {
            return (
              <tr className={twMerge(trowcn)}>
                <td className="text-acc0">
                  <div className="fr-sc">
                    <span className="flex-1">{a}</span>
                    {!nils(b) && <span>[{b}%]</span>}
                  </div>
                </td>
                <td className="text-white">
                  {dec(t[c], 2)}
                  {" WETH"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export const GameFlow = () => {
  const [qo] = useQueries([q_admin_calc_gameflow()]);
  const g = useMemo(() => getv(qo, "data.result") ?? null, [qo.dataUpdatedAt]);
  return (
    <div className="h-page">
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[3rem]"></div>
        <p className="text-center text-acc0 font-digi resp-text-1">Game Flow</p>
        <div className="h-[1rem]"></div>

        {qo.isLoading ? (
          <Loader01c size="lg" />
        ) : qiserr(qo) ? (
          <p className="text-center text-red-300 font-digi resp-text-1">
            {qiserr(qo)}
          </p>
        ) : qissuccesss(qo) ? (
          <div className="grid grid-cols-2 resp-gap-2">
            <RaceTable t={g.race} />
            <BreedTable t={g.breed} />
            <SkinMintTable t={g.skinmint} />
            <GameFundTable t={g.finalpots?.gamefunds} />
            <div className="col-span-2">
              <PotsSummary t={g.finalpots} />
            </div>
          </div>
        ) : null}
        <div className="h-[3rem]"></div>
      </div>
    </div>
  );
};
