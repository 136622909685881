import { ethers } from "ethers";
import web3 from "web3";
import { fget } from "../queries/fetch";
import { getv, nils } from "./utils";

export const getgasinfo_m = async (mul) => {
  let resp = await fget("https://gasstation.polygon.technology/v2");
  let gas = getv(resp, "standard.maxFee");
  gas = web3.utils.toWei(gas, "gwei");
  let gasPrice = parseInt(Math.floor(gas * mul));
  if (gasPrice > 100) gasPrice *= 2;
  return { gasPrice, gasLimit: 500000 * mul };
};

export const getNonce = async () => {
  const chain_mode = "main";
  const provider = new ethers.BrowserProvider(window.ethereum);
  let address = window.ethereum.selectedAddress;
  const nonce = await provider.getTransactionCount(address);
  console.log("nonce", {
    address,
    nonce,
  });
  return nonce;
};
