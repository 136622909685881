import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import tailwindcss_colors from "tailwindcss/colors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { contrastColor } from "contrast-color";
import _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
ChartJS.register(ChartDataLabels);

export const rc_all = {
  dists: [
    // 9000,
    1000, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600,
  ],
  cls: [
    // 77,
    0, 1, 2, 3, 4, 5, 6, 99,
  ],
  poss: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  fla: [
    // 0, // n
    1, // free
    2, // paid
    3, // flame
    4, // nflame
    5, // free_flame
    6, // free_nflame
    7, // paid_flame
    8, // paid_nflame
  ],
};

export function BarChart({
  cdata,
  labels,
  title,
  backgroundColor = null,
  label,
  formatter = (val) => val,
  datalabelsColor,
  ext_options = {},
}) {
  if (!backgroundColor) backgroundColor = tailwindcss_colors.blue[600];
  if (!datalabelsColor)
    datalabelsColor = contrastColor({ bgColor: backgroundColor });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
      datalabels: {
        display: true,
        formatter,
        color: datalabelsColor,
      },
    },
    ...ext_options,
  };
  const data = {
    labels,
    datasets: [
      {
        label: label,
        data: cdata,
        backgroundColor: backgroundColor,
      },
    ],
  };
  // return <Bar options={options} data={data} />;
  return <Bar options={options} data={data} />;
}
