import { faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueries } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../App";
import { Loader01c } from "../components/anims";
import {
  extract_inp,
  F_DateInput,
  F_Input,
  F_InputRange,
  F_InputTextAr,
  F_OptionInput,
  set_state_ob,
} from "../components/input";
import { Card, InpText, Tag } from "../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_factions_missions_doc,
  q_factions_missions_list,
  q_factions_missions_presets_list,
  q_factions_missions_presets_save,
  q_factions_missions_presets_get,
  q_factions_missions_presets_delete,
  q_factions_missions_delete,
  q_factions_missions_update,
} from "../queries/queries";
import { pos_txt } from "../utils/cn_map";
import { cdelay, getv, iso, jstr, nils } from "../utils/utils";

const ListContext = createContext({});
const useListContext = () => useContext(ListContext);
export const FactionsMissionsList = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [searchtxt, set_searchtxt] = useState("");

  const [qo_list] = useQueries([q_factions_missions_list({})]);
  const misslist = useMemo(() => {
    return getv(qo_list, "data.result") || [];
  }, [qo_list.dataUpdatedAt]);
  const filtmisslist = useMemo(() => {
    if (nils(searchtxt)) return misslist;
    let filt = _.chain(misslist)
      .filter((e) => {
        let [title, desc] = [e.title, e.desc].map((e) =>
          nils(e) ? null : e.toLowerCase(),
        );
        if (!nils(title) && title.includes(searchtxt.toLowerCase()))
          return true;
        if (!nils(desc) && desc.includes(searchtxt.toLowerCase())) return true;
        return false;
      })
      .value();
    return filt;
  }, [jstr(misslist), searchtxt]);

  return (
    <div class="h-page">
      <div class="w-[60rem] max-w-[95vw] mx-auto">
        <div class="h-[4rem]"></div>
        <p class="text-center text-acc0">Factions Missions List</p>

        <div className="fr-sc resp-gap-2 my-2">
          <InpText
            {...{
              id: "facmissionsearch",
              placeholder: "search",
              contprops: { className: "w-[20rem] bg-reg" },
              inpprops: { className: "w-full" },
              setter: (v) => {
                if (nils(v)) v = null;
                set_searchtxt(v);
              },
            }}
          />
          <div className="flex-1"></div>
          <span>filtered {filtmisslist.length}</span>
          <span>/</span>
          <span>{misslist.length} Fquals</span>
        </div>
        <div class="fr-sc">
          <div class="flex-1"></div>
          <Tag
            redirect="/factions_missions_list/new"
            className="bg-acc0/40 font-digi resp-text--1"
          >
            <span>New Mission</span>
          </Tag>
        </div>
        <div class="my-2"></div>

        {qo_list.isLoading ? (
          <Loader01c />
        ) : qiserr(qo_list) ? (
          <p class="text-center text-red-400">{qiserr(qo_list)}</p>
        ) : qissuccesss(qo_list) ? (
          <div class="">
            {filtmisslist.map((m) => {
              return (
                <Link
                  target="_blank"
                  to={`/factions_missions_list/${m.missid}`}
                >
                  <Card className="w-full border border-acc0">
                    <div class="fr-sc resp-gap-2 resp-text-0">
                      <div class="fc-ss flex-1">
                        <span class="text-acc0 resp-text--2">{m.missid}</span>
                        <span class="text-white">{m.title}</span>
                        <span class="text-white">{m.desc}</span>
                      </div>
                      <div class="fc-cs min-w-[5rem]">
                        <span class="text-white resp-text--1">
                          x{m.repeat_count}
                        </span>
                        <span class="text-white resp-text--1">
                          {m.difficulty}
                        </span>
                        <span class="text-white resp-text--1">
                          {m.points} Pts
                        </span>
                      </div>
                    </div>
                  </Card>
                </Link>
              );
            })}
          </div>
        ) : null}

        <div class="h-[5rem]"></div>
      </div>
    </div>
  );
};

const MissionContext = createContext({});
const useMissionContext = () => useContext(MissionContext);

export const InfoTab = () => {
  const mcon = useMissionContext();
  const { nf, set_nf } = mcon;
  const inpprops = { nf, set_nf, nfkey: "miss" };
  return (
    <>
      <table
        className={twMerge("thintdrowp4-table mx-auto w-full resp-text--2")}
      >
        <tbody>
          <tr>
            <td>title</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  ...inpprops,
                  path: "title",
                  type: "txt",
                  contprops: { className: "w-full" },
                  inpprops: { className: "w-full" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>desc</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  ...inpprops,
                  path: "desc",
                  type: "txt",
                  contprops: { className: "w-full" },
                  inpprops: { className: "w-full" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Active</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  ...inpprops,
                  path: "active",
                  type: "option",
                  options: [true, false],
                  txt_fn: (o) => (o ? "Active" : "Inactive"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Difficulty</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  ...inpprops,
                  path: "difficulty",
                  type: "option",
                  options: ["easy", "medium", "hard"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Reward Points</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  ...inpprops,
                  path: "points",
                  el_postprocess_fn: (e) =>
                    nils(parseInt(e)) ? 0 : parseInt(e),
                  contprops: { className: "w-[4rem]" },
                  contprops: { className: "w-[4rem]" },
                  inpprops: { className: "w-full" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Repeat Count[eg: 1/3]</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  ...inpprops,
                  path: "repeat_count",
                  el_postprocess_fn: (e) =>
                    nils(parseInt(e)) ? 1 : parseInt(e),
                  contprops: { className: "w-[4rem]" },
                }}
              />
            </td>
          </tr>

          <tr className="">
            <td>
              <span className="text-acc0">Bike/Agent Criteria</span>
              <span className="text-white">
                [{getv(nf, "entry_filters")?.length || 0} Rules]
              </span>
            </td>
            <td>
              <Tag
                onClick={() => {
                  let new_criterias = [
                    ...(getv(nf, "entry_filters") || []),
                    {},
                  ];
                  set_state_ob(nf, set_nf, "entry_filters", new_criterias);
                }}
                className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Tag>
            </td>
          </tr>
          {(getv(nf, "entry_filters") || []).map((e, i) => {
            return (
              <tr colSpan={13} key={i}>
                <td colSpan={13}>
                  <Card className={"w-full"}>
                    <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                      <Tag>#{i + 1}</Tag>
                      <div className="flex-1"></div>
                      <Tag
                        onClick={() => {
                          let new_criterias = _.chain(getv(nf, "entry_filters"))
                            .filter((e, idx) => idx != i)
                            .value();

                          set_state_ob(
                            nf,
                            set_nf,
                            "entry_filters",
                            new_criterias,
                          );
                        }}
                        className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Tag>
                    </div>
                    <table className="w-full thintdrowp4-table">
                      <tbody>
                        <tr>
                          <td>element</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`entry_filters.${i}.element`}
                              type="options"
                              options={["metal", "fire", "earth", "water"]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>type</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`entry_filters.${i}.type`}
                              type="options"
                              options={[
                                "genesis",
                                "morphed",
                                "freak",
                                "xclass",
                              ]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`entry_filters.${i}.gender`}
                              type="options"
                              options={["male", "female"]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>F.No.</td>
                          <td>
                            <div className="fc-ss">
                              <F_Input
                                {...inpprops}
                                path={`entry_filters.${i}.fno`}
                                disp_txt={(v) => {
                                  if (nils(v)) return "";
                                  else if (_.isArray(v)) return v.join(",");
                                  else if (_.isObject(v))
                                    return `${v.mi}-${v.mx}`;
                                  else return v;
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e.includes(","))
                                    return e.split(",").map((e) => parseInt(e));
                                  else if (e.includes("-"))
                                    return {
                                      mi: parseInt(e.split("-")[0]),
                                      mx: parseInt(e.split("-")[1]),
                                    };
                                  else return [parseInt(e)];
                                }}
                              />
                              <p className="resp-text--3">
                                {jstr(getv(nf, `entry_filters.${i}.fno`))}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>skin</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_Input
                                {...inpprops}
                                path={`entry_filters.${i}.skin`}
                                disp_txt={(v) => {
                                  if (v?.by == "any") return "any";
                                  if (_.isObject(v)) {
                                    return `${v.by}=${(v.ar ?? []).join(",")}`;
                                  }
                                  return "";
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e == "any") return { by: "any" };
                                  else if (e.includes("=")) {
                                    let by = e.split("=")[0];
                                    let ar = e.split("=")[1].split(",");
                                    return { by, ar };
                                  }
                                  return null;
                                }}
                              />

                              <p className="resp-text--3">
                                {jstr(getv(nf, `entry_filters.${i}.skin`))}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>trail</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_Input
                                {...inpprops}
                                path={`entry_filters.${i}.trail`}
                                disp_txt={(v) => {
                                  if (v?.by == "any") return "any";
                                  if (_.isObject(v)) {
                                    return `${v.by}=${(v.ar ?? []).join(",")}`;
                                  }
                                  return "";
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e == "any") return { by: "any" };
                                  else if (e.includes("=")) {
                                    let by = e.split("=")[0];
                                    let ar = e.split("=")[1].split(",");
                                    return { by, ar };
                                  }
                                  return null;
                                }}
                              />

                              <p className="resp-text--3">
                                {jstr(getv(nf, `entry_filters.${i}.trail`))}
                              </p>
                            </div>
                          </td>
                        </tr>
                        {["races_n", "win_p", "paid_races_n", "paid_win_p"].map(
                          (k) => {
                            return (
                              <React.Fragment key={k}>
                                <tr>
                                  <td>{k}</td>
                                  <td>
                                    <div className="fr-sc">
                                      <F_Input
                                        {...inpprops}
                                        path={`entry_filters.${i}.${k}.cb`}
                                        placeholder="cb/all"
                                        contprops={{
                                          className: "w-[4rem]",
                                        }}
                                      />
                                      <F_InputRange
                                        {...inpprops}
                                        path={`entry_filters.${i}.${k}`}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          },
                        )}
                        <tr>
                          <td>Birthday</td>

                          <td>
                            <div className="fr-sc">
                              <F_DateInput
                                {...inpprops}
                                {...{ path: `entry_filters.${i}.birthday` }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>is_maiden</td>
                          <td>
                            <div className="fr-sc">
                              <F_OptionInput
                                {...inpprops}
                                path={`entry_filters.${i}.is_maiden`}
                                type={"option"}
                                options={[true, false]}
                                txt_fn={(o) => (o ? "true" : "false")}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </td>
              </tr>
            );
          })}

          <tr>
            <td>
              <div class="fc-ss">
                <span>Check for CONSECUTIVE race criteria X times</span>
                <span>3 rules </span>
                <span>[1,1,3]</span>
              </div>
            </td>
            <td>
              <div class="fc-ss">
                <F_InputTextAr
                  {...inpprops}
                  path={`scoring.racecr_consec_match_each_n`}
                  el_postprocess_fn={(e) => parseInt(e)}
                />
                <span>
                  {jstr(getv(nf, "scoring.racecr_consec_match_each_n"))}
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="fc-ss">
                <span>Check for each race criteria X times</span>
                <span>3 rules </span>
                <span>[1,1,3]</span>
              </div>
            </td>
            <td>
              <div class="fc-ss">
                <F_InputTextAr
                  {...inpprops}
                  path={`scoring.racecr_match_each_n`}
                  el_postprocess_fn={(e) => (nils(e) ? "" : parseInt(e))}
                />
                <span>{jstr(getv(nf, "scoring.racecr_match_each_n"))}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="fc-ss">
                <span>Check for each race criteria X times</span>
                <span>MiMatch to qualify</span>
                <span>Eg: you have 5 rules</span>
                <span>if any 2 verify you qualify</span>
                <span></span>
              </div>
            </td>
            <td>
              <div class="fc-ss">
                <F_Input
                  {...inpprops}
                  path={`scoring.racecr_match_each_minqualn`}
                  el_postprocess_fn={(e) => parseInt(e)}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span className="text-acc0">Race Criteria</span>
              <span className="text-white">
                [{getv(nf, "scoring.criteria")?.length || 0} Rules]
              </span>
            </td>
            <td>
              <Tag
                onClick={() => {
                  let new_criterias = [
                    ...(getv(nf, "scoring.criteria") || []),
                    { race: {} },
                  ];
                  set_state_ob(nf, set_nf, "scoring.criteria", new_criterias);
                }}
                className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Tag>
            </td>
          </tr>

          {(getv(nf, "scoring.criteria") || []).map((e, i) => {
            return (
              <tr colSpan={13} key={i}>
                <td colSpan={13}>
                  <Card className={"w-full"}>
                    <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                      <Tag>#{i + 1}</Tag>
                      <div className="flex-1"></div>
                      <Tag
                        onClick={() => {
                          let new_criterias = _.chain(
                            getv(nf, "scoring.criteria"),
                          )
                            .filter((e, idx) => idx != i)
                            .value();

                          set_state_ob(
                            nf,
                            set_nf,
                            "scoring.criteria",
                            new_criterias,
                          );
                        }}
                        className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Tag>
                    </div>

                    <table className="w-full thintdrowp4-table">
                      <tbody>
                        <tr>
                          <td>version</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`scoring.criteria.${i}.race.version`}
                              type="options"
                              options={[1, 2, 3]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>paytoken</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`scoring.criteria.${i}.race.paytoken`}
                              type="options"
                              options={["WETH", "DEZ"]}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>class</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`scoring.criteria.${i}.race.class`}
                              type="options"
                              options={[40, 50, 60, 70, 80, 90, 5]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>CB</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`scoring.criteria.${i}.race.cb`}
                              type="options"
                              options={[
                                ...[9, 11, 13, 19, 21, 23],
                                ...[10, 12, 14, 16, 18, 20, 22],
                                ...[15, 17],
                              ]}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="fc-ss">
                              <span>Running length</span>
                              <span>eg: 15000</span>
                            </div>
                          </td>
                          <td>
                            <F_Input
                              {...inpprops}
                              path={`scoring.criteria.${i}.race.runninglen`}
                              el_postprocess_fn={(e) => parseInt(e)}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>format</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`scoring.criteria.${i}.race.format`}
                              type="options"
                              options={[
                                "normal",
                                "reduced",
                                "spin_n_go",
                                "sub_rounds",
                              ]}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>skin</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_Input
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.skin`}
                                disp_txt={(v) => {
                                  if (v?.by == "any") return "any";
                                  if (_.isObject(v)) {
                                    return `${v.by}=${(v.ar ?? []).join(",")}`;
                                  }
                                  return "";
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e == "any") return { by: "any" };
                                  else if (e.includes("=")) {
                                    let by = e.split("=")[0];
                                    let ar = e.split("=")[1].split(",");
                                    return { by, ar };
                                  }
                                  return null;
                                }}
                              />

                              <p className="resp-text--3">
                                {jstr(
                                  getv(nf, `scoring.criteria.${i}.race.skin`),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>R-Gates</td>
                          <td>
                            <F_InputTextAr
                              {...inpprops}
                              delimiter="|"
                              path={`scoring.criteria.${i}.race.rgate`}
                              type="options"
                              el_postprocess_fn={(e) => parseInt(e)}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>Payout</td>
                          <td>
                            <div className="fc-ss">
                              <F_OptionInput
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.payout`}
                                type="options"
                                options={[
                                  "wta",
                                  "top2",
                                  "top3",
                                  "top4",
                                  "dblup",
                                  "pity",
                                ]}
                              />
                              <F_InputTextAr
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.payout`}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(nf, `scoring.criteria.${i}.race.payout`),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Star</td>
                          <td>
                            <div className="fc-ss">
                              <F_OptionInput
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.star`}
                                type="options"
                                options={[0, 2, 3, 5]}
                                txt_fn={(o) =>
                                  o == 0
                                    ? "no-star"
                                    : o == 1
                                      ? "no-star"
                                      : o == 2
                                        ? "blue"
                                        : o == 3
                                          ? "yellow"
                                          : "both"
                                }
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Fee</td>
                          <td>
                            <div className="fc-ss">
                              <div class="fr-sc">
                                <F_InputRange
                                  {...inpprops}
                                  path={`scoring.criteria.${i}.race.fee`}
                                />
                                <F_OptionInput
                                  {...{
                                    ...inpprops,
                                    path: `scoring.criteria.${i}.race.fee_mode`,
                                    type: "option",
                                    options: ["usd", "token"],
                                  }}
                                />
                              </div>
                              <p className="resp-text--3">
                                {jstr(
                                  getv(nf, `scoring.criteria.${i}.race.fee`),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Prize</td>
                          <td>
                            <div className="fc-ss">
                              <div class="fr-sc">
                                <F_InputRange
                                  {...inpprops}
                                  path={`scoring.criteria.${i}.race.prize`}
                                />
                                <F_OptionInput
                                  {...{
                                    ...inpprops,
                                    path: `scoring.criteria.${i}.race.prize_mode`,
                                    type: "option",
                                    options: ["usd", "token"],
                                  }}
                                />
                              </div>
                              <p className="resp-text--3">
                                {jstr(
                                  getv(nf, `scoring.criteria.${i}.race.prize`),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Race Name</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_InputTextAr
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.race_name`}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(
                                    nf,
                                    `scoring.criteria.${i}.race.race_name`,
                                  ),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>is_chain</td>
                          <td>
                            <F_OptionInput
                              {...inpprops}
                              path={`scoring.criteria.${i}.race.is_chain`}
                              type="option"
                              options={[true, false, null]}
                              txt_fn={(o) =>
                                nils(o) ? "null" : o === true ? "true" : "false"
                              }
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>eventtags</td>
                          <td>
                            <div class="fc-ss my-2 resp-gap-2">
                              <F_OptionInput
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.eventtags`}
                                type="options"
                                options={[
                                  // "maiden_qual",
                                  "F1-2",
                                  "Genesis",
                                  "ME",
                                  "Maiden Qualifer",
                                  "CLM<0.01",
                                  "CLM<0.02",
                                  "CLM<0.03",
                                  "CLM<0.04",
                                  "CLM<0.05",
                                  "CLM<0.10",
                                  "CLM<0.20",
                                  "Trainer",
                                ]}
                                txt_fn={(o) => o}
                              />
                              <F_InputTextAr
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.eventtags`}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(
                                    nf,
                                    `scoring.criteria.${i}.race.eventtags`,
                                  ),
                                )}
                              </p>{" "}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>specialcase</td>
                          <td>
                            <div class="fc-ss my-2 resp-gap-2">
                              <F_OptionInput
                                {...inpprops}
                                path={`scoring.criteria.${i}.race.specialcase`}
                                type="option"
                                options={["lowest_F", "highest_F", "not_last"]}
                                txt_fn={(o) => o}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(
                                    nf,
                                    `scoring.criteria.${i}.race.specialcase`,
                                  ),
                                )}
                              </p>{" "}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Pos</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_OptionInput
                                {...inpprops}
                                type="options"
                                path={`scoring.criteria.${i}.race.pos`}
                                options={[
                                  -1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                ]}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const PresetsRow = () => {
  const [show, set_show] = useState(false);

  const mcon = useMissionContext();
  const { nf, set_nf, qo_doc } = mcon;

  const q_list = q_factions_missions_presets_list;
  const q_save = q_factions_missions_presets_save;
  const q_get = q_factions_missions_presets_get;
  const q_delete = q_factions_missions_presets_delete;

  const [qo_presets_list] = useQueries([q_list()]);
  const list = useMemo(() => {
    return getv(qo_presets_list, "data.result") || [];
  }, [qo_presets_list.dataUpdatedAt]);

  const f_save_preset = async () => {
    set_resp({ loading: true, msg: "Saving Preset" });
    try {
      let preset_name = extract_inp("fqual-preset-name");
      let res = await q_save({
        preset_name,
        doc: nf,
      }).queryFn();
      if (res?.status == "success") {
        console.log(res);
        let presetid = getv(res, "result.presetid");
        set_resp({
          loading: false,
          status: "success",
          msg: `Saved presetid:${presetid}`,
        });
      } else {
        console.log(res);
        set_resp({ loading: false, status: "error", msg: res.err });
      }
    } catch (err) {
      console.log(err);
      set_resp({ loading: false, status: "error", msg: err.message });
    }
  };

  const use_preset = async (presetid) => {
    try {
      set_resp({ loading: true, msg: "Loading Preset" });
      let res = await q_get({ presetid }).queryFn();
      console.log(res);
      if (res?.status == "success") {
        let f = getv(res, "result.doc") || {};
        console.log(f);
        if (nils(f)) throw new Error("preset is empty");
        set_nf(f);
        set_resp({ loading: false, status: "success", msg: "Loaded" });
      } else if (res.err) {
        set_resp({ loading: false, status: "error", msg: res.err });
      }
    } catch (err) {
      set_resp({ loading: false, status: "error", msg: err.message });
    }
    set_resp({ ...resp, loading: false });
  };

  const delete_preset = async (presetid) => {
    try {
      set_resp({ loading: true, msg: "Deleting Preset" });
      let res = await q_delete({ presetid }).queryFn();
      if (res?.status == "success") {
        set_resp({ loading: false, status: "success", msg: "Deleted" });
      } else if (res.err) {
        set_resp({ loading: false, status: "error", msg: res.err });
      }
      setTimeout(qo_presets_list.refetch, 2000);
    } catch (err) {
      set_resp({ loading: false, status: "error", msg: err.message });
    }
  };

  const [resp, set_resp] = useState(undefined);

  return (
    <Card className={"w-full bg-reg resp-text--2"}>
      <div className="fr-sc">
        {resp && (
          <p
            className={twMerge(
              "resp-p-2 fr-sc resp-gap-2",
              resp?.status == "error"
                ? "border-red-300 text-red-300"
                : resp?.status == "success"
                  ? "border-green-300 text-green-300"
                  : "text-white",
            )}
          >
            {resp.loading && <Loader01c size="s" />}
            <span>{resp.msg}</span>
          </p>
        )}
      </div>
      <div className="fr-sc">
        <Tag
          onClick={() => set_show(!show)}
          className={twMerge(show ? "text-yellow-400" : "text-acc0")}
        >
          <span>{show ? "Hide Presets" : "Show Presets"}</span>
        </Tag>
        <div className="flex-1"></div>
        <InpText id="fqual-preset-name" placeholder="Enter Preset Name:" />
        <Tag onClick={f_save_preset} className="bg-green-500">
          <span>Save Preset</span>
        </Tag>
      </div>
      <hr className="my-2" />
      {show && (
        <>
          <div className="fr-sc">
            <Tag
              onClick={() => qo_presets_list.refetch()}
              className="text-orange-300 border-orange-300 fr-sc resp-gap-1"
            >
              {qo_presets_list.isLoading && <Loader01c size="s" />}
              <span>Refresh</span>
            </Tag>
          </div>
          <div className="grid grid-cols-3 gap-2">
            {list.map((e) => {
              return (
                <Card
                  key={e.presetid}
                  className="w-full border border-slate-400 resp-p-2"
                >
                  <div className="fr-sc resp-gap-2">
                    <p className="font-digi">${e.presetid}</p>
                    <span>-</span>
                    <p className="font-digi">{e.preset_name}</p>
                  </div>
                  <div className="fr-sc resp-gap-2">
                    <Tag
                      className="bg-acc0/40"
                      onClick={() => {
                        use_preset(e.presetid);
                      }}
                    >
                      <span>Use</span>
                    </Tag>
                    <div className="flex-1"></div>
                    <Tag
                      className="bg-red-500"
                      onClick={() => {
                        delete_preset(e.presetid);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Tag>
                  </div>
                </Card>
              );
            })}
          </div>
        </>
      )}
    </Card>
  );
};

const ControlRow = () => {
  const history = useNavigate();
  const mcon = useMissionContext();
  const { missid, nf, set_nf, qo_doc, qo_leader } = mcon;

  const f_delete = async () => {
    try {
      if (nils(missid)) return;
      let res = await q_factions_missions_delete({ missid }).queryFn();
      if (res?.status == "success") {
        await cdelay(1000);
        history("/fqual");
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const f_update = async () => {
    try {
      console.log("f_update", nf);
      if (nils(missid)) return;
      let res = await q_factions_missions_update({ missid, doc: nf }).queryFn();
      if (res?.status == "success") {
        await cdelay(1000);
        if (missid == "new") {
          let nmissid = getv(res, "result.missid");
          history(`/factions_missions_list/${nmissid}`);
        } else window.location.reload();
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const f_reset = async () => {
    try {
      if (nils(missid)) return;
      let f = getv(qo_doc, "data.result") || {};
      set_nf(f);
    } catch (err) {
      console.log(err);
    }
  };

  const f_refetch = async () => {
    try {
      if (nils(missid)) return;
      await qo_doc.refetch();
      await qo_leader.refetch();
      let f = getv(qo_doc, "data.result") || {};
      set_nf(f);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card className={"w-full bg-reg"}>
      <div className="fr-sc resp-gap-2">
        {missid !== "new" && (
          <Tag
            onClick={f_reset}
            className="fr-sc resp-gap-1 resp-text--1 bg-yellow-600"
          >
            <span>Reset Default</span>
          </Tag>
        )}

        <Tag
          onClick={f_refetch}
          className="fr-sc resp-gap-1 resp-text--1 bg-acc0/40"
        >
          <span>Update to Latest</span>
        </Tag>

        <div className="flex-1"></div>
        <Tag
          onClick={f_delete}
          className="fr-sc resp-gap-1 resp-text--1 bg-red-500"
        >
          <FontAwesomeIcon icon={faTrash} />
          <span>Delete</span>
        </Tag>
        <Tag
          onClick={f_update}
          className="fr-sc resp-gap-1 resp-text--1 bg-green-500"
        >
          <FontAwesomeIcon icon={faSave} />
          <span>Save</span>
        </Tag>
      </div>
    </Card>
  );
};

export const FactiosnMissionPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const { missid } = useParams();
  const [qo_doc] = useQueries([
    q_factions_missions_doc({ missid }, { enabled: missid != "new" }),
  ]);
  const [nf, set_nf] = useState(missid == "new" ? {} : undefined);
  const f = useMemo(() => {
    if (qissuccesss(qo_doc)) {
      let f = getv(qo_doc, "data.result") || {};
      if (nf === undefined) set_nf(f);
      return f;
    }
    return {};
  }, [qo_doc.dataUpdatedAt]);

  const mcon = {
    missid,
    nf,
    set_nf,
  };
  useEffect(() => {
    console.log("nf", nf);
  }, [jstr(nf)]);
  return (
    <MissionContext.Provider value={mcon}>
      <div class="h-page">
        <div class="w-[50rem] max-w-[95vw] mx-auto">
          <div className="h-[2rem]"></div>

          <div className="fr-sc">
            {missid == "new" ? (
              <p className="text-center resp-text-1 text-orange-300 font-digi">
                Create New Mission
              </p>
            ) : (
              <p className="text-center resp-text-1 text-acc0 font-digi">
                Mission - {missid}
              </p>
            )}
          </div>

          <div class="h-[1rem]"></div>
          <PresetsRow />
          <div class="h-[1rem]"></div>
          <ControlRow />
          <div class="h-[1rem]"></div>

          {qo_doc.isLoading && <Loader01c />}
          <InfoTab />
          <div class="h-[5rem]"></div>
        </div>
      </div>
    </MissionContext.Provider>
  );
};
