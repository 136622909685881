// const abi = require("./abi.json");
// const ethers = require("ethers");
// const _ = require("lodash");
// const { contractAddress_list } = require("../constants.js");
import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list, getgasinfo } from "../constants.js";
import _ from "lodash";
import { getv } from "../../utils/utils.js";

class SplicingContract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async withdrawFunds(to, amount) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.withdrawFunds(to, amount, gasinfo);
    const resp = await tx.wait();
    return resp;
  }
}

const get_SplicingContract = async () => {
  const contractAddress = contractAddress_list.splicing;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();

  const pars = { contractAddress, provider, signer };
  const runner = new SplicingContract(pars);
  return runner;
};

const Splicing = {
  class: SplicingContract,
  get_contract: get_SplicingContract,
};

export default Splicing;
