import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQueries } from "react-query";
import {
  q_admin_calc_rfetable,
  q_token_prices,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { dec, getv, iso, jstr, nils } from "../utils/utils.js";
import { useAppContext } from "../App.js";
import { Loader01c } from "../components/anims.js";
import { tablecn } from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { faFlag, faUsd } from "@fortawesome/free-solid-svg-icons";
import { Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";

const DContext = createContext();
export const useDContext = () => useContext(DContext);

export const DailyEarnings = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch, tok_to_usd_val, tokmap } = appcon;
  const [n, set_n] = useState(psearch.n ?? 10);
  useEffect(() => {
    upd_psearch({ n });
  }, [n]);

  const [qotok] = useQueries([q_token_prices({ n })]);
  const ethusd = useMemo(() => {
    let ethusd = getv(qotok, "data.result.ethusd");
    return ethusd;
  }, [qotok.dataUpdatedAt]);

  const [qo] = useQueries([q_admin_calc_rfetable({ n })]);
  const d = useMemo(() => {
    if (!qissuccesss(qo)) return [];
    let ar = getv(qo, "data.result");
    // console.log("ar", ar);
    return ar;
  }, [qo.dataUpdatedAt]);

  const openfunds = getv(d, "openfunds");
  const [tokview, set_tokview] = useState("USD");

  const tokdecn = (token) =>
    token == "WETH"
      ? 3
      : token == "ETH"
        ? 3
        : token == "DEZ"
          ? 0
          : token == "USD"
            ? 0
            : 0;
  const rows = useMemo(() => {
    if (nils(d?.rows)) return [];
    let rs = _.map(d.rows, (row) => {
      let eachrow = row;
      let tot = {};
      for (let v of ["race_v1", "race_v2", "race_v3", "splice"]) {
        let r = row[v] || {};
        let token = r.token;
        if (nils(token)) token = "WETH";
        let ins_usd = tok_to_usd_val(r.ins, token);
        let outs_usd = tok_to_usd_val(r.outs, token);
        let profit_usd = tok_to_usd_val(r.profit, token);
        eachrow[v] = { ...r, ins_usd, outs_usd, profit_usd, token };
        tot.profit_usd = (tot.profit_usd || 0) + profit_usd;
      }
      eachrow.tot = tot;
      return eachrow;
    });
    // console.log("rs", rs);
    return rs;
  }, [jstr(d), jstr(tokmap)]);

  const dcon = {};
  return (
    <DContext.Provider value={dcon}>
      <Helmet>
        <title>{"Earnings | Admin"}</title>
      </Helmet>

      <div className="h-page">
        <div className="max-w-[98vw] w-[70rem] mx-auto">
          <div className="h-[2rem]"></div>
          <p className="resp-text-2 text-center font-digi text-acc0 my-4">
            Daily Earnings
          </p>
          <div class="fr-sc">
            <div className="fr-cc flex-1h">
              {[10, 30, 45, 60, 90].map((_n) => {
                return (
                  <Tag
                    onClick={() => set_n(_n)}
                    className={twMerge(
                      n == _n
                        ? "bg-acc0/40 -skew-x-12 text-white"
                        : "text-acc0",
                    )}
                  >
                    {_n}D
                  </Tag>
                );
              })}
            </div>

            <div class="flex-1"></div>
            <div class="fr-sc">
              {["USD", "Token"].map((_t) => {
                return (
                  <Tag
                    onClick={() => set_tokview(_t)}
                    className={twMerge(
                      tokview == _t
                        ? "bg-acc0/40 -skew-x-12 text-white"
                        : "text-acc0",
                    )}
                  >
                    {_t}
                  </Tag>
                );
              })}
            </div>
          </div>

          <div className="h-[2rem]"></div>
          {qo.isLoading ? (
            <Loader01c />
          ) : qiserr(qo) ? (
            <p className="text-red-400 text-center">{qiserr(qo)}</p>
          ) : qissuccesss(qo) ? (
            <>
              <table className={tablecn.table}>
                <thead>
                  <tr className="thintdrow text-center text-acc0 resp-text--1">
                    <td>Date[UTC]</td>
                    <td>Fin#Races</td>
                    <td colSpan={1}>RacesV1</td>
                    <td colSpan={1}>RacesV2</td>
                    <td colSpan={1}>RacesV3</td>
                    <td colSpan={1}>Splice</td>
                    <td>Profit</td>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((r, i) => {
                    let today = i == 0 || r.date == iso().slice(0, 10);
                    let totprofit = today
                      ? r.tot.profit - openfunds
                      : r.tot.profit;
                    return (
                      <tr className="thintdrow text-white resp-text--1">
                        <td>{r.date}</td>
                        <td>
                          <div className="flex flex-row justify-end items-center resp-gap-2">
                            <FontAwesomeIcon icon={faFlag} />
                            <span>{r.rids_n}</span>
                            <span>{"Races"}</span>
                          </div>
                        </td>
                        {["race_v1", "race_v2", "race_v3", "splice"].map(
                          (k) => {
                            return (
                              <td>
                                <div className="fc-cc">
                                  {tokview == "USD" ? (
                                    <>
                                      <div className="w-full flex flex-row justify-end items-center resp-gap-2">
                                        <span>IN</span>
                                        <span>
                                          {dec(r[k]?.ins_usd, tokdecn("USD"))}
                                        </span>
                                        <span>{"USD"}</span>
                                      </div>
                                      <div className="w-full flex flex-row justify-end items-center resp-gap-2">
                                        <span>OUT</span>
                                        <span>
                                          {dec(r[k]?.outs_usd, tokdecn("USD"))}
                                        </span>
                                        <span>{"USD"}</span>
                                      </div>
                                      <div className="w-full flex flex-row justify-end items-center resp-gap-2 text-green-300">
                                        <span>Profit</span>
                                        <span>
                                          {dec(
                                            r[k]?.profit_usd,
                                            tokdecn("USD"),
                                          )}
                                        </span>
                                        <span>{"USD"}</span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="w-full flex flex-row justify-end items-center resp-gap-2">
                                        <span>IN</span>
                                        <span>
                                          {dec(r[k]?.ins, tokdecn(r[k]?.token))}
                                        </span>
                                        <span>{r[k]?.token}</span>
                                      </div>
                                      <div className="w-full flex flex-row justify-end items-center resp-gap-2">
                                        <span>OUT</span>
                                        <span>
                                          {dec(
                                            r[k]?.outs,
                                            tokdecn(r[k]?.token),
                                          )}
                                        </span>
                                        <span>{r[k]?.token}</span>
                                      </div>
                                      <div className="w-full flex flex-row justify-end items-center resp-gap-2 text-green-300">
                                        <span>Profit</span>
                                        <span>
                                          {dec(
                                            r[k]?.profit,
                                            tokdecn(r[k]?.token),
                                          )}
                                        </span>
                                        <span>{r[k]?.token}</span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            );
                          },
                        )}

                        <td>
                          <div className="fc-cc">
                            {/*                             {today && (
                                  <>
                                    <div className="flex flex-row justify-end items-center resp-gap-2 resp-text--1 text-orange-400">
                                      <span>Holds</span>
                                      <FontAwesomeIcon icon={faEthereum} />
                                      <span>{dec(r.tot.profit, 4)}</span>
                                      <span></span>
                                    </div>
                                    <div className="flex flex-row justify-end items-center resp-gap-2 resp-text--1 text-blue-400">
                                      <span>OPEN</span>
                                      <FontAwesomeIcon icon={faEthereum} />
                                      <span>{dec(openfunds, 4)}</span>
                                      <span></span>
                                    </div>
                                  </>
                                )}
                             */}
                            <div className="flex flex-row w-full justify-end items-end resp-gap-2 resp-text-1 text-green-400">
                              <span>{dec(getv(r, "tot.profit_usd"), 2)}</span>
                              <span className="resp-text--2">USD</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}

          <div className="h-[5rem]"></div>
        </div>
      </div>
    </DContext.Provider>
  );
};
