import { faPlus, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { parseInt } from "lodash";
import moment from "moment";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueries } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAppContext, useNowContext } from "../App";
import { Loader01c } from "../components/anims";
import {
  extract_inp,
  F_DateInput,
  F_DropdownInput,
  F_Input,
  F_OptionInput,
  F_PopupInput,
  set_state_ob,
} from "../components/input";
import { InpText, Tag } from "../components/utilityComps";
import {
  qiserr,
  q_factions_bingo_bingo_list,
  q_factions_bingo_bingo_doc,
  q_factions_bingo_bingo_update,
  q_factions_bingo_bingo_delete,
  q_factions_bingo_task_list,
  q_factions_bingo_task_doc,
  q_factions_bingo_task_update,
  q_factions_bingo_task_delete,
  qissuccesss,
  q_factions_bingo_presets_list,
  q_factions_bingo_presets_doc,
  q_factions_bingo_presets_update,
  q_factions_bingo_presets_delete,
} from "../queries/queries";
import {
  cdelay,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
} from "../utils/utils";

const BingoContext = createContext({});
const useBingoContext = () => useContext(BingoContext);

const Bingo_ControlSection = () => {
  const history = useNavigate();

  const bcon = useBingoContext();
  const { id, qobdoc, nf, set_nf } = bcon;

  const [showpresets, set_showpresets] = useState(false);

  const actions = {
    delete_bingo: async () => {
      try {
        let res = await q_factions_bingo_bingo_delete({ id }).queryFn();
        console.log("bingodoc:actions:delete_bingo", res);
        let done = getv(res, "result.done");
        if (done == 1) {
          history(`/factions_bingos/bingos/new`);
        }
      } catch (e) {
        console.log("bingodoc:actions:delete_bingo", e);
      }
    },
    reset_changes: () => {
      try {
        let doc = getv(qobdoc, "data.result") || {};
        set_nf(doc);
      } catch (e) {
        console.log("bingodoc:actions:reset_changes", e);
      }
    },
    save_bingo: async () => {
      try {
        if (nils(id)) return;
        let doc = _.cloneDeep(nf);

        delete doc.factions_map;
        delete doc.winmap;
        delete doc._id;

        let res = await q_factions_bingo_bingo_update({
          id,
          doc,
        }).queryFn();
        if (res?.status == "success") {
          await cdelay(1000);
          if (id == "new") {
            let nid = getv(res, "result.id");
            history(`/factions_bingos/bingo/${nid}`);
          } else {
            window.location.reload();
          }
        } else {
          console.log(res);
        }
      } catch (e) {
        console.log("bingodoc:actions:save_changes", e);
      }
    },
    use_preset: async (presetid) => {
      try {
        console.log("use_preset", presetid);
        let res = await q_factions_bingo_presets_doc({
          presetid,
        }).queryFn();
        let doc = getv(res, "result.doc");
        console.log("use_preset res", doc);
        set_nf(doc);
      } catch (e) {
        console.log("bingodoc:actions:use_preset", e);
      }
    },
    save_preset: async () => {
      try {
        let inpname = extract_inp("inp-presetname");
        inpname = inpname.trim();
        let doc = _.cloneDeep(nf);
        delete doc.id;
        delete doc.winmap;
        delete doc.factions_map;
        delete doc.starts_at;
        delete doc.ended;
        delete doc.created_at;
        delete doc.ended_at;
        delete doc._id;

        let data = {
          preset_name: inpname,
          doc,
        };
        console.log("save preset", inpname, data);
        const res = await q_factions_bingo_presets_update(data).queryFn();
        console.log("save preset res", res);
        set_showpresets(true);
        setTimeout(qobps.refetch, 2 * 1e3);
      } catch (e) {
        console.log("bingodoc:actions:save_preset", e);
      }
    },
    delete_preset: async (presetid) => {
      try {
        console.log("delete_preset", presetid);
        const res = await q_factions_bingo_presets_delete({
          presetid,
        }).queryFn();
        console.log("delete_preset res", res);
        setTimeout(qobps.refetch, 2 * 1e3);
      } catch (e) {
        console.log("bingodoc:actions:delete_preset", e);
      }
    },
  };

  const [qobps] = useQueries([
    q_factions_bingo_presets_list({}, { enabled: showpresets }),
  ]);
  const [qots] = useQueries([q_factions_bingo_task_list()]);

  const tasks = useMemo(
    () => getv(qots, "data.result") || [],
    [qots.dataUpdatedAt],
  );

  const presetslist = useMemo(
    () => getv(qobps, "data.result"),
    [qobps.dataUpdatedAt],
  );

  return (
    <div className="w-full my-2 resp-p-2 border border-slate-700 bg-reg">
      <div class="fr-sc resp-p-2 my-2 border-b border-slate-200">
        <Tag
          onClick={actions.delete_bingo}
          className={twMerge("bg-red-400/60")}
        >
          Delete Bingo
        </Tag>
        <div class="flex-1"></div>
        <Tag
          onClick={actions.reset_changes}
          className={twMerge("bg-yellow-400/60")}
        >
          Reset Changes
        </Tag>
        <Tag
          onClick={actions.save_bingo}
          className={twMerge("bg-green-400/60")}
        >
          Save Changes
        </Tag>
      </div>
      <div class="fr-sc">
        <Tag
          onClick={() => set_showpresets(true)}
          className={twMerge("text-acc0")}
        >
          Show Presets
        </Tag>
        <div class="flex-1"></div>
        <InpText id="inp-presetname" />
        <Tag onClick={actions.save_preset} className="bg-acc0/40">
          <span>Save</span>
        </Tag>
      </div>

      <div class="grid grid-cols-5 resp-gap-2">
        {qobps.isLoading ? (
          <Loader01c size="s" />
        ) : qiserr(qobps) ? (
          <p class="text-red-300">{qiserr(qobps)}</p>
        ) : (
          (presetslist || []).map((p) => {
            const clickfn = () => {
              actions.use_preset(p.presetid);
            };
            return (
              <div class="w-full border border-acc0 rounded-md">
                <div class="fr-sc resp-gap-1">
                  <p
                    onClick={clickfn}
                    className="cursor-pointer h-full resp-text--1 flex-1 resp-px-4 resp-py-2 font-digi"
                  >
                    {p.preset_name}
                  </p>
                  <div
                    onClick={() => {
                      actions.delete_preset(p.presetid);
                    }}
                    class="cursor-pointer resp-p-1 mr-2 rounded-md bg-red-500"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const Bingo_EditSection = () => {
  const bcon = useBingoContext();
  const { qobdoc, nf, set_nf, tasks } = bcon;

  const inpprops = { nf, set_nf, nfkey: "bingo" };

  return (
    <div className="w-full h-[60vh] my-2 resp-p-2 border border-slate-700 bg-reg overflow-auto">
      <div class="fr-sc resp-p-2 my-2">
        <table
          className={twMerge("thintdrowp4-table mx-auto w-max resp-text--2")}
        >
          <tbody>
            <tr>
              <td>ID</td>
              <td colSpan={5}>
                <span className="text-acc0">{getv(nf, "id")}</span>
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td colSpan={5}>
                <F_Input
                  {...{
                    ...inpprops,
                    path: "name",
                    contprops: { className: "w-full" },
                    inpprops: { className: "w-full" },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Starts</td>
              <td colSpan={5}>
                <F_DateInput {...inpprops} path={`starts_at`} />
              </td>
            </tr>
            <tr>
              <td>Ended</td>
              <td colSpan={5}>
                <F_OptionInput
                  {...{
                    ...inpprops,
                    type: "option",
                    path: "ended",
                    options: [true, false],
                    txt_fn: (v) => (v ? "Ended" : "no"),
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <span className="text-acc0">Title Rules</span>
                <span className="text-white">
                  [{getv(nf, "title_rules")?.length || 0} Rules]
                </span>
              </td>
              <td>
                <Tag
                  onClick={() => {
                    let new_trules = [
                      ...(getv(nf, "title_rules") || []),
                      ["", ""],
                    ];
                    set_state_ob(nf, set_nf, "title_rules", new_trules);
                  }}
                  className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Tag>
              </td>
            </tr>
            {(getv(nf, "title_rules") || []).map((e, i) => {
              return (
                <tr key={`title_rules-${i}`}>
                  <td colSpan={1}>
                    <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                      <Tag>#{i + 1}</Tag>
                      <div className="flex-1"></div>
                      <Tag
                        onClick={() => {
                          let new_trules = _.chain(getv(nf, "title_rules"))
                            .filter((e, idx) => idx != i)
                            .value();

                          set_state_ob(nf, set_nf, "title_rules", new_trules);
                        }}
                        className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Tag>
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div className="w-full">
                      <F_Input
                        {...inpprops}
                        path={`title_rules.${i}.0`}
                        contprops={{ className: "w-full" }}
                        inpprops={{ className: "w-full" }}
                      />
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div className="w-full">
                      <F_Input
                        {...inpprops}
                        path={`title_rules.${i}.1`}
                        contprops={{ className: "w-full" }}
                        inpprops={{ className: "w-full" }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={2}>
                <span class="resp-text-1">Card Layout</span>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <div class="w-full grid grid-cols-5 resp-gap-1">
                  {_.range(0, 5).map((i) => {
                    return (
                      <>
                        {_.range(0, 5).map((j) => {
                          return (
                            <Bingo_GridCard
                              {...{ key: jstr({ i, j }), i, j, tasks }}
                            />
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Bingo_GridCard = ({ i, j }) => {
  const bcon = useBingoContext();
  const { nf, set_nf, tasks } = bcon;

  const gpath = `card_layout.${i}.${j}`;
  useEffect(() => {
    set_state_ob(nf, set_nf, gpath, null);
  }, [i, j]);

  const inpprops = { nf, set_nf, nfkey: "bingo" };
  return (
    <div
      className={twMerge(
        "w-full resp-m-1 aspect-[1/1]  resp-p-1 rounded-md border border-blue-400",
      )}
    >
      {i == 2 && j == 2 ? (
        <div class="fc-cc w-full h-full">BLANK</div>
      ) : (
        <div class="fc-cc resp-gap-2">
          <span className="resp-text--1">{`${i}-${j}`}</span>

          <F_Input
            {...{
              ...inpprops,
              path: `card_layout.${i}.${j}.0`,
              contprops: { className: "w-[4rem]" },
              inpprops: { className: "w-full" },
              el_postprocess_fn: (v) => (nils(v) ? "" : parseInt(v)),
            }}
          />

          <F_PopupInput
            {...{
              ...inpprops,
              type: "option",
              path: `card_layout.${i}.${j}.1`,
              txt_fn: (v) => {
                let t = _.find(tasks, { id: v });
                return t?.name ?? "--";
              },
              options: _.map(tasks || [], "id"),
            }}
          />
        </div>
      )}
    </div>
  );
};

export const BingoPage = () => {
  const { id = "new" } = useParams();

  const [qobdoc] = useQueries([
    q_factions_bingo_bingo_doc({ id }, { enabled: id != "new" }),
  ]);
  const [nf, set_nf] = useState(id == "new" ? {} : undefined);
  const [resp, set_resp] = useState({});

  useEffect(() => {
    console.log("bingonf", nf);
  }, [jstr(nf)]);

  const f = useMemo(() => {
    if (qissuccesss(qobdoc)) {
      let f = getv(qobdoc, "data.result") || {};
      if (nf === undefined) set_nf(f);
      return f;
    }
    return {};
  }, [qobdoc.dataUpdatedAt]);

  const [qots] = useQueries([q_factions_bingo_task_list()]);
  const tasks = useMemo(
    () => getv(qots, "data.result") || [],
    [qots.dataUpdatedAt],
  );

  const bcon = {
    id,
    qobdoc,
    f,
    nf,
    set_nf,
    resp,
    set_resp,
    tasks,
  };
  return (
    <BingoContext.Provider value={bcon}>
      <div class="h-page">
        <div class=" w-[50rem] p-2 mx-auto overflow-auto resp-text--2">
          <div class="h-[2rem]"></div>
          <div class="fc-cc resp-gap-2 ">
            <p class="text-center font-digi">Bingo Create/Edit Page</p>
            <p class="text-center resp-text-1 font-digi">Bingo#{id}</p>
            <Bingo_ControlSection />
            <Bingo_EditSection />
          </div>
          <div class="h-[5rem]"></div>
        </div>
      </div>
    </BingoContext.Provider>
  );
};

const TasksList = ({ qots }) => {
  const tasks = useMemo(() => getv(qots, "data.result"), [qots.dataUpdatedAt]);

  return (
    <div class="h-[70vh] w-[50rem] p-2 mx-auto overflow-auto">
      {qots.isLoading ? (
        <Loader01c />
      ) : qiserr(qots) ? (
        <p class="text-center text-red-300">{qiserr(qots)}</p>
      ) : (
        <>
          {(tasks || []).map((t) => {
            return (
              <div class="rounded-md border border-acc0/60 my-2 resp-p-2 bg-reg">
                <div class="fc-ss">
                  <span>
                    {"id: "} <span className="text-acc0">{t.id}</span>
                  </span>
                  <span>name: {t?.name}</span>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
const BingosList = ({ qobs }) => {
  const { now } = useNowContext();
  const bingos = useMemo(() => getv(qobs, "data.result"), [qobs.dataUpdatedAt]);

  return (
    <div class="h-[70vh] w-[50rem] p-2 mx-auto overflow-auto">
      <div class="rounded-md border border-acc0/60 my-2 resp-p-2 bg-reg">
        <div class="fr-sc">
          <div
            onClick={() => {
              qobs.refetch();
            }}
            class={twMerge(
              "resp-2 cursor-pointer text-yellow-300",
              qobs.isLoading ? "animate-spin" : "",
            )}
          >
            <FontAwesomeIcon icon={faRefresh} />
          </div>
          <div class="flex-1"></div>
          <Link target={"_blank"} to="/factions_bingos/bingo/new">
            <Tag className="uppercase font-digi resp-text--1 bg-yellow-400/40 text-white">
              create new
            </Tag>
          </Link>
        </div>
      </div>
      {qobs.isLoading ? (
        <Loader01c />
      ) : qiserr(qobs) ? (
        <p class="text-center text-red-300">{qiserr(qobs)}</p>
      ) : (
        <>
          {(bingos || []).map((b) => {
            return (
              <Link target={"_blank"} to={`/factions_bingos/bingo/${b.id}`}>
                <div class="rounded-md border border-acc0/60 my-2 resp-p-2 bg-reg">
                  <div class="fr-sc">
                    <div class="fc-ss resp-gap-2 flex-1">
                      <span>
                        {"id: "} <span className="text-acc0">{b.id}</span>
                      </span>
                      <span>name: {b?.name}</span>
                    </div>
                    <div className="fc-ss resp-gap-2">
                      <span className="resp-text--2 font-mono">
                        {b.starts_at}
                      </span>
                      <span className="resp-text--2 text-acc0">
                        {iso_format(b.starts_at)}
                      </span>
                      {b.ended ? (
                        <span className="text-red-300">Ended</span>
                      ) : now < b.starts_at ? (
                        <span className="text-yellow-300">
                          Starts in {from_time_mini(b.starts_at)}
                        </span>
                      ) : (
                        <span className="text-green-400">Ongoing</span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </>
      )}
    </div>
  );
};

export const FactionBingos = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const [tab, set_tab] = useState(psearch.tab ?? "bingos");

  useEffect(() => {
    upd_psearch({ tab });
  }, [jstr({ tab })]);

  const [qobs, qots] = useQueries([
    q_factions_bingo_bingo_list(),
    q_factions_bingo_task_list(),
  ]);

  return (
    <div class="">
      <div class="h-page">
        <div class="border border-slate-700 w-[80rem] max-w-[98vw] mx-auto overflow-auto">
          <div class="h-[2rem]"></div>
          <div class="fr-cc resp-gap-2 resp-text--2">
            {["tasks", "bingos"].map((_t) => {
              let active = tab == _t;
              return (
                <Tag
                  onClick={() => set_tab(_t)}
                  className={twMerge(
                    "uppercase font-digi resp-text--1",
                    active ? "bg-acc0/40" : "text-white",
                  )}
                >
                  {_t}
                </Tag>
              );
            })}
          </div>

          {tab == "tasks" && <TasksList {...{ qots }} />}
          {tab == "bingos" && <BingosList {...{ qobs }} />}

          <div class="h-[5rem]"></div>
        </div>
      </div>
    </div>
  );
};
