import { ethers } from "ethers";
import { nils, tofeth } from "../utils/utils.js";
import _ from "lodash";
import { contractAddress_list } from "./constants.js";

export const asset_types_map = _.chain([
  ["core", 721, "Core", "0xCE8090dE88Bba13d3CEa5d73F8Baf1F1C1a61B16"],
  ["skin", 721, "Skin", "0xcd0783c0e2b0a68a64ba7c5e0f99097945397cf3"],
  ["god", 721, "GOD Token", "0x28AaBbd51A634d186F79ffbfA84FB70D119DbB05"],
  [
    "corelootboxv2",
    1155,
    "Core LootBox V2",
    "0x20dd967458922cDC21b16F69e043c7C918bcE8eF",
  ],
  [
    "skinlootboxv1",
    1155,
    "Skin LootBox V1",
    "0xbcDEB62e65666A13b4dF9fab76258b3164Da18bd",
  ],
  [
    "skinlootboxv101",
    1155,
    "Hallow Lootbox",
    "0x459698b869a671ecdF7c7714B101eb8B8891C2C5",
  ],
  [
    "skingamelootbox",
    1155,
    "Rarity Box",
    "0x7D16c167f9d9241A538ade09FD64AC25cA50132E",
  ],

  ["bgc", 20, "BGC", contractAddress_list.bikecredits],
  ["sgc", 20, "SGC", contractAddress_list.skincredits],
  ["dez", 20, "DEZ", contractAddress_list.dez],
  ["DEZ", 20, "DEZ", contractAddress_list.dez],
  ["weth", 20, "WETH", contractAddress_list.weth],
  ["WETH", 20, "WETH", contractAddress_list.weth],
])
  .map((e) => {
    let [asset_type, erc, name, address] = e;
    address = address.toLowerCase();
    // let infofn = infofns[asset_type];
    return { asset_type, name, address, erc };
  })
  .keyBy("asset_type")
  .value();

const erc_abis = {
  721: [
    "function transferFrom(address from, address to, uint256 tokenId) external",
    "function approve(address to, uint256 tokenId) public",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  1155: [
    "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  20: [
    "function balanceOf(address account) external view returns (uint256)",
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function approve(address spender, uint256 amount) external returns (bool)",
    "function transfer(address to, uint256 amount) external returns (bool)",
  ],
};
export const mm_asset_signer = async (asset_type, k, address) => {
  let erc = null;
  if (!nils(asset_type)) {
    let asty = asset_types_map[asset_type];
    console.log(asty);
    erc = asty.erc;
    address = asty.address;
  } else if (!nils(k)) {
    erc = k;
  }
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, erc_abis[erc], signer);
  return contract;
};

export const get_dez_balance = async (addr) => {
  let con = await mm_asset_signer(null, 20, contractAddress_list.dez);
  let bal_wei = await con.balanceOf(addr);
  let bal = tofeth(bal_wei);
  return { status: "success", result: { vault: addr, bal, bal_wei } };
};

export const get_weth_balance = async (addr) => {
  let con = await mm_asset_signer(null, 20, contractAddress_list.weth);
  let bal_wei = await con.balanceOf(addr);
  let bal = tofeth(bal_wei);
  return { status: "success", result: { vault: addr, bal, bal_wei } };
};
