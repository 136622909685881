import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims";
import { Card, Tag } from "../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_rally_delete,
  q_rally_edit,
  q_rally_info,
  q_rally_leader,
  q_rally_list,
} from "../queries/queries";
import {
  iso_format,
  getv,
  cdelay,
  nils,
  jstr,
  copy_clip,
} from "../utils/utils";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { class_cn, class_text, tablecn } from "../utils/cn_map";
import {
  F_DateInput,
  F_Input,
  F_InputTextAr,
  F_OptionInput,
} from "../components/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const RallyContext = createContext();
const useRallyContext = () => useContext(RallyContext);

const RallyInfoTab = () => {
  const rcon = useRallyContext();
  const { ralid, rdoc, set_rdoc, update_rdoc, reset_default } = rcon;
  const inpargs = { nf: rdoc, set_nf: set_rdoc, nfkey: "rallyinfo" };

  return (
    <div>
      <table
        className={twMerge(tablecn.table, "w-max mx-auto thintdrowp4-table")}
      >
        <tbody className="resp-text--2">
          <tr>
            <td>
              <span className="text-acc0">Rally ID</span>
            </td>
            <td>{ralid == "new" ? "new" : rdoc?.ralid}</td>
          </tr>

          <tr>
            <td>
              <span className="text-acc0">Rally Name</span>
            </td>
            <td>
              <F_Input {...{ ...inpargs, path: "name" }} />
            </td>
          </tr>

          <tr>
            <td>
              <span className="text-acc0">Status</span>
            </td>
            <td>
              <F_OptionInput
                {...{
                  ...inpargs,
                  path: "status",
                  type: "option",
                  options: ["yettostart", "qualifying", "running", "ended"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>
              <span className="text-acc0">Qualifying Dates</span>
            </td>
            <td>
              <div class="fr-sc">
                <F_DateInput
                  {...{
                    ...inpargs,
                    path: "qualifying_times.st",
                  }}
                />
                <F_DateInput
                  {...{
                    ...inpargs,
                    path: "qualifying_times.ed",
                  }}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <span className="text-acc0">Use Pos</span>
            </td>
            <td>
              <div class="fc-ss">
                <F_InputTextAr
                  {...{
                    ...inpargs,
                    path: "use_pos",
                  }}
                />
              </div>
              <span>{jstr(getv(rdoc, "use_pos"))}</span>
            </td>
          </tr>

          <tr>
            <td>
              <span className="resp-text-1 text-acc0 font-digi">
                Race Criteria
              </span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span className="text-acc0"> Race Name </span>
            </td>
            <td>
              <F_Input {...{ ...inpargs, path: "race_cri.race_name" }} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
const RallyLeader = () => {
  const rcon = useRallyContext();
  const { qoleader } = rcon;
  const [qualifiers, vmap, hmap] = useMemo(() => {
    let leader = getv(qoleader, "data.result") || {};
    let { qualifiers = [], vmap, hmap } = leader;
    return [qualifiers, vmap, hmap];
  }, [qoleader.dataUpdatedAt]);

  const copy_for_sheet = () => {
    let data = qualifiers.map((r) => {
      let h = hmap[r.hid];
      let v = vmap[r.racing_vault];
      return [
        r.rid,
        r.race_name,
        r.hid,
        h.name,
        h.fno,
        h.type,
        h.element,
        r.racing_vault,
        v,
      ];
    });
    data = [
      "rid,race_name,hid,hname,fno,type,element,racing_vault,vault_name".split(
        ",",
      ),
      ,
      ...data,
    ];
    data = data.map((r) => r.join("	")).join("\n");
    copy_clip(data);
  };

  return (
    <div className="max-w-[98vw] mx-auto w-full overflow-auto">
      <div class="fr-sc">
        <Tag onClick={copy_for_sheet} className="bg-acc0/40">
          <span class="resp-text--2">copy for sheet</span>
        </Tag>
      </div>
      {qoleader.isLoading ? (
        <Loader01c />
      ) : qiserr(qoleader) ? (
        <p class="text-red-300 text-center resp-text--1">{qiserr(qoleader)}</p>
      ) : qissuccesss(qoleader) && _.isEmpty(qualifiers) ? (
        <p class="text-yellow-300 text-center resp-text--1">
          {"No Qualifiers"}
        </p>
      ) : qissuccesss(qoleader) && !_.isEmpty(qualifiers) ? (
        <div class="">
          <table
            className={twMerge(
              tablecn.table,
              "w-max mx-auto thintdrowp4-table",
              "resp-text--2",
            )}
          >
            <thead>
              <tr class="text-acc0 font-digi text-center">
                <td>Race</td>
                <td>Bike</td>
                <td>#F-Ty-Elem</td>
                <td>Racing Vault</td>
              </tr>
            </thead>
            <tbody>
              {qualifiers.map((r) => {
                let h = hmap[r.hid];
                let v = vmap[r.racing_vault];
                return (
                  <tr>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`https://fbike.dnaracing.run/race/${r.rid}`}
                      >
                        <div class="fc-ss">
                          <div class="fr-ss">
                            <span class="text-acc0">{r.rid}</span>
                          </div>
                          <div class="fr-sc resp-gap-1">
                            <span
                              className={twMerge(
                                class_cn(r.class),
                                "rounded-md resp-p-1 resp-text--3",
                              )}
                            >
                              {class_text(r.class)}
                            </span>
                            <span>CB{r.cb}00</span>
                          </div>

                          <div class="fr-ss">{r.race_name}</div>
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`https://fbike.dnaracing.run/bike/${r.hid}`}
                      >
                        <div class="fc-ss resp-gap-2 text-acc0 font-digi">
                          <span>#{r.hid}</span>
                          <span className="text-white">{h.name}</span>
                        </div>
                      </Link>
                    </td>
                    <td>
                      <div class="grid grid-cols-3 text-center">
                        <span>F{h.fno}</span>
                        <span className="">{_.capitalize(h.type)}</span>
                        <div class="d">{h.element}</div>
                      </div>
                    </td>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`https://fbike.dnaracing.run/vault/${r.racing_vault}`}
                      >
                        <div class="fc-ss">
                          <span className="text-acc0">{v}</span>
                          <span className="resp-text--4">{r.racing_vault}</span>
                        </div>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export const RallyPage = () => {
  const history = useNavigate();
  const params = useParams();
  const ralid = useMemo(() => getv(params, "ralid"), [params]);
  const [qoinfo, qoleader] = useQueries([
    q_rally_info({ ralid }, { ralid: ralid !== "new" }),
    q_rally_leader({ ralid }, { enabled: ralid !== "new" }),
  ]);
  const info = useMemo(
    () => getv(qoinfo, "data.result"),
    [qoinfo.dataUpdatedAt],
  );
  const leader = useMemo(
    () => getv(qoleader, "data.result"),
    [qoleader.dataUpdatedAt],
  );

  const [rdoc, set_rdoc] = useState(undefined);
  const reset_default = () => {
    set_rdoc(info);
  };
  useEffect(() => {
    if (ralid == "new") {
      set_rdoc({
        ralid: "new",
      });
      return;
    }

    if (nils(info)) return;
    if (rdoc === undefined) reset_default();
  }, [jstr(info)]);

  const update_rally = async () => {
    try {
      let data = { ralid, doc: rdoc };
      console.log("upd rally", data);
      const resp = await q_rally_edit(data).queryFn();
      console.log("resp", resp);

      if (getv(resp, "result.done") == 1) {
        if (ralid == "new") {
          history(`/rally/${getv(resp, "result.ralid")}`);
        } else {
          await cdelay(2000);
          await qoinfo.refetch();
          setTimeout(reset_default, 3000);
        }
      }
    } catch (err) {}
  };

  const fetch_latest = async () => {
    await qoinfo.refetch();
    await qoleader.refetch();
    await cdelay(1000);
    reset_default();
  };

  const delete_rally = async () => {
    try {
      const resp = await q_rally_delete({ ralid }).queryFn();
      if (getv(resp, "result.done") == 1) {
        await cdelay(2000);
        history("/rally");
      }
    } catch (err) {}
  };

  const [tab, set_tab] = useState("info");

  const rcon = {
    ralid,

    qoinfo,
    info,

    qoleader,
    leader,

    rdoc,
    set_rdoc,
    update_rally,
    reset_default,
  };

  return (
    <RallyContext.Provider value={rcon}>
      <div class="h-page">
        <div class="w-[60rem] mx-auto max-w-[98vw]">
          <div class="h-[3rem]"></div>
          <div class="fr-sc">
            <Link to={`/rally`}>
              <Tag className="fr-sc resp-gap-2 text-acc0 border border-acc0">
                <FontAwesomeIcon icon={faArrowLeft} />
                <span class="resp-text--1">All Rally</span>
              </Tag>
            </Link>
          </div>
          <p class="text-center text-acc0 resp-text-1 font-digi">
            Rally:{ralid}
          </p>
          <Card className="w-full">
            <div class="w-full fr-sc">
              <Tag
                onClick={() => {
                  delete_rally();
                }}
                className="bg-red-400/40"
              >
                Delete Rally
              </Tag>
              <div class="flex-1"></div>
              <Tag
                onClick={() => {
                  reset_default();
                }}
                className="bg-orange-400/40"
              >
                Reset Default
              </Tag>
              <Tag
                onClick={() => {
                  fetch_latest();
                }}
                className="bg-orange-400/40"
              >
                Fetch Latest
              </Tag>

              <Tag
                onClick={() => {
                  update_rally();
                }}
                className="bg-acc0/40"
              >
                Update Rally
              </Tag>
            </div>
          </Card>

          <div class="fr-cc">
            {["info", "leader"].map((t) => {
              let active = tab === t;
              return (
                <Tag
                  onClick={() => set_tab(t)}
                  className={twMerge(active ? "bg-acc0/60" : "text-white ")}
                >
                  <span class="uppercase">{t}</span>
                </Tag>
              );
            })}
          </div>

          {tab == "info" && <RallyInfoTab />}
          {tab == "leader" && <RallyLeader />}

          <div class="h-[5rem]"></div>
        </div>
      </div>
    </RallyContext.Provider>
  );
};

export const ListRallyPage = () => {
  const [qolist] = useQueries([q_rally_list()]);
  const list = useMemo(
    () => getv(qolist, "data.result"),
    [qolist.dataUpdatedAt],
  );

  return (
    <div class="h-page">
      <div class="w-[60rem] mx-auto max-w-[98vw]">
        <div class="h-[3rem]"></div>
        <p class="text-center text-acc0 my-4 resp-text-1 font-digi">Rally</p>
        <hr class="w-[30rem] my-4 max-w-[90vw] mx-auto" />

        <div class="fr-sc my-2">
          <div class="flex-1"></div>
          <Link to="/rally/new" target="_blank">
            <Tag className="font-digi bg-acc0/40">
              <span class="resp-text--1">Create New</span>
            </Tag>
          </Link>
        </div>

        {qolist.isLoading ? (
          <Loader01c />
        ) : qiserr(qolist) ? (
          <p class="text-red-300 text-center resp-text--1">{qiserr(qolist)}</p>
        ) : qissuccesss(qolist) && _.isEmpty(list) ? (
          <p class="text-yellow-300 text-center resp-text--1">{"No Rally"}</p>
        ) : qissuccesss(qolist) && !_.isEmpty(list) ? (
          <div class="">
            {list.map((r) => {
              return (
                <Link target={"_blank"} to={`/rally/${r.ralid}`}>
                  <Card className="w-full">
                    <div class="fr-sc resp-gap-2 resp-text--2">
                      <p class="text-acc0 font-mono ">{r.ralid}</p>
                      <p class="text-white font-digi resp-test--1 ">{r.name}</p>
                      <div class="flex-1"></div>
                      <p class="text-green-300 uppercase">{r.status}</p>
                    </div>
                  </Card>
                </Link>
              );
            })}
          </div>
        ) : null}
        <div class="h-[5rem]"></div>
      </div>
    </div>
  );
};
