import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { nano } from "../utils/utils.js";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNowContext } from "../App.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MoVariants } from "../utils/motion_helper.js";
import _ from "lodash";
import { qiserr, qissuccesss } from "../queries/queries.js";
import { Loader01c } from "./anims.js";

export const Tag = (props) => {
  let pointer = props.onClick || props.redirect ? "cursor-pointer" : "";
  return (
    <a
      {...(props.redirect
        ? {
            href: props.redirect,
            target: props.target ?? "_blank",
          }
        : {})}
      {...{
        className: props.acn ?? "",
      }}
    >
      <div
        {...props}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
        style={{ ...props.style }}
        className={twMerge(
          "px-2 py-1 font-bold",
          "mx-1 my-0.5 font-bold",
          `nowrap rounded-md`,
          pointer,
          props?.active === false ? "bg-opacity-25" : "",
          props.className,
        )}
      >
        {props.children}
      </div>
    </a>
  );
};

export const Img = ({ className, img }) => {
  return (
    <div className={twMerge("img-obey-cont h-auto", className)}>
      <img src={img} />
    </div>
  );
};

export const Card = ({ className, ...props }) => {
  return (
    <div
      className={twMerge("rounded-md p-4 mb-2 bg-reg w-max mx-auto", className)}
    >
      {props.children}
    </div>
  );
};

export const HeadC2L = (props) => {
  const Line = (
    <>
      <div
        className={twMerge(
          "h-1 min-w-[5rem] rounded-md px-1 bg-white max-w-[10rem]",
          props.linecn,
        )}
      ></div>
    </>
  );
  return (
    <div className="fr-cc w-full gap-2 mb-2">
      <div className="flex-1"></div>
      {Line}
      <div className="w-max">
        <span
          className={twMerge(
            "text-acc0 text-[2rem] font-digi",
            props.className,
          )}
        >
          {props.children}
        </span>
      </div>
      {Line}
      <div className="flex-1"></div>
    </div>
  );
};

export const BImg = ({ hex_code, className }) => {
  return (
    <>
      <div
        style={{ background: `#${hex_code}` }}
        className={twMerge("w-[4rem] h-[4rem] m-1 rounded-md", className)}
      ></div>
    </>
  );
};

const inpkeydel = 300;

export const InpText = (props) => {
  const {
    contprops = {},
    inpprops = {},
    label,
    setter,
    def_val = "",
    type,
    autoComplete,
  } = props;
  const inpref = useRef();
  const lastkey = useRef(null);
  const nc = useNowContext();
  const diff = nc.now - lastkey.current;
  useEffect(() => {
    if (diff > inpkeydel) {
      let value = inpref.current.value;
      if (setter) setter(value);
    }
  }, [diff > inpkeydel]);
  useEffect(() => {
    inpref.current.value = def_val;
  }, [def_val]);
  const [showpass, set_showpass] = useState(false);
  return (
    <motion.div
      {...contprops}
      onClick={() => inpref.current.focus()}
      className={twMerge("inp-container ", contprops.className)}
    >
      {label && <div className="inp-label">{label}</div>}
      {
        <div className="flex flex-row justify-start">
          <input
            id={props.id}
            placeholder={props.placeholder ?? ""}
            onKeyDown={(e) => {
              lastkey.current = nano();
              if (props.onKeyDown) props.onKeyDown(e);
            }}
            onChange={(e) => {
              lastkey.current = nano();
              if (props.onKeyDown) props.onChange(e);
            }}
            onBlur={(e) => {
              lastkey.current = nano();
              if (props.onKeyDown) props.onBlur(e);
            }}
            ref={inpref}
            type={showpass ? "text" : type || "text"}
            defaultValue={def_val}
            className=""
            autoComplete={autoComplete}
            {...inpprops}
          />
          {type == "password" ? (
            <div
              className="p-1 w-[1.5rem] cursor-pointer"
              onClick={() => {
                if (type == "password") set_showpass(!showpass);
              }}
            >
              <FontAwesomeIcon icon={showpass ? faEyeSlash : faEye} />
            </div>
          ) : (
            <div className="w-[1.5rem]"></div>
          )}
        </div>
      }
    </motion.div>
  );
};

export const Hori_Divider = () => {
  return <div className="my-1 h-1 bg-acc0/30 rounded-md"></div>;
};

export const Dropdown = ({ wrapcn, trigger, triggercn, dropcn, ...props }) => {
  const [hover, set_hover] = useState(false);

  return (
    <div className="relative" onMouseLeave={() => set_hover(false)}>
      <div className={wrapcn}>
        <div
          onMouseEnter={() => set_hover(true)}
          className={twMerge(
            "fr-cc mx-4 gap-1 relative cursor-pointer z-10",
            triggercn,
          )}
        >
          {trigger}
        </div>
        <motion.div
          variants={MoVariants.show_hide}
          animate={hover ? "visible" : "hidden"}
          className={twMerge("absolute w-full z-20", dropcn)}
        >
          {props.children}
        </motion.div>
      </div>
    </div>
  );
};

export const QLazyDisp = ({ qo, data, children }) => {
  return qo.isLoading ? (
    <Loader01c size="s" />
  ) : qiserr(qo) ? (
    <p className="text-red-400 text-center font-digi">{qiserr(qo)}</p>
  ) : qissuccesss(qo) && _.isEmpty(data) ? (
    <p className="text-yellow-400 text-center font-digi">Nothing Found</p>
  ) : qissuccesss(qo) && !_.isEmpty(data) ? (
    <div className="">{children}</div>
  ) : (
    <></>
  );
};

const tokenimgmap = {
  WETH: "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
  DEZ: "https://i.imgur.com/sbvrqAU.png",
  USD: "https://i.imgur.com/y6kotUk.png",
  MATIC: "https://polygonscan.com/assets/generic/html/favicon-light.ico",
};

export const TokenIcon = ({ token, className, size = "xs" }) => {
  return (
    <div
      className={twMerge(
        "rounded-full overflow-hidden resp-px-1",
        size == "xs"
          ? "xs:w-[1rem] xs:h-[1rem] lg:w-[2rem] lg:h-[2rem]"
          : size == "md"
            ? "xs:w-[1.5rem] xs:h-[1.5rem] lg:w-[3rem] lg:h-[3rem]"
            : size == "lg"
              ? "xs:w-[2rem] xs:h-[2rem] lg:w-[4rem] h-[4rem]"
              : "",
        className,
      )}
    >
      <Img img={tokenimgmap[token]} />
    </div>
  );
};
