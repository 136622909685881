import React, { useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import qs from "query-string";
import { createContext } from "react";
import { init_web3 } from "./utils/web3.js";
import { MetaMaskWrapper } from "./wrappers/MetaMaskWrapper.js";
import AuthWrapper from "./wrappers/AuthWrapper.js";
import HomePage from "./views/HomePage.js";
import Layout from "./components/Layout.js";
import NoColor from "./wrappers/NoColor.js";
import AccountWrapper from "./wrappers/AccountWrapper.js";
import Races from "./views/Races.js";
import { Ledger } from "./views/Ledger.js";
import CustomRace from "./views/CustomRace.js";
import FunnyMoney, { FundsPage } from "./views/FunnyMoney.js";
import RaceCounts from "./views/RaceCounts.js";
import Jackpot from "./views/JackPot.js";
import { DailyEarnings } from "./views/DailyEarnings.js";
import { HelmetProvider } from "react-helmet-async";
import { GameFlow } from "./views/GameFlow.js";
import { Actions } from "./views/Actions.js";
import { FinMov } from "./views/FinMov.js";
import { FqualListPage, FqualPage } from "./views/Fqual.js";
import { VaultStatsPage } from "./views/VaultStats.js";
import { Inbox } from "./views/Inbox.js";
import { getv } from "./utils/utils.js";
import { q_token_prices } from "./queries/queries.js";
import { useQueries } from "react-query";
import { ListRallyPage, RallyPage } from "./views/Rally.js";
import {
  FactionsMissionsList,
  FactiosnMissionPage,
} from "./views/FactionsMissionsList.js";
import { BingoPage, FactionBingos } from "./views/FactionBingos.js";
import { BulkRacePayouts } from "./views/BulkRacePayouts.js";
import { HStatsPage } from "./views/HStats.js";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);

export const tokdecn = (token) =>
  token == "WETH"
    ? 6
    : token == "ETH"
      ? 6
      : token == "DEZ"
        ? 0
        : (token = "USD" ? 2 : 0);
export const tokdecn2 = (token) =>
  token == "WETH"
    ? 3
    : token == "ETH"
      ? 3
      : token == "DEZ"
        ? 0
        : (token = "USD" ? 2 : 0);

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);

  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tokmap = useMemo(
    () => getv(qo_tokpri, "data.result") || { ethusd: 0, dezusd: 0 },
    [qo_tokpri.dataUpdatedAt],
  );

  const tokkey = (c) => (c == "WETH" ? "ethusd" : c == "DEZ" ? "dezusd" : null);
  const tok_to_usd_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(tokp) * parseFloat(cost);
  };
  const usd_to_tok_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(cost) / parseFloat(tokp);
  };

  const appcon = {
    history,
    location,
    path: location.pathname,
    psearch,
    upd_psearch,

    tokmap,
    tok_to_usd_val,
    usd_to_tok_val,
  };

  return (
    <div className="App bg-dark text-white">
      <NowContext.Provider value={{ now }}>
        <AppContext.Provider value={appcon}>
          <MetaMaskWrapper>
            <AuthWrapper>
              <AccountWrapper>
                <Layout>
                  <HelmetProvider>
                    <Routes>
                      {/* <Route path="/races" exact element={<Races />} /> */}
                      {/* <Route path="/customrace" exact element={<CustomRace />} /> */}
                      {/* <Route path="/ledger" exact element={<Ledger />} /> */}
                      {/* <Route path="/funnymoney" exact element={<FunnyMoney />} /> */}
                      <Route
                        path="/racecounts"
                        exact
                        element={<RaceCounts />}
                      />
                      <Route path="/jackpot" exact element={<Jackpot />} />
                      <Route
                        path="/daily-earnings"
                        exact
                        element={<DailyEarnings />}
                      />
                      <Route path="/game-flow" exact element={<GameFlow />} />
                      <Route path="/actions" exact element={<Actions />} />
                      <Route path="/finmov" exact element={<FinMov />} />

                      <Route path="/fqual" exact element={<FqualListPage />} />
                      <Route path="/fqual/:fid" exact element={<FqualPage />} />

                      <Route
                        path="/factions_missions_list"
                        exact
                        element={<FactionsMissionsList />}
                      />
                      <Route
                        path="/factions_missions_list/:missid"
                        exact
                        element={<FactiosnMissionPage />}
                      />

                      <Route
                        path="/factions_bingos/bingo/:id"
                        exact
                        element={<BingoPage />}
                      />
                      <Route
                        path="/factions_bingos"
                        exact
                        element={<FactionBingos />}
                      />

                      <Route
                        path="/vault_stats"
                        exact
                        element={<VaultStatsPage />}
                      />
                      <Route path="/inbox" exact element={<Inbox />} />
                      <Route
                        path="/rally/:ralid"
                        exact
                        element={<RallyPage />}
                      />
                      <Route path="/rally" exact element={<ListRallyPage />} />
                      <Route path="/funds" exact element={<FundsPage />} />
                      <Route path="/hstats" exact element={<HStatsPage />} />
                      <Route
                        path="/bulk-race-payouts"
                        exact
                        element={<BulkRacePayouts />}
                      />

                      <Route path="/" exact element={<HomePage />} />
                    </Routes>
                  </HelmetProvider>
                </Layout>
              </AccountWrapper>
            </AuthWrapper>
          </MetaMaskWrapper>
          <NoColor />
        </AppContext.Provider>
      </NowContext.Provider>
    </div>
  );
}

export default App;
