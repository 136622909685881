import { ethers } from "ethers";
import { iso, nano, nils, toeth, tofeth } from "../../utils/utils.js";
import abi from "./abi.json";
import { contractAddress_list, getgasinfo } from "../constants";

class FairexMCBetsContract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async withdrawFunds(to, amount) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.withdrawFunds(to, amount, gasinfo);
    const resp = await tx.wait();
    return resp;
  }
}

const get_FairexMCBetsContract = async (ext = {}) => {
  const contractAddress = contractAddress_list.fairexmcbets;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new FairexMCBetsContract({
    contractAddress,
    provider,
    signer,
    ...ext,
  });
  return runner;
};

const FairexMCBets = {
  class: FairexMCBetsContract,
  get_contract: get_FairexMCBetsContract,
};

export default FairexMCBets;
