export const contractAddress_list = {
  weth: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  dez: "0xdc4f4ed9872571d5ec8986a502a0d88f3a175f1e",
  bikecredits: "0x8226fe9615d39fa09f389860ecd579b5a37e9c35",
  skincredits: "0xF81f84b068e19a72D84313Fd5F5E8d02eece8B05",
  lc: "0x02d115fe8404b9ffe5ca8f19db392bbeea1cb282",

  corelootboxv2: "0x20dd967458922cDC21b16F69e043c7C918bcE8eF",
  skinlootboxv1: "0xbcDEB62e65666A13b4dF9fab76258b3164Da18bd",
  fbikeskin: "0xCD0783C0e2b0A68A64ba7c5e0F99097945397cF3",
  splicing: "0x792a3A3539A314f4FaC63F6fcc546c3ef9fFC8CE",
  racestake: "0x60dbd31fAefa2Bf87e85e7582e389DC134eDa2b1",
  racestakev02: "0x1DfE57D9aC38E3168ab920f050934667d32Ba433",
  racestakev03dez: "0x3e4FC7D4161c6A5B8667D55bf0F71aad03095D3F",
  fairexmcbets: "0xa55e807f8466634562e9777965bb12561e117c9e",
  prizebox: "0x59768e3a04b0598b128ba5039f24b7bd61cbb24a",
};

export const getgasinfo = () => ({
  gasLimit: 220000,
});
