import _, { has } from "lodash";
import { fpost } from "./fetch.js";
import { getv, jstr, nils } from "../utils/utils.js";
import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { get_auth_header } from "../wrappers/AuthWrapper.js";
import { list } from "postcss";

const usedevloc = true;
export const btbk =
  usedevloc && process.env.NODE_ENV == "development"
    ? `http://localhost:3005`
    : `https://api.dnaracing.run`;

export const qiserr = (q) => {
  if (getv(q, "data.err")) return getv(q, "data.err");
  if (q.status == "error") return "server error";
  if (q.status == "loading") return false;
  return false;
};

export const iserr = (d) => {
  if (getv(d, "err") || d.status == "error") return getv(d, "data.err");
  return false;
};

export const qissuccess = (q) => {
  return getv(q, "status") == "success" && !qiserr(q);
};

export const qissuccesss = (q) => {
  return (
    getv(q, "status") == "success" &&
    getv(q, "data.status") == "success" &&
    !qiserr(q)
  );
};

export const useStepQuery = ({
  key,
  par_ar,
  q_,
  params,
  lim = 1,
  options = {},
  ...props
}) => {
  // useEffect(() => console.log("useStepQuery", par_ar), [par_ar]);

  const [enabled_n, set_enabled_n] = useState(lim);
  const qs = useQueries(
    par_ar?.map((e, idx) => {
      let ext_options = { ...options, enabled: idx < enabled_n };
      // return () => {return { test: 0 }};
      return q_(...e, ext_options);
    }),
  );
  const c = useMemo(() => {
    let ob = _.countBy(qs, (e) => e.status);
    return {
      success: ob.success ?? 0,
      loading: ob.loading ?? 0,
      error: ob.error ?? 0,
      idle: ob.idle ?? 0,
    };
  }, [qs]);

  useEffect(() => {
    set_enabled_n((parseInt(c.success / lim) + 1) * lim);
  }, [c.success]);

  const datas = useMemo(() => {
    // if (qs_count.loading !== 0) return [];
    let ob = _.chain(qs).filter({ status: "success" }).map("data").value();
    // console.log("all_horses_data", ob);
    return ob;
  }, [jstr(_.map(qs, "dataUpdatedAt")), c]);

  return {
    qs,
    datas,
    c,
    n: par_ar.length,
    done: c.success,
    tot: par_ar.length,
    loading: c.idle + c.loading > 0,
  };
};

export const polytxnbase = `https://polygonscan.com/tx`;
export const polytxnlink = (hash) => `${polytxnbase}/${hash}`;
export const polytxnlink_sp = (hash) => {
  if (nils(hash)) return "--";
  hash = hash.split(":")[0];
  return `${polytxnbase}/${hash}`;
};
export const polytxnidlink = (id) =>
  `${polytxnbase}/${id ? id.split(":")[0] : null}`;

export const q_auth_get_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/get_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_nonce`;
  return fpost(api, doc, {});
};

export const q_token_prices = (doc, ext = {}) => {
  let next = {
    staleTime: 5 * 60 * 1e3,
    refetchInterval: 5 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct(
    "q_token_prices",
    `${btbk}/fbike/token_prices`,
    doc,
    next,
  );
  return o;
};

export const q_auth_verify_token = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_admin`;
  return fpost(api, doc, {});
};

export const q_construct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      return fpost(api, doc);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_auconstruct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      let headers = get_auth_header();
      return fpost(api, doc, headers);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_races_presets = (doc, ext = {}) => {
  const next = {
    staleTime: 1e14,
    ...ext,
  };
  return q_auconstruct(
    "races_preset",
    `${btbk}/fbike/admin/races/presets`,
    doc,
    next,
  );
};

export const q_races_preset = (doc, ext = {}) => {
  const next = {
    staleTime: 1e14,
    ...ext,
  };
  return q_auconstruct(
    "races_preset",
    `${btbk}/fbike/admin/races/preset`,
    doc,
    next,
  );
};

export const q_races_update_preset = (doc, ext = {}) => {
  const next = {
    staleTime: 1e14,
    ...ext,
  };
  return q_auconstruct(
    "races_preset",
    `${btbk}/fbike/admin/races/update_preset`,
    doc,
    next,
  );
};

export const q_races_remove_preset = (doc, ext = {}) => {
  const next = {
    staleTime: 1e14,
    ...ext,
  };
  return q_auconstruct(
    "races_preset",
    `${btbk}/fbike/admin/races/remove_preset`,
    doc,
    next,
  );
};

export const q_ledger_rfe_summary = (doc, ext = {}) => {
  let next = { staleTime: 60 * 1e3, refetchInterval: 60 * 1e3, ...ext };
  return q_auconstruct(
    "q_rfe_summary",
    `${btbk}/fbike/admin/ledger/rfe_summary`,
    doc,
    next,
  );
};
export const q_ledger_rfe_races = (doc, ext = {}) => {
  let next = { staleTime: 60 * 1e3, refetchInterval: 60 * 1e3, ...ext };
  return q_auconstruct(
    "q_rfe_races",
    `${btbk}/fbike/admin/ledger/rfe_races`,
    doc,
    next,
  );
};
export const q_ledger_rfe_racetxns = (doc, ext = {}) => {
  let next = { staleTime: 1e14, ...ext };
  return q_auconstruct(
    "q_rfe_racetxns",
    `${btbk}/fbike/admin/ledger/rfe_racetxns`,
    doc,
    next,
  );
};

export const q_hstats = (doc, ext = {}) => {
  let o = q_construct("q_hstats", `${btbk}/fbike/races/hstats`, doc, ext);
  return o;
};

export const q_bikesearch = (doc, ext = {}) => {
  let o = q_construct("q_bikesearch", `${btbk}/fbike/i/search`, doc, ext);
  return o;
};

export const q_admin_add_customrrace = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_bikesearch",
    `${btbk}/fbike/admin/races/add_customrrace`,
    doc,
    ext,
  );
  return o;
};

export const q_admin_funnymoney = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_admin_funnymoney",
    `${btbk}/fbike/admin/races/funnymoney`,
    doc,
    ext,
  );
  return o;
};

export const q_admin_rcounts = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_admin_rcounts",
    `${btbk}/fbike/admin/races/rcounts`,
    doc,
    ext,
  );
  return o;
};

export const q_admin_races = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_admin_races",
    `${btbk}/fbike/admin/races/races`,
    doc,
    ext,
  );
  return o;
};

export const q_admin_jackpot_pot = (doc, ext = {}) => {
  ext = {
    ...ext,
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
  };
  let o = q_auconstruct(
    "q_admin_races",
    `${btbk}/fbike/admin/jackpot/pot`,
    doc,
    ext,
  );
  return o;
};

export const q_admin_jackpot_winhash = (doc, ext = {}) => {
  ext = {
    ...ext,
    staleTime: 1e14,
  };
  let o = q_auconstruct(
    "q_admin_races",
    `${btbk}/fbike/admin/jackpot/winhash`,
    doc,
    ext,
  );
  return o;
};

export const q_vaultinfo = (doc, ext = {}) => {
  let o = q_construct("q_vaultinfo", `${btbk}/fbike/vault/info`, doc, ext);
  return o;
};

export const q_admin_calc_rfetable = (doc, ext = {}) => {
  ext = {
    ...ext,
    staleTime: 1e14,
  };
  return q_auconstruct(
    "q_admin_calc_rfetable",
    `${btbk}/fbike/admin/calc/rfetable`,
    doc,
    ext,
  );
};

export const q_admin_calc_gameflow = (doc = {}, ext = {}) => {
  ext = {
    ...ext,
    staleTime: 5 * 60 * 1e3,
  };
  return q_auconstruct(
    "q_admin_calc_gameflow",
    `${btbk}/fbike/admin/calc/gameflow`,
    doc,
    ext,
  );
};

export const q_admin_actions_cancel_races = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_actions_cancel_races",
    `${btbk}/fbike/admin/actions/cancel_races`,
    doc,
    ext,
  );
};
export const q_admin_actions_cancel_satellite = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_actions_cancel_satellite",
    `${btbk}/fbike/admin/actions/cancel_satellite`,
    doc,
    ext,
  );
};
export const q_admin_actions_mint_core_lootbox = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_actions_mint_core_lootbox",
    `${btbk}/fbike/admin/actions/mint_core_lootbox`,
    doc,
    ext,
  );
};
export const q_admin_actions_mint_skin_lootbox = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_actions_mint_skin_lootbox",
    `${btbk}/fbike/admin/actions/mint_skin_lootbox`,
    doc,
    ext,
  );
};

export const q_admin_finmov_base = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_finmov_base",
    `${btbk}/fbike/admin/finmov/base`,
    doc,
    ext,
  );
};
export const q_admin_finmov_bbms = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_finmov_bbms",
    `${btbk}/fbike/admin/finmov/bbms`,
    doc,
    ext,
  );
};
export const q_admin_finmov_setb = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_finmov_setb",
    `${btbk}/fbike/admin/finmov/setb`,
    doc,
    ext,
  );
};

// /fqual/updatesheet
export const q_fqual_updatesheet = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_fqual_updatesheet",
    `${btbk}/fbike/fqual/updatesheet`,
    doc,
    ext,
  );
};

export const q_admin_fqual_list = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_fqual_list",
    `${btbk}/fbike/admin/fqual/list`,
    doc,
    ext,
  );
};

export const q_admin_fqual_doc = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_doc",
    `${btbk}/fbike/admin/fqual/doc`,
    doc,
    ext,
  );
};

export const q_admin_fqual_leader = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_leader",
    `${btbk}/fbike/admin/fqual/leader`,
    doc,
    ext,
  );
};

export const q_admin_fqual_update = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_update",
    `${btbk}/fbike/admin/fqual/update`,
    doc,
    ext,
  );
};

export const q_admin_fqual_cancel = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_cancel",
    `${btbk}/fbike/admin/fqual/cancel`,
    doc,
    ext,
  );
};

export const q_admin_fqual_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_delete",
    `${btbk}/fbike/admin/fqual/delete`,
    doc,
    ext,
  );
};

export const q_admin_fqual_presets_list = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_presets_list",
    `${btbk}/fbike/admin/fqual/presets/list`,
    doc,
    ext,
  );
};

export const q_admin_fqual_presets_save = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_presets_save",
    `${btbk}/fbike/admin/fqual/presets/save`,
    doc,
    ext,
  );
};

export const q_admin_fqual_presets_get = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_presets_get",
    `${btbk}/fbike/admin/fqual/presets/get`,
    doc,
    ext,
  );
};

export const q_admin_fqual_presets_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_presets_delete",
    `${btbk}/fbike/admin/fqual/presets/delete`,
    doc,
    ext,
  );
};

export const q_admin_fqual_entrants = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_entrants",
    `${btbk}/fbike/admin/fqual/entrants`,
    doc,
    ext,
  );
};

export const q_admin_mint_prizebox = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_mint_prizebox",
    `${btbk}/fbike/admin/actions/mint_prizebox`,
    doc,
    ext,
  );
};

export const q_vault_stats_splice_chart = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_stats_splice_chart",
    `${btbk}/fbike/admin/vault_stats/splice_chart`,
    doc,
    ext,
  );
};

export const q_vault_stats_raw = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_stats_raw",
    `${btbk}/fbike/admin/vault_stats/raw`,
    doc,
    ext,
  );
};

export const q_inbox_create_admin_acc = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_inbox_create_admin_acc",
    `${btbk}/fbike/inbox/create_admin_acc`,
    doc,
    ext,
  );
};

export const q_admin_fqual_add_entrant = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_add_entrant",
    `${btbk}/fbike/admin/fqual/add_entrant`,
    doc,
    ext,
  );
};
export const q_admin_fqual_delete_entrant = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_delete_entrant",
    `${btbk}/fbike/admin/fqual/delete_entrant`,
    doc,
    ext,
  );
};

export const q_rally_list = (doc = {}, ext = {}) => {
  return q_construct("q_rally_list", `${btbk}/fbike/rally/list`, doc, ext);
};

export const q_rally_info = (doc = {}, ext = {}) => {
  return q_construct("q_rally_info", `${btbk}/fbike/rally/info`, doc, ext);
};

export const q_rally_leader = (doc = {}, ext = {}) => {
  return q_construct("q_rally_leader", `${btbk}/fbike/rally/leader`, doc, ext);
};

export const q_rally_edit = (doc = {}, ext = {}) => {
  return q_auconstruct("q_rally_edit", `${btbk}/fbike/rally/edit`, doc, ext);
};

export const q_rally_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_rally_delete",
    `${btbk}/fbike/rally/delete`,
    doc,
    ext,
  );
};

export const q_actions_maiden_date_get = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_actions_maiden_date_get",
    `${btbk}/fbike/admin/actions/maiden_date/get`,
    doc,
    ext,
  );
};
export const q_actions_maiden_date_set = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_actions_maiden_date_set",
    `${btbk}/fbike/admin/actions/maiden_date/set`,
    doc,
    ext,
  );
};

export const q_admin_fqual_delete_finale = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_fqual_delete_finale",
    `${btbk}/fbike/admin/fqual/delete_finale`,
    doc,
    ext,
  );
};

// /factions/missions_list/list
export const q_factions_missions_list = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_missions_list",
    `${btbk}/fbike/factions/missions_list/list`,
    doc,
    ext,
  );
};

// /factions/missions_list/doc/
export const q_factions_missions_doc = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_missions_doc",
    `${btbk}/fbike/factions/missions_list/doc`,
    doc,
    ext,
  );
};

// /factions/missions_list/update
export const q_factions_missions_update = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_update",
    `${btbk}/fbike/factions/missions_list/update`,
    doc,
    ext,
  );
};

// /factions/missions_list/delete
export const q_factions_missions_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_delete",
    `${btbk}/fbike/factions/missions_list/delete`,
    doc,
    ext,
  );
};

// /factions/missions_list/presets/list
export const q_factions_missions_presets_list = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_presets_list",
    `${btbk}/fbike/factions/missions_list/presets/list`,
    doc,
    ext,
  );
};

// /factions/missions_list/presets/save
export const q_factions_missions_presets_save = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_presets_save",
    `${btbk}/fbike/factions/missions_list/presets/save`,
    doc,
    ext,
  );
};

// /factions/missions_list/presets/get
export const q_factions_missions_presets_get = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_presets_get",
    `${btbk}/fbike/factions/missions_list/presets/get`,
    doc,
    ext,
  );
};

// /factions/missions_list/presets/delete
export const q_factions_missions_presets_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_presets_delete",
    `${btbk}/fbike/factions/missions_list/presets/delete`,
    doc,
    ext,
  );
};

// router.post("/bingo_list", async (req, res) =>
//   fnr_wrapx(req, res, r_bingo_list),
// );
// router.post("/bingo_doc", async (req, res) => fnr_wrapx(req, res, r_bingo_doc));
// router.post("/bingo_update", async (req, res) =>
//   fnr_wrapx(req, res, r_bingo_update),
// );
// router.post("/bingo_delete", async (req, res) =>
//   fnr_wrapx(req, res, r_bingo_delete),
// );

export const q_factions_bingo_bingo_list = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_bingo_list",
    `${btbk}/fbike/admin/factions_bingo/bingo_list`,
    doc,
    ext,
  );
};
export const q_factions_bingo_bingo_doc = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_bingo_doc",
    `${btbk}/fbike/admin/factions_bingo/bingo_doc`,
    doc,
    ext,
  );
};
export const q_factions_bingo_bingo_update = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_bingo_update",
    `${btbk}/fbike/admin/factions_bingo/bingo_update`,
    doc,
    ext,
  );
};
export const q_factions_bingo_bingo_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_bingo_delete",
    `${btbk}/fbike/admin/factions_bingo/bingo_delete`,
    doc,
    ext,
  );
};

// router.post("/task_list", async (req, res) => fnr_wrapx(req, res, r_task_list));
// router.post("/task_doc", async (req, res) => fnr_wrapx(req, res, r_task_doc));
// router.post("/task_update", async (req, res) =>
//   fnr_wrapx(req, res, r_task_update),
// );
// router.post("/task_delete", async (req, res) =>
//   fnr_wrapx(req, res, r_task_delete),
// );

export const q_factions_bingo_task_list = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_task_list",
    `${btbk}/fbike/admin/factions_bingo/task_list`,
    doc,
    ext,
  );
};
export const q_factions_bingo_task_doc = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_task_doc",
    `${btbk}/fbike/admin/factions_bingo/task_doc`,
    doc,
    ext,
  );
};
export const q_factions_bingo_task_update = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_task_update",
    `${btbk}/fbike/admin/factions_bingo/task_update`,
    doc,
    ext,
  );
};
export const q_factions_bingo_task_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_task_delete",
    `${btbk}/fbike/admin/factions_bingo/task_delete`,
    doc,
    ext,
  );
};

// router.post("/bingo_presets_list", async (req, res) =>
//   fnr_wrapx(req, res, r_bingo_presets_list),
// );
// router.post("/bingo_presets_doc", async (req, res) =>
//   fnr_wrapx(req, res, r_bingo_presets_doc),
// );
// router.post("/bingo_presets_update", async (req, res) =>
//   fnr_wrapx(req, res, r_bingo_presets_update),
// );
// router.post("/bingo_presets_delete", async (req, res) =>
//   fnr_wrapx(req, res, r_bingo_presets_delete),
// );
export const q_factions_bingo_presets_list = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_presets_list",
    `${btbk}/fbike/admin/factions_bingo/bingo_presets_list`,
    doc,
    ext,
  );
};
export const q_factions_bingo_presets_doc = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_presets_doc",
    `${btbk}/fbike/admin/factions_bingo/bingo_presets_doc`,
    doc,
    ext,
  );
};
export const q_factions_bingo_presets_update = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_bingo_presets_update",
    `${btbk}/fbike/admin/factions_bingo/bingo_presets_update`,
    doc,
    ext,
  );
};
export const q_factions_bingo_presets_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_bingo_presets_delete",
    `${btbk}/fbike/admin/factions_bingo/bingo_presets_delete`,
    doc,
    ext,
  );
};

export const q_sheet_pages_funds = (doc, ext = {}) => {
  return q_auconstruct(
    "q_sheet_pages_funds",
    `${btbk}/fbike/sheet_pages/funds`,
    doc,
    ext,
  );
};

// /fbike/admin/bulk_race_payouts/addrows
// /fbike/admin/bulk_race_payouts/delrows
// /fbike/admin/bulk_race_payouts/list
// /fbike/admin/bulk_race_payouts/cc
export const q_bulk_race_payouts_addrows = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_bulk_race_payouts_addrows",
    `${btbk}/fbike/admin/bulk_race_payouts/addrows`,
    doc,
    ext,
  );
};
export const q_bulk_race_payouts_delrows = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_bulk_race_payouts_delrows",
    `${btbk}/fbike/admin/bulk_race_payouts/delrows`,
    doc,
    ext,
  );
};
export const q_bulk_race_payouts_list = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_bulk_race_payouts_list",
    `${btbk}/fbike/admin/bulk_race_payouts/list`,
    doc,
    ext,
  );
};
export const q_bulk_race_payouts_cc = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_bulk_race_payouts_cc",
    `${btbk}/fbike/admin/bulk_race_payouts/cc`,
    doc,
    ext,
  );
};

// /fbike/admin/prizebox/recent_bigbox_mints
export const q_prizebox_recent_bigbox_mints = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_prizebox_recent_bigbox_mints",
    `${btbk}/fbike/admin/prizebox/recent_bigbox_mints`,
    doc,
    ext,
  );
};

// /fbike/admin/prizebox/update_bigbox_mint
export const q_prizebox_update_bigbox_mint = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_prizebox_update_bigbox_mint",
    `${btbk}/fbike/admin/prizebox/update_bigbox_mint`,
    doc,
    ext,
  );
};

// /fbike/admin/races/hstats
export const q_admin_hstats = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_admin_hstats",
    `${btbk}/fbike/admin/races/hstats`,
    doc,
    ext,
  );
};

//fbike/quest_cards/admin_pending_prizes
export const q_quest_cards_admin_pending_prizes = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_admin_pending_prizes",
    `${btbk}/fbike/quest_cards/admin_pending_prizes`,
    doc,
    ext,
  );
};

//fbike/quest_cards/admin_payout_prize
export const q_quest_cards_admin_payout_prize = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_admin_payout_prize",
    `${btbk}/fbike/quest_cards/admin_payout_prize`,
    doc,
    ext,
  );
};

//fbike/quest_cards/admin_givecards
export const q_quest_cards_admin_givecards = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_admin_givecards",
    `${btbk}/fbike/quest_cards/admin_givecards`,
    doc,
    ext,
  );
};

//fbike/quest_cards/admin_givecards_history
export const q_quest_cards_admin_givecards_history = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_admin_givecards_history",
    `${btbk}/fbike/quest_cards/admin_givecards_history`,
    doc,
    ext,
  );
};
