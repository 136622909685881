import React, { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../App.js";
import {
  base64_to_json,
  dec,
  getv,
  json_to_base64,
  jstr,
  nils,
} from "../utils/utils.js";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { gen_valob_from_filters } from "../utils/filt.js";
import { InpAutoWrap, InpDatesRange } from "../components/input.js";
import {
  q_vault_stats_raw,
  q_vault_stats_splice_chart,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { BarChart } from "../components/charts.js";
import tailwindcss_colors from "tailwindcss/colors";
import moment from "moment";

const VaultStatsContext = React.createContext({});
const useVaultStatsContext = () => React.useContext(VaultStatsContext);

const InpSection = () => {
  let vcon = useVaultStatsContext();
  let {
    sel_vaults,
    set_sel_vaults,
    view,
    set_view,
    vmap = {},
    filt,
    set_filt,
  } = vcon;

  const inpargs = {
    filters: filt,
    set_filters: set_filt,
    fkey: "vault_stats",
  };

  let id = "inp-vaults-search";
  return (
    <Card className="bg-reg w-full">
      <div className="h-2"></div>

      <div className="fr-sc my-2 resp-gap-4">
        <>
          <InpAutoWrap {...{ ...inpargs, idd: "date" }} />
        </>
        {["vaults", "all"].map((_v) => {
          let active = view == _v;
          return (
            <>
              <Tag
                onClick={() => set_view(_v)}
                className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
              >
                <span>{_.capitalize(_v)} Mode</span>
              </Tag>
            </>
          );
        })}
      </div>

      <div className="relative">
        <div className="">
          <InpText
            {...{
              id: id,
              placeholder: "Search Vault",

              contprops: { className: "w-[30rem] font-mono resp-text--1" },
              inpprops: { className: "w-full" },
            }}
          />
        </div>
      </div>

      <div className="grid grid-cols-5 resp-gap-2">
        {sel_vaults.map((vault) => {
          let v = vmap[vault];
          return (
            <>
              <div className="col-span-3 fc-ss">
                <p className="resp-text--1">{v.vault_name}</p>
                <p className="resp-text---3 text-acc0">{vault}</p>
              </div>
              <div className="">
                <span>-</span>
              </div>
              <div className="">
                <span>-</span>
              </div>
            </>
          );
        })}
      </div>
    </Card>
  );
};

const LoadWrapper = ({
  q = {},
  title = "",
  cont_cn,
  cont_click = () => {},
  ...props
}) => {
  return (
    <div className={cont_cn} onClick={cont_click}>
      <p className="resp-text-1 my-2 text-acc0 text-center font-digi">
        {title}
      </p>
      {q.isLoading ? (
        <Loader01c />
      ) : qiserr(q) ? (
        <p className="resp-text--2 text-center text-acc0">Error: {qiserr(q)}</p>
      ) : qissuccesss(q) && _.isEmpty(getv(q, "data.result")) ? (
        <p className="resp-text--2 text-center text-yellow-300">No Data</p>
      ) : qissuccesss(q) && !_.isEmpty(getv(q, "data.result")) ? (
        props.children
      ) : null}
    </div>
  );
};

const getIntervalStartDate = (date, interval) => {
  const currentDate = moment(date);
  return currentDate
    .subtract(currentDate.dayOfYear() % interval, "days")
    .format("YYYY-MM-DD");
};

const SpliceChartView = () => {
  const vcon = useVaultStatsContext();
  const { qo_splice_chart } = vcon;

  const ints = [1, 5, 7, 15, 30];
  const [int, set_int] = useState(1);

  const ar = useMemo(() => {
    let ar = getv(qo_splice_chart, "data.result", []);
    return ar;
  }, [qo_splice_chart.dataUpdatedAt]);

  const getIntervalStartDate = (date, interval) => {
    const currentDate = moment(date);
    return currentDate
      .subtract(currentDate.dayOfYear() % interval, "days")
      .format("YYYY-MM-DD");
  };

  const comp = useMemo(() => {
    let c = _.chain(ar)
      .groupBy((e) => {
        e.gpdate = getIntervalStartDate(e.date, int);
        return e.gpdate;
      })
      .entries()
      .map(([k, v]) => {
        let o = {};
        o.vaults_n = _.chain(v).map("vault").uniq().value().length;

        o.splices_n = _.chain(v).map("splices_n").sum().value();
        o.avg_splices_n_per_vault = _.chain(v).map("splices_n").mean().value();

        o.tot_dna_fee = _.chain(v).map("tot_dna_fee").sum().value();
        o.avg_dna_fee_per_vault = _.chain(v).map("tot_dna_fee").mean().value();

        return { date: k, o };
      })
      .sortBy("date")
      .value();

    return c;
  }, [jstr(ar), int]);

  const charts = useMemo(() => {
    let labels = _.map(comp, (e) => e.date.slice(5));

    let cs = [
      ["Tot.#Vaults", _.map(comp, (e) => e.o.vaults_n)],
      null,
      ["Tot.#Splices", _.map(comp, (e) => e.o.splices_n)],
      [
        "Avg.#splices -/vault",
        _.map(comp, (e) => dec(getv(e, "o.avg_splices_n_per_vault")), 3),
      ],
      ["Tot.DNA Fee", _.map(comp, (e) => dec(getv(e, "o.tot_dna_fee")), 3)],
      [
        "Avg.fee -/vault",
        _.map(comp, (e) => dec(getv(e, "o.avg_dna_fee_per_vault")), 3),
      ],
    ];
    cs = _.chain(cs)
      .map((e) => {
        if (nils(e)) return null;
        return {
          title: e[0],
          labels,
          label: e[0],
          cdata: e[1],
        };
      })
      .value();

    return cs;
  }, [jstr(comp)]);

  return (
    <>
      <div className="fr-sc resp-gap-1 resp-text--1">
        <div className="flex-1"></div>
        <span>Intervals</span>
        {ints.map((e) => {
          let active = int == e;
          return (
            <Tag
              onClick={() => set_int(e)}
              className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
            >
              <span className="resp-text--2">{e}D</span>
            </Tag>
          );
        })}
      </div>
      <div className="grid grid-cols-2 resp-gap-2">
        {charts.map((c) => {
          if (nils(c)) return <div className=""></div>;
          return (
            <div>
              <p className="resp-text--1 text-acc0 my-1 text-center ">
                {c.title}
              </p>
              <BarChart
                {...{
                  labels: c.labels,
                  cdata: c.cdata,
                  label: c.label,
                  backgroundColor: tailwindcss_colors.teal[600],
                  datalabelsColor: "white",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const RacesPaid = () => {
  const vcon = useVaultStatsContext();
  const { qo_paid_weth_chart, qo_paid_dez_chart } = vcon;

  const ints = [1, 5, 7, 15, 30];
  const [int, set_int] = useState(1);

  const ar = useMemo(() => {
    let ar1 = getv(qo_paid_weth_chart, "data.result", []);
    let ar2 = getv(qo_paid_dez_chart, "data.result", []);
    let ar = [...ar1, ...ar2];
    return ar;
  }, [qo_paid_weth_chart.dataUpdatedAt]);

  const comp = useMemo(() => {
    let c = _.chain(ar)
      .groupBy((e) => {
        e.gpdate = getIntervalStartDate(e.date, int);
        return e.gpdate;
      })
      .entries()
      .map(([k, v]) => {
        let o = {};
        o.vaults_n = _.chain(v).map("vault").uniq().value().length;
        return { date: k, o };
      })
      .sortBy("date")
      .value();

    return c;
  }, [jstr(ar), int]);

  const charts = useMemo(() => {
    let labels = _.map(comp, (e) => e.date.slice(5));

    let cs = [["Active Vaults", _.map(comp, (e) => e.o.vaults_n)]];
    cs = _.chain(cs)
      .map((e) => {
        if (nils(e)) return null;
        return {
          title: e[0],
          labels,
          label: e[0],
          cdata: e[1],
        };
      })
      .value();

    return cs;
  }, [jstr(comp)]);

  return (
    <>
      <div className="fr-sc resp-gap-1 resp-text--1">
        <div className="flex-1"></div>
        <span>Intervals</span>
        {ints.map((e) => {
          let active = int == e;
          return (
            <Tag
              onClick={() => set_int(e)}
              className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
            >
              <span className="resp-text--2">{e}D</span>
            </Tag>
          );
        })}
      </div>
      <div className="grid grid-cols-2 resp-gap-2">
        {charts.map((c) => {
          if (nils(c)) return <div className=""></div>;
          return (
            <div>
              <p className="resp-text--1 text-acc0 my-1 text-center ">
                {c.title}
              </p>
              <BarChart
                {...{
                  labels: c.labels,
                  cdata: c.cdata,
                  label: c.label,
                  backgroundColor: tailwindcss_colors.teal[600],
                  datalabelsColor: "white",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const RacesPaidWETH_Chart = () => {
  const vcon = useVaultStatsContext();
  const { qo_paid_weth_chart } = vcon;

  const ints = [1, 5, 7, 15, 30];
  const [int, set_int] = useState(1);

  const ar = useMemo(() => {
    let ar = getv(qo_paid_weth_chart, "data.result", []);
    return ar;
  }, [qo_paid_weth_chart.dataUpdatedAt]);

  const comp = useMemo(() => {
    let c = _.chain(ar)
      .groupBy((e) => {
        e.gpdate = getIntervalStartDate(e.date, int);
        return e.gpdate;
      })
      .entries()
      .map(([k, v]) => {
        let o = {};
        o.vaults_n = _.chain(v).map("vault").uniq().value().length;
        o.avg_races_n_per_vault = _.chain(v).map("races_n").mean().value();

        o.tot_fee = _.chain(v).map("tot_fee").sum().value();
        o.avg_tot_fee_per_vault = _.chain(v).map("tot_fee").mean().value();

        return { date: k, o };
      })
      .sortBy("date")
      .value();

    return c;
  }, [jstr(ar), int]);

  const charts = useMemo(() => {
    let labels = _.map(comp, (e) => e.date.slice(5));

    let cs = [
      ["Active Vaults", _.map(comp, (e) => e.o.vaults_n)],
      [
        "Avg #Races per vault",
        _.map(comp, (e) => dec(e.o.avg_races_n_per_vault, 0)),
      ],
      ["Tot.Fee", _.map(comp, (e) => dec(getv(e, "o.tot_fee"), 3))],
      [
        "Avg of Tot.Fee per vault",
        _.map(comp, (e) => dec(getv(e, "o.avg_tot_fee_per_vault"), 4)),
      ],
    ];
    cs = _.chain(cs)
      .map((e) => {
        if (nils(e)) return null;
        return {
          title: e[0],
          labels,
          label: e[0],
          cdata: e[1],
        };
      })
      .value();

    return cs;
  }, [jstr(comp)]);

  return (
    <>
      <div className="fr-sc resp-gap-1 resp-text--1">
        <div className="flex-1"></div>
        <span>Intervals</span>
        {ints.map((e) => {
          let active = int == e;
          return (
            <Tag
              onClick={() => set_int(e)}
              className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
            >
              <span className="resp-text--2">{e}D</span>
            </Tag>
          );
        })}
      </div>
      <div className="grid grid-cols-2 resp-gap-2">
        {charts.map((c) => {
          if (nils(c)) return <div className=""></div>;
          return (
            <div>
              <p className="resp-text--1 text-acc0 my-1 text-center ">
                {c.title}
              </p>
              <BarChart
                {...{
                  labels: c.labels,
                  cdata: c.cdata,
                  label: c.label,
                  backgroundColor: tailwindcss_colors.teal[600],
                  datalabelsColor: "white",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
const RacesPaidDEZ_Chart = () => {
  const vcon = useVaultStatsContext();
  const { qo_paid_dez_chart } = vcon;

  const ints = [1, 5, 7, 15, 30];
  const [int, set_int] = useState(1);

  const ar = useMemo(() => {
    let ar = getv(qo_paid_dez_chart, "data.result", []);
    return ar;
  }, [qo_paid_dez_chart.dataUpdatedAt]);

  const comp = useMemo(() => {
    let c = _.chain(ar)
      .groupBy((e) => {
        e.gpdate = getIntervalStartDate(e.date, int);
        return e.gpdate;
      })
      .entries()
      .map(([k, v]) => {
        let o = {};
        o.vaults_n = _.chain(v).map("vault").uniq().value().length;
        o.avg_races_n_per_vault = _.chain(v).map("races_n").mean().value();

        o.tot_fee = _.chain(v).map("tot_fee").sum().value();
        o.avg_tot_fee_per_vault = _.chain(v).map("tot_fee").mean().value();

        return { date: k, o };
      })
      .sortBy("date")
      .value();

    return c;
  }, [jstr(ar), int]);

  const charts = useMemo(() => {
    let labels = _.map(comp, (e) => e.date.slice(5));

    let cs = [
      ["Active Vaults", _.map(comp, (e) => e.o.vaults_n)],
      [
        "Avg #Races per vault",
        _.map(comp, (e) => dec(e.o.avg_races_n_per_vault, 0)),
      ],
      ["Tot.Fee", _.map(comp, (e) => dec(getv(e, "o.tot_fee"), 0))],
      [
        "Avg of Tot.Fee per vault",
        _.map(comp, (e) => dec(getv(e, "o.avg_tot_fee_per_vault"), 0)),
      ],
    ];
    cs = _.chain(cs)
      .map((e) => {
        if (nils(e)) return null;
        return {
          title: e[0],
          labels,
          label: e[0],
          cdata: e[1],
        };
      })
      .value();

    return cs;
  }, [jstr(comp)]);

  return (
    <>
      <div className="fr-sc resp-gap-1 resp-text--1">
        <div className="flex-1"></div>
        <span>Intervals</span>
        {ints.map((e) => {
          let active = int == e;
          return (
            <Tag
              onClick={() => set_int(e)}
              className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
            >
              <span className="resp-text--2">{e}D</span>
            </Tag>
          );
        })}
      </div>
      <div className="grid grid-cols-2 resp-gap-2">
        {charts.map((c) => {
          if (nils(c)) return <div className=""></div>;
          return (
            <div>
              <p className="resp-text--1 text-acc0 my-1 text-center ">
                {c.title}
              </p>
              <BarChart
                {...{
                  labels: c.labels,
                  cdata: c.cdata,
                  label: c.label,
                  backgroundColor: tailwindcss_colors.teal[600],
                  datalabelsColor: "white",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
const RacesFree_Chart = () => {
  const vcon = useVaultStatsContext();
  const { qo_free_chart: qo } = vcon;

  const ints = [1, 5, 7, 15, 30];
  const [int, set_int] = useState(1);

  const ar = useMemo(() => {
    let ar = getv(qo, "data.result", []);
    return ar;
  }, [qo.dataUpdatedAt]);

  const comp = useMemo(() => {
    let c = _.chain(ar)
      .groupBy((e) => {
        e.gpdate = getIntervalStartDate(e.date, int);
        return e.gpdate;
      })
      .entries()
      .map(([k, v]) => {
        let o = {};
        o.vaults_n = _.chain(v).map("vault").uniq().value().length;
        o.avg_races_n_per_vault = _.chain(v).map("races_n").mean().value();

        return { date: k, o };
      })
      .sortBy("date")
      .value();

    return c;
  }, [jstr(ar), int]);

  const charts = useMemo(() => {
    let labels = _.map(comp, (e) => e.date.slice(5));

    let cs = [
      ["Active Vaults", _.map(comp, (e) => e.o.vaults_n)],
      [
        "Avg #Races per vault",
        _.map(comp, (e) => dec(e.o.avg_races_n_per_vault, 0)),
      ],
    ];
    cs = _.chain(cs)
      .map((e) => {
        if (nils(e)) return null;
        return {
          title: e[0],
          labels,
          label: e[0],
          cdata: e[1],
        };
      })
      .value();

    return cs;
  }, [jstr(comp)]);

  return (
    <>
      <div className="fr-sc resp-gap-1 resp-text--1">
        <div className="flex-1"></div>
        <span>Intervals</span>
        {ints.map((e) => {
          let active = int == e;
          return (
            <Tag
              onClick={() => set_int(e)}
              className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
            >
              <span className="resp-text--2">{e}D</span>
            </Tag>
          );
        })}
      </div>
      <div className="grid grid-cols-2 resp-gap-2">
        {charts.map((c) => {
          if (nils(c)) return <div className=""></div>;
          return (
            <div>
              <p className="resp-text--1 text-acc0 my-1 text-center ">
                {c.title}
              </p>
              <BarChart
                {...{
                  labels: c.labels,
                  cdata: c.cdata,
                  label: c.label,
                  backgroundColor: tailwindcss_colors.teal[600],
                  datalabelsColor: "white",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const SkinMint_Chart = () => {
  const vcon = useVaultStatsContext();
  const { qo_skinmint_chart: qo } = vcon;

  const ints = [1, 5, 7, 15, 30];
  const [int, set_int] = useState(1);

  const ar = useMemo(() => {
    let ar = getv(qo, "data.result", []);
    return ar;
  }, [qo.dataUpdatedAt]);

  const comp = useMemo(() => {
    let c = _.chain(ar)
      .groupBy((e) => {
        e.gpdate = getIntervalStartDate(e.date, int);
        return e.gpdate;
      })
      .entries()
      .map(([k, v]) => {
        let o = {};
        o.vaults_n = _.chain(v).map("vault").uniq().value().length;
        o.tot_skins_fee = _.chain(v).map("skins_n").sum().value() * 15;

        o.tot_skins_n = _.chain(v).map("skins_n").sum().value();
        o.avg_skins_per_vault = _.chain(v).map("skins_n").mean().value();

        return { date: k, o };
      })
      .sortBy("date")
      .value();

    return c;
  }, [jstr(ar), int]);

  const charts = useMemo(() => {
    let labels = _.map(comp, (e) => e.date.slice(5));

    let cs = [
      ["Active Vaults", _.map(comp, (e) => e.o.vaults_n)],
      ["Tot.SkinFee [USD]", _.map(comp, (e) => e.o.tot_skins_fee)],
      ["Tot.Skins Minted", _.map(comp, (e) => e.o.tot_skins_n)],
      [
        "Avg.Skins Minted per vault",
        _.map(comp, (e) => dec(e.o.avg_skins_per_vault, 2)),
      ],
    ];
    cs = _.chain(cs)
      .map((e) => {
        if (nils(e)) return null;
        return {
          title: e[0],
          labels,
          label: e[0],
          cdata: e[1],
        };
      })
      .value();

    return cs;
  }, [jstr(comp)]);

  return (
    <>
      <div className="fr-sc resp-gap-1 resp-text--1">
        <div className="flex-1"></div>
        <span>Intervals</span>
        {ints.map((e) => {
          let active = int == e;
          return (
            <Tag
              onClick={() => set_int(e)}
              className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
            >
              <span className="resp-text--2">{e}D</span>
            </Tag>
          );
        })}
      </div>
      <div className="grid grid-cols-2 resp-gap-2">
        {charts.map((c) => {
          if (nils(c)) return <div className=""></div>;
          return (
            <div>
              <p className="resp-text--1 text-acc0 my-1 text-center ">
                {c.title}
              </p>
              <BarChart
                {...{
                  labels: c.labels,
                  cdata: c.cdata,
                  label: c.label,
                  backgroundColor: tailwindcss_colors.teal[600],
                  datalabelsColor: "white",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const SignUps_Chart = () => {
  const vcon = useVaultStatsContext();
  const { qo_signups_chart: qo } = vcon;

  const ints = [1, 5, 7, 15, 30];
  const [int, set_int] = useState(1);

  const ar = useMemo(() => {
    let ar = getv(qo, "data.result", []);
    return ar;
  }, [qo.dataUpdatedAt]);

  const comp = useMemo(() => {
    let c = _.chain(ar)
      .groupBy((e) => {
        e.gpdate = getIntervalStartDate(e.date, int);
        return e.gpdate;
      })
      .entries()
      .map(([k, v]) => {
        let o = {};
        o.signups_n = _.chain(v).map("vaults_n").sum().value();
        return { date: k, o };
      })
      .sortBy("date")
      .value();

    return c;
  }, [jstr(ar), int]);

  const charts = useMemo(() => {
    let labels = _.map(comp, (e) => e.date.slice(5));

    let cs = [["SignUps", _.map(comp, (e) => e.o.signups_n)]];
    cs = _.chain(cs)
      .map((e) => {
        if (nils(e)) return null;
        return {
          title: e[0],
          labels,
          label: e[0],
          cdata: e[1],
        };
      })
      .value();

    return cs;
  }, [jstr(comp)]);

  return (
    <>
      <div className="fr-sc resp-gap-1 resp-text--1">
        <div className="flex-1"></div>
        <span>Intervals</span>
        {ints.map((e) => {
          let active = int == e;
          return (
            <Tag
              onClick={() => set_int(e)}
              className={twMerge(active ? "bg-acc0/60" : "bg-acc0/10")}
            >
              <span className="resp-text--2">{e}D</span>
            </Tag>
          );
        })}
      </div>
      <div className="grid grid-cols-2 resp-gap-2">
        {charts.map((c) => {
          if (nils(c)) return <div className=""></div>;
          return (
            <div>
              <p className="resp-text--1 text-acc0 my-1 text-center ">
                {c.title}
              </p>
              <BarChart
                {...{
                  labels: c.labels,
                  cdata: c.cdata,
                  label: c.label,
                  backgroundColor: tailwindcss_colors.teal[600],
                  datalabelsColor: "white",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export const VaultStatsPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const bfilt = useMemo(() => {
    let f = psearch.f;
    if (nils(f)) return {};
    f = base64_to_json(f);
    return f;
  }, [jstr(psearch)]);

  const [view, set_view] = useState(psearch.view ? psearch.view : "all");
  const [sel_vaults, set_sel_vaults] = useState(
    nils(psearch.vaults) ? [] : psearch.vaults
  );

  const [filt, set_filt] = useState({
    date: {
      label: "Date",
      vals: bfilt?.date ?? { mi: null, mx: null },
      type: "date-range",
    },
  });
  const valob = useMemo(() => {
    let f = gen_valob_from_filters(filt);
    return f;
  }, [jstr(filt)]);

  useMemo(() => {
    upd_psearch({ vaults: sel_vaults });
  }, []);

  useEffect(() => {
    let f = json_to_base64(valob);
    let o = { view, f, sel_vaults };
    upd_psearch(o);
  }, [jstr(valob), view, jstr(sel_vaults)]);

  const baseinf =
    view == "all"
      ? {
          date: valob.date,
        }
      : {
          sel_vaults,
          date: valob.date,
        };

  const [
    qo_splice_chart,
    qo_paid_weth_chart,
    qo_paid_dez_chart,
    qo_free_chart,
    qo_skinmint_chart,
    qo_signups_chart,
  ] = useQueries([
    q_vault_stats_raw({ ...baseinf, mat: { type: "splice" } }),
    q_vault_stats_raw({ ...baseinf, mat: { type: "races-paid-weth" } }),
    q_vault_stats_raw({ ...baseinf, mat: { type: "races-paid-dez" } }),
    q_vault_stats_raw({ ...baseinf, mat: { type: "races-free" } }),
    q_vault_stats_raw({ ...baseinf, mat: { type: "skinmint" } }),
    q_vault_stats_raw({ ...baseinf, mat: { type: "signups" } }),
  ]);

  const vcon = {
    view,
    set_view,

    sel_vaults,
    set_sel_vaults,

    filt,
    set_filt,
    qo_splice_chart,
    qo_paid_weth_chart,
    qo_paid_dez_chart,
    qo_free_chart,
    qo_skinmint_chart,
    qo_signups_chart,
  };
  return (
    <VaultStatsContext.Provider value={vcon}>
      <div className="h-page">
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[3rem]"></div>
          <p className="text-acc0 resp-text-1 my-2 text-center">
            Vaults Day Summary
          </p>

          <InpSection />

          <div className="">
            <LoadWrapper
              q={qo_splice_chart}
              cont_cn={"bg-reg w-full rounded-md resp-p-4 my-2"}
              onClick={() => {}}
              title="Splice Chart"
            >
              <SpliceChartView />
            </LoadWrapper>

            <LoadWrapper
              q={
                qo_paid_dez_chart.isLoading
                  ? qo_paid_dez_chart
                  : qo_paid_weth_chart
              }
              cont_cn={"bg-reg w-full rounded-md resp-p-4 my-2"}
              onClick={() => {}}
              title="Races Paid"
            >
              <RacesPaid />
            </LoadWrapper>

            <LoadWrapper
              q={qo_paid_weth_chart}
              cont_cn={"bg-reg w-full rounded-md resp-p-4 my-2"}
              onClick={() => {}}
              title="Races Paid WETH"
            >
              <RacesPaidWETH_Chart />
            </LoadWrapper>

            <LoadWrapper
              q={qo_paid_dez_chart}
              cont_cn={"bg-reg w-full rounded-md resp-p-4 my-2"}
              onClick={() => {}}
              title="Races Paid DEZ"
            >
              <RacesPaidDEZ_Chart />
            </LoadWrapper>

            <LoadWrapper
              q={qo_free_chart}
              cont_cn={"bg-reg w-full rounded-md resp-p-4 my-2"}
              onClick={() => {}}
              title="Races Free"
            >
              <RacesFree_Chart />
            </LoadWrapper>

            <LoadWrapper
              q={qo_skinmint_chart}
              cont_cn={"bg-reg w-full rounded-md resp-p-4 my-2"}
              onClick={() => {}}
              title="Skins Mint"
            >
              <SkinMint_Chart />
            </LoadWrapper>

            <LoadWrapper
              q={qo_signups_chart}
              cont_cn={"bg-reg w-full rounded-md resp-p-4 my-2"}
              onClick={() => {}}
              title="Sign Ups"
            >
              <SignUps_Chart />
            </LoadWrapper>
          </div>

          <div className="h-[5rem]"></div>
        </div>
      </div>
    </VaultStatsContext.Provider>
  );
};
