import { MetaMaskSDK } from "@metamask/sdk";
import detectEthereumProvider from "@metamask/detect-provider";
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { Tag } from "../components/utilityComps.js";
import { createContext } from "react";
import { useContext } from "react";
import { useAppContext } from "../App.js";
import _ from "lodash";
import { cdelay, getv, jstr, nils } from "../utils/utils.js";
import {
  q_auth_get_nonce,
  q_auth_verify_nonce,
  q_auth_verify_token,
} from "../queries/queries.js";
import { PopUp } from "../components/popup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MetaMaskContext = createContext({});
export const useMetaMaskContext = () => useContext(MetaMaskContext);

export const token_locpath = "jwt_admin_auth_token";
export const tokenacc_locpath = "jwt_admin_auth_account";

const get_token = () => {
  let token = localStorage.getItem(token_locpath);
  return token;
};

const gen_token = async () => {
  let mm = window.ethereum;
  let account = mm.selectedAddress;
  let vault = account.toLowerCase();

  let non = await q_auth_get_nonce({ vault });
  let nonce = getv(non, "result.nonce");
  if (nils(nonce)) throw new Error("couldn't fetch nonce");

  let tosign = nonce;
  let signed_data = await mm.request({
    method: "personal_sign",
    params: [tosign, account],
  });

  let resptok = await q_auth_verify_nonce({ signed_data, vault });
  if (resptok?.status == "error") throw new Error(resptok.err);

  let token = getv(resptok, "result.token");
  localStorage.setItem(token_locpath, token);
  localStorage.setItem(tokenacc_locpath, account);
  return token;
};

const request_metamask_login = async () => {
  let mm = window.ethereum;
  let resp = await mm.request({ method: "eth_requestAccounts" });
  let acc = getv(resp, 0);
  return acc;
};

const relogin = async () => {
  let mm = window.ethereum;
  await mm.request({
    method: "wallet_requestPermissions",
    params: [{ eth_accounts: {} }],
  });
};

const verify_token = async () => {
  let token = localStorage.getItem("jwt_auth_token");
  let vault = window.ethereum.selectedAddress?.toLowerCase();
  let resp = await q_auth_verify_token({ vault, token });
  if (resp.err) throw new Error(resp.err);
  return resp.result;
};

export const MetaMaskWrapper = (props) => {
  const { path } = useAppContext();

  const mm = window.ethereum;
  const [mm_connected, set_mm_connected] = useState(undefined);
  useEffect(() => {
    let fn = async () => {
      await cdelay(500);
      const mm = window.ethereum;
      let conn = nils(mm) ? false : window.ethereum?.isConnected();
      set_mm_connected(conn);
    };
    fn();
  }, []);
  const account = window.ethereum?.selectedAddress;
  const vault = useMemo(() => {
    if (nils(account)) return null;
    return account.toLowerCase();
  }, [account]);

  const mmcon = {
    mm,
    mm_connected,

    request_metamask_login,
    gen_token,
    get_token,
    verify_token,
    relogin,

    account,
    vault,
  };

  return (
    <>
      <MetaMaskContext.Provider value={mmcon}>
        {mm_connected === false && (
          <PopUp openstate={true} overlayclose={false}>
            <div className="min-w-[30rem]">
              <p className="text-[1.2rem] text-center">
                Please Install MetaMask
              </p>
              <a
                target="_blank"
                href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
              >
                <div className="fr-cc bg-white rounded-md p-2 gap-2 w-max mx-auto">
                  <img
                    className="h-[4rem]"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
                    alt=""
                  />
                  <img
                    className="h-[4rem]"
                    src="https://storage.googleapis.com/web-dev-uploads/image/WlD8wC6g8khYWPJUsQceQkhXSlv1/YT2Grfi9vEBa2wAPzhWa.png"
                  />
                </div>
              </a>
            </div>
          </PopUp>
        )}
        {mm_connected == true && <>{props.children}</>}
      </MetaMaskContext.Provider>
    </>
  );
};
