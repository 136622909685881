import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Img,
  InpText,
  Tag,
  TokenIcon,
} from "../components/utilityComps.js";
import {
  cdelay,
  dec,
  getv,
  nils,
  toeth,
  tofeth,
  jstr,
  iso_format,
  iso,
  snake_str_to_caps,
} from "../utils/utils.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import {
  polytxnidlink,
  polytxnlink,
  polytxnlink_sp,
  q_actions_maiden_date_get,
  q_actions_maiden_date_set,
  q_admin_actions_cancel_races,
  q_admin_actions_cancel_satellite,
  q_admin_actions_mint_core_lootbox,
  q_admin_actions_mint_skin_lootbox,
  q_admin_mint_prizebox,
  q_prizebox_recent_bigbox_mints,
  q_prizebox_update_bigbox_mint,
  q_quest_cards_admin_givecards,
  q_quest_cards_admin_givecards_history,
  q_quest_cards_admin_payout_prize,
  q_quest_cards_admin_pending_prizes,
} from "../queries/queries.js";
import {
  DateInput,
  extract_inp,
  set_state_ob,
  set_val_inp,
} from "../components/input.js";
import { Loader01c } from "../components/anims.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { contractAddress_list } from "../contracts/constants.js";
import { mm_asset_signer } from "../contracts/contract_funcs.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faInfoCircle,
  faSpinner,
  faSync,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import RaceStakeV02 from "../contracts/RaceStakeV02/RaceStakeV02.js";
import RaceStake from "../contracts/RaceStake/RaceStakeContract";
import RaceStakeV03_DEZ from "../contracts/RaceStakeV03_DEZ/RaceStakeV03_DEZ.js";
import Splicing from "../contracts/Splicing/SplicingContract.js";
import FairexMCBets from "../contracts/FairexMCBets/FairexMCBets";
import PrizeBox from "../contracts/PrizeBox/PrizeBox.js";
import { useQueries } from "react-query";
import { polychainimg } from "../utils/links.js";
import { Link } from "react-router-dom";
import { useAppContext, tokdecn } from "../App.js";
import { tablecn } from "../utils/cn_map.js";
const ActionsContext = React.createContext();
const useActionsContext = () => React.useContext(ActionsContext);

const AcMintPrizebox = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpid = "inp:mint:prizebox";
  const doaction = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      let con = await PrizeBox.get_contract();
      let resp = await con.o_mintLootbox(docdata.vault, docdata.qty, 1);

      set_resp({
        status: "success",
        msg: `minted: ${resp.hash}`,
      });

      set_docdata({});
      set_val_inp(`${inpid}:vault`, "");
      set_val_inp(`${inpid}:qty`, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
      }, 5000);
    } catch (err) {
      let errtxt = null;
      if (!nils(err.reason)) errtxt = err.reason;
      else errtxt = err.message;
      // await cdelay(3000);
      set_resp({
        status: "error",
        msg: errtxt,
      });
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">Mint Prizeboxes</p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2 fr-sc">
        <InpText
          {...{
            id: `${inpid}:vault`,
            placeholder: "Vault",
            contprops: { className: "w-[20rem]" },
            inpprops: { className: "w-full" },
            setter: () => {
              let v = extract_inp(`${inpid}:vault`);
              if (nils(v)) v = null;
              set_state_ob(docdata, set_docdata, "vault", v);
            },
          }}
        />
        <InpText
          {...{
            id: `${inpid}:qty`,
            placeholder: "Qty",
            contprops: { className: "w-[5rem]" },
            inpprops: { className: "w-full" },
            setter: () => {
              let v = extract_inp(`${inpid}:qty`);
              v = parseInt(v);
              if (nils(v)) v = null;

              set_state_ob(docdata, set_docdata, "qty", v);
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <span>
          {docdata.qty ?? "--"} boxes to {docdata.vault ?? "--"}
        </span>
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Mint
          </Tag>
        )}
      </div>
    </Card>
  );
};

const AcMintBigBox = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});

  const inpid = "inp:mint:prizebox:bigbox";
  const appcon = useAppContext();
  const { usd_to_tok_val, tok_to_usd_val } = appcon;

  const doaction_mintbigboxes = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      let con = await PrizeBox.get_contract();
      let resp = await con.o_mintLootbox(docdata.vault, docdata.qty, 2);

      set_resp({
        status: "success",
        msg: `minted: ${resp.hash}`,
      });

      set_docdata({});
      set_val_inp(`${inpid}:vault`, "");
      set_val_inp(`${inpid}:qty`, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
      }, 5000);
    } catch (err) {
      let errtxt = null;
      if (!nils(err.reason)) errtxt = err.reason;
      else errtxt = err.message;
      // await cdelay(3000);
      set_resp({
        status: "error",
        msg: errtxt,
      });
      set_loading(false);
    }
  };

  const [qo_recent_bigbox_mints] = useQueries([
    q_prizebox_recent_bigbox_mints({ page: 1 }),
  ]);
  const mintrows = useMemo(
    () => getv(qo_recent_bigbox_mints, "data.result") || [],
    [qo_recent_bigbox_mints],
  );

  const [token, set_token] = useState("DEZ");
  const [payresp, set_payresp] = useState({});
  const doaction_sendpayout = async (r) => {
    try {
      set_payresp({
        rowid: `${r.boxid}:${r.aidx}`,
        type: "loading",
        msg: "confirm txn",
      });
      let to = r.vault;
      let { amt, type } = r.asset;
      if (type !== "USD") return;
      let usetoken = token;
      if (nils(usetoken)) throw new Error("no token specified");

      let tokamt = usd_to_tok_val(amt, usetoken);
      tokamt = parseFloat(dec(tokamt, tokdecn(usetoken)));
      console.log({ tokamt, usetoken });
      // tokamt = 10;
      // usetoken = "DEZ";

      let mmcon = await mm_asset_signer(usetoken);
      let resp = await mmcon.transfer(to, toeth(tokamt));
      console.log(resp);
      await q_prizebox_update_bigbox_mint({
        boxid: r.boxid,
        aidx: r.aidx,
        hash: resp.hash,
      }).queryFn();
      resp = await resp.wait();
      console.log(resp);
      set_payresp((o) => ({
        ...o,
        type: "success",
        msg: "success",
      }));

      window.open(polytxnlink(resp.hash));
      setTimeout(() => {
        qo_recent_bigbox_mints.refetch();
      }, 5 * 1e3);
    } catch (err) {
      console.log(err);
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      if (errmsg.length > 100) errmsg = errmsg.slice(0, 100);
      set_payresp({
        type: "error",
        msg: errmsg,
      });
      setTimeout(() => {
        set_payresp({});
      }, 5 * 1e3);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">
        Mint Big Boxes [boxtype=2]
      </p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2 fr-sc">
        <InpText
          {...{
            id: `${inpid}:vault`,
            placeholder: "Vault",
            contprops: { className: "w-[20rem]" },
            inpprops: { className: "w-full" },
            setter: () => {
              let v = extract_inp(`${inpid}:vault`);
              if (nils(v)) v = null;
              set_state_ob(docdata, set_docdata, "vault", v);
            },
          }}
        />
        <InpText
          {...{
            id: `${inpid}:qty`,
            placeholder: "Qty",
            contprops: { className: "w-[5rem]" },
            inpprops: { className: "w-full" },
            setter: () => {
              let v = extract_inp(`${inpid}:qty`);
              v = parseInt(v);
              if (nils(v)) v = null;

              set_state_ob(docdata, set_docdata, "qty", v);
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <span>
          {docdata.qty ?? "--"} boxes to {docdata.vault ?? "--"}
        </span>
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction_mintbigboxes}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Mint
          </Tag>
        )}
      </div>

      <hr className="my-2" />
      <div
        onClick={() => {
          qo_recent_bigbox_mints.refetch();
        }}
        className="fr-sc gap-2 resp-text-1 my-2 cursor-pointer text-acc0 font-digi"
      >
        <FontAwesomeIcon icon={faSync} className="pulse-anim" />
        <p class="">Transactions</p>
        {["WETH", "DEZ"].map((e) => {
          return (
            <Tag
              onClick={() => {
                set_token(e);
              }}
              className={twMerge(
                "bg-acc0/50 text-white -skew-x-12 resp-text--2",
                token == e ? "bg-acc0/50" : "bg-acc0/10",
              )}
            >
              {e}
            </Tag>
          );
        })}
      </div>

      {!_.isEmpty(payresp) && (
        <div
          class={twMerge(
            "my-1 fr-sc gap-1 p-2 rounded-md",
            payresp?.type == "loading"
              ? "text-blue-300 border border-blue-300"
              : payresp?.type == "error"
                ? "text-red-300 border border-red-300"
                : payresp?.type == "success"
                  ? "text-green-300 border border-green-300"
                  : "",
          )}
        >
          <>
            <span className="text-purple-2--">
              row: {getv(payresp, "rowid")}
            </span>

            {payresp.type == "loading" && <Loader01c size="s" />}

            <span className="">{getv(payresp, "msg")}</span>
          </>
        </div>
      )}

      <div class="w-full overflow-auto">
        <table className={twMerge("w-max thintdrowp4-table")}>
          <thead>
            <tr className="">
              <td>boxburnid</td>
              <td>boxid</td>
              <td>asset_idx</td>
              <td>vault</td>
            </tr>
          </thead>
          <tbody>
            {mintrows.map((r) => {
              let mint_hash = getv(r, "mintinfo.txid");
              let minted = !nils(mint_hash);
              let rowid = `${r.boxid}:${r.aidx}`;
              let is_resprow = getv(payresp, "rowid") == rowid;
              return (
                <tr className="">
                  <td>
                    <Link target="_blank" to={polytxnlink(r.ltxid)}>
                      <div class="fc-ss">
                        <div class="fr-sc">
                          <Img
                            img={polychainimg}
                            className={twMerge("w-[2rem] aspect-[1/1]")}
                          />
                          <span>...{r.ltxid.slice(-8)}</span>
                        </div>
                        <span>{iso_format(getv(r, "ltxdate"))}</span>
                      </div>
                    </Link>
                  </td>
                  <td>
                    <span>{r.boxid}</span>
                  </td>
                  <td>
                    <div class="fc-ss resp-gap-1">
                      <span>
                        #{r.aidx} - {getv(r, "asset.key")}
                      </span>
                      <span>
                        info: {getv(r, "asset.amt")} {getv(r, "asset.type")}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="fc-ss">
                      <span>{r.vault}</span>
                      <span>{r.vault_name}</span>
                    </div>
                  </td>
                  <td>
                    <div class="fc-ss resp-gap-1">
                      {minted ? (
                        <>
                          <span>minted: </span>
                          <Link target="_blank" to={polytxnlink(mint_hash)}>
                            <div class="fr-sc">
                              <Img
                                img={polychainimg}
                                className={twMerge("w-[2rem] aspect-[1/1]")}
                              />
                              <span>...{mint_hash.slice(-5)}</span>
                            </div>
                          </Link>
                          <span>{iso_format(getv(r, "mintinfo.date"))}</span>
                        </>
                      ) : (
                        <>
                          <Tag
                            onClick={() => doaction_sendpayout(r)}
                            className="bg-acc0/50 -skew-x-12 resp-text--2"
                          >
                            <span className="resp-text--1">Send Asset</span>
                          </Tag>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const AcCancelRaces = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpid = "inp:cancel:raceids";
  const doaction = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      let a = await q_admin_actions_cancel_races(docdata).queryFn();
      if (a.status == "error") throw new Error(a.err);
      set_resp({
        status: "success",
        msg: getv(a, "result.msg"),
      });
      set_docdata({});
      set_val_inp(inpid, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
      }, 5000);
    } catch (err) {
      await cdelay(3000);
      set_resp({
        status: "error",
        msg: err.message,
      });
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">Cancel Races</p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2">
        <p className="my-2">Enter Raceids Eg: race1,race2,race3</p>
        <InpText
          {...{
            id: inpid,
            placeholder: "Raceids",
            setter: () => {
              let v = extract_inp(inpid);
              if (nils(v)) set_docdata({});
              else {
                v = v.split(",").map((e) => e.trim());
                set_docdata({ rids: v });
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <span>Cancel {docdata?.rids?.length ?? 0} rids -- </span>
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Cancel
          </Tag>
        )}
      </div>
    </Card>
  );
};

const AcCancelSatellite = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpid = "inp:cancel:satid";
  const doaction = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      let a = await q_admin_actions_cancel_satellite(docdata).queryFn();
      if (a.status == "error") throw new Error(a.err);
      set_resp({
        status: "success",
        msg: getv(a, "result.msg"),
      });
      set_docdata({});
      set_val_inp(inpid, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
      }, 5000);
    } catch (err) {
      await cdelay(3000);
      set_resp({
        status: "error",
        msg: err.message,
      });
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">Cancel Satellite</p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2">
        <InpText
          {...{
            id: inpid,
            placeholder: "satid",
            setter: () => {
              let v = extract_inp(inpid);
              if (nils(v)) set_docdata({});
              else {
                v = v.trim();
                set_docdata({ satid: v });
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <span>delete satid:{docdata?.satid} -- </span>
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Delete
          </Tag>
        )}
      </div>
    </Card>
  );
};

const AcMintSkinBox = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpid = { vault: "inp:mintskin:vault", qty: "inp:mintskin:qty" };

  const doaction = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      console.log("mint skin", docdata);
      let a = await q_admin_actions_mint_skin_lootbox(docdata).queryFn();
      if (a.status == "error") throw new Error(a.err);
      let txhash = getv(a, "result.hash");
      set_resp({
        status: "success",
        msg: `done txhash: ${txhash}`,
      });
      window.open(polytxnlink_sp(txhash));

      set_docdata({});
      set_val_inp(inpid.vault, "");
      set_val_inp(inpid.qty, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
      }, 5000);
    } catch (err) {
      await cdelay(3000);
      set_resp({
        status: "error",
        msg: err.message,
      });
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">Mint Skin Lootboxes</p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2 fr-sc resp-gap-2">
        <p>Vault</p>
        <InpText
          {...{
            id: inpid.vault,
            placeholder: "vault_address",
            setter: () => {
              let v = extract_inp(inpid.vault);
              if (nils(v)) set_state_ob(docdata, set_docdata, "vault", null);
              else {
                v = v.trim();
                v = v.toLowerCase();
                set_state_ob(docdata, set_docdata, "vault", v);
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <p>Qty</p>
        <InpText
          {...{
            id: inpid.qty,
            placeholder: "enter: qty",
            setter: () => {
              let v = extract_inp(inpid.qty);
              v = parseInt(v);
              if (nils(v)) set_state_ob(docdata, set_docdata, "qty", null);
              else {
                set_state_ob(docdata, set_docdata, "qty", v);
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Mint Skin Boxes
          </Tag>
        )}
      </div>
    </Card>
  );
};

const AcMintCoreBox = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpid = { vault: "inp:mintcore:vault", qty: "inp:mintcore:qty" };

  const doaction = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      console.log("mint core", docdata);
      let a = await q_admin_actions_mint_core_lootbox(docdata).queryFn();
      if (a.status == "error") throw new Error(a.err);
      let txhash = getv(a, "result.hash");
      set_resp({
        status: "success",
        msg: `done txhash: ${txhash}`,
      });
      window.open(polytxnlink_sp(txhash));

      set_docdata({});
      set_val_inp(inpid.vault, "");
      set_val_inp(inpid.qty, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
      }, 5000);
    } catch (err) {
      await cdelay(3000);
      set_resp({
        status: "error",
        msg: err.message,
      });
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">Mint Core Lootboxes</p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2 fr-sc resp-gap-2">
        <p>Vault</p>
        <InpText
          {...{
            id: inpid.vault,
            placeholder: "vault_address",
            setter: () => {
              let v = extract_inp(inpid.vault);
              if (nils(v)) set_state_ob(docdata, set_docdata, "vault", null);
              else {
                v = v.trim();
                v = v.toLowerCase();
                set_state_ob(docdata, set_docdata, "vault", v);
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <p>Qty</p>
        <InpText
          {...{
            id: inpid.qty,
            placeholder: "enter: qty",
            setter: () => {
              let v = extract_inp(inpid.qty);
              v = parseInt(v);
              if (nils(v)) set_state_ob(docdata, set_docdata, "qty", null);
              else {
                set_state_ob(docdata, set_docdata, "qty", v);
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Mint Core Boxes
          </Tag>
        )}
      </div>
    </Card>
  );
};

const AcMaidenDate = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpid = { date: "inp:maiden:date" };

  const [qomdate] = useQueries([q_actions_maiden_date_get()]);
  const maiden_date = useMemo(
    () => getv(qomdate, "data.result.date") ?? null,
    [qomdate],
  );

  const doaction = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      let a = await q_actions_maiden_date_set(docdata).queryFn();
      set_docdata({});
      set_val_inp(inpid.date, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
        qomdate.refetch();
      }, 2000);
    } catch (err) {
      await cdelay(3000);
      set_resp({
        status: "error",
        msg: err.message,
      });
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">Maiden Date</p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2 fr-sc resp-gap-2">
        <p>Date [local]</p>
        <DateInput
          {...{
            id: inpid.date,
            placeholder: "Maiden Date",
            defaultValue: maiden_date,
            setter: () => {
              let v = extract_inp(inpid.date);
              if (nils(v)) set_state_ob(docdata, set_docdata, "date", null);
              else {
                v = iso(v);
                set_state_ob(docdata, set_docdata, "date", v);
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <p className="text-acc0">current</p>
        {qomdate.isLoading ? (
          <Loader01c size="s" />
        ) : (
          <div class="fc-cc">
            <p>{maiden_date} UTC</p>
            <p>{iso_format(maiden_date)} local</p>
          </div>
        )}
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Update Date
          </Tag>
        )}
      </div>
    </Card>
  );
};

const AcCards = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpid = {
    n: "inp:cards:n",
    vault: "inp:cards:vault",
  };

  const [qohs] = useQueries([q_quest_cards_admin_givecards_history()]);
  const hs = useMemo(() => getv(qohs, "data.result", []), [qohs.dataUpdatedAt]);

  const doaction = async () => {
    set_resp({});
    set_loading(true);
    await cdelay(500);
    try {
      let o = {
        vault: docdata.vault,
        n: docdata.n,
      };
      let res = await q_quest_cards_admin_givecards(o).queryFn();
      let done = getv(res, "result.done");
      if (done) {
        set_resp({
          status: "success",
          msg: "submitted",
        });
      } else {
        throw new Error(getv(res, "err"));
      }
      set_val_inp(inpid.vault, "");
      set_val_inp(inpid.n, "");
      set_loading(false);
      set_docdata({});
      setTimeout(() => {
        set_resp({});
        qohs.refetch();
      }, 2000);
    } catch (err) {
      await cdelay(3000);
      set_resp({
        status: "error",
        msg: err.message,
      });
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full "}>
      <p className="text-acc0 text-left resp-text-1">Cards Give</p>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
      <div className="p-2 fr-sc resp-gap-2">
        <InpText
          {...{
            id: inpid.vault,
            placeholder: "Vault",
            setter: () => {
              let v = extract_inp(inpid.vault);
              if (nils(v)) set_state_ob(docdata, set_docdata, "vault", null);
              else {
                set_state_ob(docdata, set_docdata, "vault", v);
              }
            },
          }}
        />

        <InpText
          {...{
            id: inpid.n,
            placeholder: "count",
            setter: () => {
              let v = extract_inp(inpid.n);
              v = parseInt(v);
              if (nils(v)) set_state_ob(docdata, set_docdata, "n", null);
              else {
                set_state_ob(docdata, set_docdata, "n", v);
              }
            },
          }}
        />
      </div>
      <div className="p-2 fr-sc resp-gap-2">
        <div class="flex-1"></div>
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={doaction}
            className={twMerge("-skew-x-12 font-digi bg-acc0/40")}
          >
            Submit
          </Tag>
        )}
      </div>
      <hr />

      <table
        className={twMerge(
          tablecn.table,
          "w-full resp-text--2 thintdrowp4-table",
        )}
      >
        <thead>
          <tr>
            <td>id</td>
            <td>vault</td>
            <td>n</td>
            <td>date</td>
          </tr>
        </thead>
        <tbody>
          {(hs || []).map((e) => {
            return (
              <tr>
                <td>{e.id}</td>
                <td>
                  <div class="fc-ss">
                    <p>{e.vault_name}</p>
                    <p>{e.vault}</p>
                  </div>
                </td>
                <td>
                  <span>{e.n}</span>
                </td>
                <td>{iso_format(e.date)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

const ExtractFunds = ({ contractMod, contract_title, token, conk }) => {
  const mmcon = useMetaMaskContext();

  const tokdecn = token == "WETH" ? 4 : 0;
  const tokaddress = useMemo(() => {
    if (token == "WETH") return contractAddress_list.weth;
    else if (token == "DEZ") return contractAddress_list.dez;
    else return null;
  }, [token]);
  const [con, set_con] = useState(null);
  useEffect(() => {
    if (nils(mmcon.vault)) return;
    else {
      const fn = async () => {
        let con = await contractMod.get_contract();
        set_con(con);
      };
      fn();
    }
  }, [mmcon.vault]);

  const [bal, set_bal] = useState(undefined);
  const [balloading, set_balloading] = useState(false);
  const update_funds = async () => {
    set_balloading(true);
    try {
      let conaddr = await con.contractAddress;
      console.log(contract_title, conaddr);
      let tokcon = await mm_asset_signer(null, 20, tokaddress);
      let bal_wei = await tokcon.balanceOf(conaddr);
      let bal = tofeth(bal_wei);
      set_bal(bal);
    } catch (err) {
      console.log(err);
    }
    set_balloading(false);
  };
  useEffect(() => {
    if (!nils(mmcon.vault) && !nils(con)) {
      setTimeout(() => {
        // update_funds();
      }, 1000);
    }
  }, [mmcon.vault, con]);

  const [loading, set_loading] = useState(false);
  const [docdata, set_docdata] = useState({});
  const [resp, set_resp] = useState({});
  const inpidk = `inp:${conk}:${token}:funds`;

  const inpid = {
    vault: `${inpidk}:vault`,
    amt: `${inpidk}:amt`,
  };

  const withdraw = async () => {
    set_loading(true);
    set_resp({});
    await cdelay(500);
    try {
      let vault = docdata.vault;
      let amt = docdata.amt;
      let a = await con.withdrawFunds(vault, toeth(amt));

      // console.log(a);
      const hash = getv(a, "hash");
      set_resp({
        status: "success",
        msg: `done txhash: ${hash}`,
      });
      window.open(polytxnlink_sp(hash), "_blank");
      set_docdata({});
      set_val_inp(inpid.vault, "");
      set_val_inp(inpid.amt, "");
      set_loading(false);
      setTimeout(() => {
        set_resp({});
      }, 5000);
    } catch (err) {
      console.log(err);
      set_resp({ status: "error", msg: err.message });
      await cdelay(5000);
      set_loading(false);
    }
  };

  return (
    <Card className={"w-full"}>
      <div className="fc-cc resp-p-2">
        <p className="text-acc0 resp-text-1 my-2">{contract_title}</p>
        <div className="fr-cc w-full resp-gap-2 font-digi resp-text-1">
          <TokenIcon token={token} />
          {nils(bal) ? <span>--</span> : <span>{dec(bal, tokdecn)}</span>}
          {balloading ? (
            <Loader01c size="s" />
          ) : (
            <Tag onClick={update_funds}>
              <FontAwesomeIcon icon={faSync} />
            </Tag>
          )}
        </div>
      </div>
      <div class="fc-cc">
        <p class="resp-text--2">{jstr(docdata)}</p>
        {/* <p class="resp-text--2">{jstr(inpid)}</p> */}
      </div>
      <div className="fr-cc resp-p-2 resp-gap-2">
        <InpText
          {...{
            id: inpid.vault,
            placeholder: "vault_address",
            setter: () => {
              let v = extract_inp(inpid.vault);
              if (nils(v)) set_state_ob(docdata, set_docdata, "vault", null);
              else {
                v = v.trim();
                v = v.toLowerCase();
                set_state_ob(docdata, set_docdata, "vault", v);
              }
            },
          }}
        />
        <InpText
          {...{
            id: inpid.amt,
            placeholder: "enter: amt",
            setter: () => {
              let v = extract_inp(inpid.amt);
              v = parseFloat(v);
              if (nils(v)) set_state_ob(docdata, set_docdata, "amt", null);
              else {
                set_state_ob(docdata, set_docdata, "amt", v);
              }
            },
          }}
        />
        {loading ? (
          <Loader01c size="s" />
        ) : (
          <>
            {!nils(docdata.vault) && !nils(docdata.amt) && (
              <Tag
                onClick={withdraw}
                className="bg-acc0/50 -skew-x-12 resp-text--2"
              >
                Withdraw Funds
              </Tag>
            )}
          </>
        )}
      </div>
      {!_.isEmpty(resp) && (
        <>
          <div
            className={twMerge(
              "resp-text--1 resp-p-2 border-md my-2",
              resp.status == "error"
                ? "text-red-300 border border-red-300"
                : "text-green-300 border border-green-300",
            )}
          >
            {resp.msg}
          </div>
        </>
      )}
    </Card>
  );
};

const AcQuestCardsPrize = () => {
  const atcon = useActionsContext();
  const [loading, set_loading] = useState(false);

  const [type, set_type] = useState("pending");
  const [qrs] = useQueries([
    q_quest_cards_admin_pending_prizes({
      type,
    }),
  ]);
  const rewards = useMemo(
    () => getv(qrs, "data.result", []),
    [qrs.dataUpdatedAt],
  );

  const format_prizename = (p) => {
    if (p == "no-prize" || nils(p)) return "No Prize";
    let [a, b, c] = p.split("-");
    if (p.includes("USD")) {
      return `${a} USD`;
    } else {
      return `${a}x ${_.capitalize(b)} `;
    }
  };

  const sel_cards_n = 5;
  const tot_cards_n = 5;
  const imgbase =
    "https://dna-run-public.s3.us-east-2.amazonaws.com/imgs/playing-cards-factions";
  const gen_img = (k) => {
    let link = null;
    if (k == "back" || nils(k)) link = `${imgbase}/card_back_p01.png`;
    else {
      let [a, b] = k.split("-");
      link = `${imgbase}/${a}s_${b}.png`;
    }
    // console.log("gen_img", k, link);
    return link;
  };

  const [data, set_data] = useState({});
  const [resp, set_resp] = useState({});
  const submit_payout = async () => {
    try {
      set_resp({ type: "loading", msg: "setting hash" });
      await cdelay(2 * 1e3);
      let id = data.id;
      let hash = extract_inp("inp:questcards:hash");
      console.log("submit_payout", id, hash);

      if (nils(id)) throw new Error("no id");
      if (nils(hash)) throw new Error("no hash");

      let res = await q_quest_cards_admin_payout_prize({ id, hash }).queryFn();
      if (res?.err) throw new Error(res.err);

      set_resp({
        type: "success",
        msg: "submitted",
      });
      setTimeout(() => {
        set_resp({});
        set_data({});
        set_val_inp("inp:questcards:hash", "");
        qrs.refetch();
      }, 3 * 1e3);
    } catch (err) {
      console.log("do_cashout", err);
      set_resp({ type: "error", msg: err.message });
      setTimeout(() => {
        set_resp({});
      }, 2 * 1e3);
    }
  };

  return (
    <div class="">
      <Card className={"w-full "}>
        <div
          onClick={() => {
            qrs.refetch();
          }}
          class="fr-sc cursor-pointer resp-gap-2"
        >
          <p className="text-acc0 text-left resp-text-1">Quest Cards Prize</p>
          <FontAwesomeIcon icon={faSync} className="pulse-anim" />
        </div>
        <div class="fr-sc resp-gap-2 mb-2">
          <div class="flex-1"></div>
          {["pending", "all"].map((e) => {
            return (
              <Tag
                onClick={() => {
                  set_type(e);
                  qrs.refetch();
                }}
                className={twMerge(
                  "bg-acc0/50 -skew-x-12 resp-text--2 px-2 py-1",
                  type == e ? "bg-acc0/50" : "bg-acc0/10",
                )}
              >
                {e}
              </Tag>
            );
          })}
        </div>
        {_.isEmpty(rewards) ? null : (
          <table className={twMerge(tablecn.table, "thintdrowp4-table")}>
            <tbody>
              {rewards.map((e, i) => {
                return (
                  <tr key={e.id}>
                    <td>
                      {e.minted ? (
                        <div class="w-[3rem] aspect-[1/1]">
                          <Link to={polytxnidlink(getv(e, "mint.hash"))}>
                            <Img img={polychainimg} />
                          </Link>
                        </div>
                      ) : (
                        <div class="">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-acc4 resp-text-2"
                          />
                        </div>
                      )}
                    </td>

                    <td>
                      <div class="fr-sc gap-2">
                        {e.hand.map((c) => {
                          return (
                            <div class="w-[3rem] aspect-[242/340]">
                              <Img
                                img={gen_img(c)}
                                className={twMerge("w-full")}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <p>{e.hand.join(", ")}</p>
                    </td>

                    <td>
                      <div class="fc-ss gap-1 resp-text--1 font-digi">
                        <p>{e.vault_name}</p>
                        <p className="text-acc4 resp-text--3">{e.vault}</p>
                        <p className="text-acc4">
                          Combo: {snake_str_to_caps(e.combo)}
                        </p>
                        <p className="text-acc4">
                          Prize: {format_prizename(getv(e, "prize"))}
                        </p>
                        <p class="">{iso_format(e.cashout_at)}</p>
                      </div>
                    </td>

                    <td>
                      <Tag
                        onClick={() => {
                          set_data({ id: e.id });
                        }}
                        className="bg-acc0/50 -skew-x-12 resp-text--2"
                      >
                        <span className="resp-text--1">Use</span>
                      </Tag>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <hr />
        {_.isEmpty(data) ? null : (
          <div className="fc-ss my-2 p-2">
            <p>Using: {data.id}</p>
            <div class="fr-sc resp-gap-2">
              <InpText
                {...{
                  id: "inp:questcards:hash",
                  placeholder: "Enter Pay Txn Hash",
                  setter: () => {},
                }}
              />
              <Tag
                onClick={() => {
                  submit_payout();
                }}
                className="bg-acc0/50 -skew-x-12 resp-text--2"
              >
                <span className="resp-text--1">Submit</span>
              </Tag>
            </div>

            {!_.isEmpty(resp) && (
              <div
                class={twMerge(
                  "fr-sc resp-gap-2 border rounded-md w-full mx-4 px-4 py-2 resp-text-1 font-digi",
                  nils(resp)
                    ? "text-white border-white"
                    : resp.type == "loading"
                      ? "text-blue-400 border-blue-400"
                      : resp.type == "info"
                        ? "text-blue-400 border-blue-400"
                        : resp.type == "success"
                          ? "text-green-300 border-green-300"
                          : resp.type == "error"
                            ? "text-red-400 border-red-400"
                            : "text-white border-white",
                )}
              >
                {resp.type == "loading" ? (
                  <Loader01c size="s" />
                ) : (
                  <FontAwesomeIcon
                    className="resp-text-2"
                    icon={
                      resp.type == "info"
                        ? faInfoCircle
                        : resp.type == "success"
                          ? faCheckCircle
                          : resp.type == "error"
                            ? faTimesCircle
                            : null
                    }
                  />
                )}
                <p className="">{resp.msg}</p>
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export const Actions = () => {
  const atcon = {};
  return (
    <ActionsContext.Provider value={atcon}>
      <div className="h-page">
        <div className="w-[60rem] mx-auto max-w-[98vw]">
          <div className="h-[3rem]"></div>
          {[
            ["r01", "RaceStakeV01", "WETH", RaceStake],
            ["r02", "RaceStakeV02", "WETH", RaceStakeV02],
            ["r03", "RaceStakeV03", "DEZ", RaceStakeV03_DEZ],
            ["sp", "Splicing", "WETH", Splicing],
            ["fex", "FairexBets", "DEZ", FairexMCBets],
          ].map((e) => {
            return (
              <>
                <ExtractFunds
                  {...{
                    key: e[0],
                    conk: e[0],
                    contract_title: e[1],
                    token: e[2],
                    contractMod: e[3],
                  }}
                />
              </>
            );
          })}

          <AcQuestCardsPrize />
          <AcCards />
          <AcMintPrizebox />
          <AcMintBigBox />
          <AcCancelRaces />
          <AcCancelSatellite />
          <AcMintSkinBox />
          <AcMintCoreBox />
          <AcMaidenDate />
          <div className="h-[5rem]"></div>
        </div>
      </div>
    </ActionsContext.Provider>
  );
};
