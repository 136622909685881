import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list, getgasinfo } from "../constants.js";

class RaceStakeContract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async withdrawFunds(to, amount) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.withdrawFunds(to, amount, gasinfo);
    const resp = await tx.wait();
    return resp;
  }
}

const get_RaceStakeContract = async () => {
  const contractAddress = contractAddress_list.racestake;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new RaceStakeContract({ contractAddress, provider, signer });
  return runner;
};

const RaceStake = {
  class: RaceStakeContract,
  get_contract: get_RaceStakeContract,
};

export default RaceStake;
